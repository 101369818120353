import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  height?: Property.Height;
  width?: Property.Width;
  title?: string;
  role?: string;
}

export const WorkLiningPartialIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, height, width, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" {...rest} shapeRendering="geometricprecision">
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6V2H10V6H2ZM0 7V1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V7V11C12 11.5523 11.5523 12 11 12H1C0.447715 12 0 11.5523 0 11V7Z"
        fill={fill}
      />
    </svg>
  );
};

WorkLiningPartialIcon.defaultProps = {
  height: '12',
  width: '12',
  fill: settings.colors.Primary.Blue_5,
};
