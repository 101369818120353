/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import {
  RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
} from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { scaleLinear } from '@visx/scale';
import { max } from 'd3-array';
import React, { useMemo } from 'react';
import { useMeasure } from 'react-use';

import GraphDeltaRemainingBrickLength from './components/GraphDeltaRemainingBrickLength';
import GraphRemainingThickness from './components/GraphRemainingThickness';
import GraphRepairMixAmount from './components/GraphRepairMixAmount';
import GraphVolume from './components/GraphVolume';
import GraphVolumeRepairDeltaVsMixVolume from './components/GraphVolumeRepairDeltaVsMixVolume';
import GraphWearRate from './components/GraphWearRate';
import ReportHeader from './components/ReportHeader';
import { SSubHeader } from './styles';
import { DATA } from './utils';

interface Props {
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
}
const CampaignReportPanel: React.ChildlessComponent<Props> = ({ measurementSequence }) => {
  const [containerRef, { width: containerWidth }] = useMeasure<HTMLDivElement>();

  const allMeasurements = useMemo(() => {
    const ret: (RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto)[] = [];
    if (isDefined(measurementSequence.permanentLiningMeasurement)) {
      ret.push(measurementSequence.permanentLiningMeasurement);
    }
    if (isDefined(measurementSequence.wearLiningInitialMeasurement)) {
      ret.push(measurementSequence.wearLiningInitialMeasurement);
    }
    for (const measurement of measurementSequence.measurementTuples) {
      if (isDefined(measurement.workingLiningMeasurement)) {
        ret.push(measurement.workingLiningMeasurement);
      }
      if (isDefined(measurement.afterRepairMeasurement)) {
        ret.push(measurement.afterRepairMeasurement);
      }
    }
    return ret;
  }, [measurementSequence]);

  const tonnageMaximumValueTons = useMemo(() => {
    const maxTonnageKg = max(allMeasurements, (measurement) => measurement.tonnage);
    const maxTonnageTons = isDefined(maxTonnageKg) ? maxTonnageKg / 1000 : 0;
    return maxTonnageTons;
  }, [allMeasurements]);

  const xScale = useMemo(() => {
    return scaleLinear({ domain: [0, tonnageMaximumValueTons], range: [0, containerWidth] });
  }, [containerWidth, tonnageMaximumValueTons]);

  return (
    <div ref={containerRef}>
      <ReportHeader title="Campaign Report" metadata="0 – 140,123t (Jan 4, 2024 – Jun 16 2024)" />
      <SSubHeader>Wear Rate – Slag Line</SSubHeader>
      <GraphWearRate xScale={xScale} measurementSequence={measurementSequence} line="slagLine" average={3.5} />
      <SSubHeader>Wear Rate – Iron Line</SSubHeader>
      <GraphWearRate xScale={xScale} measurementSequence={measurementSequence} line="ironLine" average={3.5} />
      <SSubHeader>Remaining Thickness - Slag Line</SSubHeader>
      <GraphRemainingThickness
        xScale={xScale}
        remainingThicknesses={DATA.map((data) => ({
          tonnage: data.atTons,
          slag: { remainingThicknessBefore: data.slagLine.thicknessBefore, remainingThicknessAfter: data.slagLine.thicknessAfter },
          iron: { remainingThicknessBefore: data.ironLine.thicknessBefore, remainingThicknessAfter: data.ironLine.thicknessAfter },
        }))}
        critical={150}
      />
      <SSubHeader>Remaining Thickness - Iron Line</SSubHeader>
      <GraphRemainingThickness
        xScale={xScale}
        remainingThicknesses={DATA.map((data) => ({
          tonnage: data.atTons,
          slag: { remainingThicknessBefore: data.slagLine.thicknessBefore, remainingThicknessAfter: data.slagLine.thicknessAfter },
          iron: { remainingThicknessBefore: data.ironLine.thicknessBefore, remainingThicknessAfter: data.ironLine.thicknessAfter },
        }))}
        critical={150}
      />
      <SSubHeader>Repair: Thickness Delta – Slag Line</SSubHeader>
      <GraphDeltaRemainingBrickLength
        xScale={xScale}
        rbls={DATA.map((data) => ({ atTons: data.atTons, deltaRBLSlagLine: data.deltaRBLSlagLine, deltaRBLIronLine: data.deltaRBLIronLine }))}
      />
      <SSubHeader>Repair: Thickness Delta – Iron Line</SSubHeader>
      <GraphDeltaRemainingBrickLength
        xScale={xScale}
        rbls={DATA.map((data) => ({ atTons: data.atTons, deltaRBLSlagLine: data.deltaRBLSlagLine, deltaRBLIronLine: data.deltaRBLIronLine }))}
      />
      <GraphRepairMixAmount xScale={xScale} repairMixAmounts={DATA.map((data) => ({ atTons: data.atTons, amount: data.repairMixAmount }))} />
      <SSubHeader>Volume Repair Delta vs Mix Volume</SSubHeader>
      <GraphVolumeRepairDeltaVsMixVolume
        xScale={xScale}
        data={DATA.map((data) => ({ atTons: data.atTons, deltaVolume: data.deltaVolume, repairMixVolume: data.repairMixVolume }))}
      />
      <SSubHeader>Volume</SSubHeader>
      <GraphVolume
        xScale={xScale}
        volumes={DATA.map((data) => ({
          atTons: data.atTons,
          volumeBefore: data.fillVolumneBefore,
          volumeAfter: data.fillVolumneAfter,
        }))}
      />
    </div>
  );
};

CampaignReportPanel.whyDidYouRender = true;
export default React.memo(CampaignReportPanel);
