import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  metadata: React.ReactNode;
}
const ReportHeader: React.ChildlessComponent<Props> = ({ title, metadata }) => {
  return (
    <SWrapper>
      <SHeader>{title}</SHeader>
      <SMetadata>{metadata}</SMetadata>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${settings.Spacing.Spacing_150} 0 ${settings.Spacing.Spacing_300} 0;
  color: ${settings.colors.Primary.Grey_8};
`;

const SHeader = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.XX_Large};
`;

const SMetadata = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Large};
`;

export default React.memo(ReportHeader);
