import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { getBrowserLocale, toUnicodeLocale } from '@rhim/i18n';
import { FullscreenExitIcon, FullscreenIcon, OpsStateCriticalInvertedIcon } from '@rhim/icons/24';
import { EyeSettingsIcon } from '@rhim/icons/24';
import { APOIcon } from '@rhim/icons/32';
import { formatNumber, IconButton } from '@rhim/react';
import { RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel } from '@rhim/rest/operatorDisplay';
import { RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto, RHIMContractsMaintenanceTaskSeverity } from '@rhim/rest/wearManagement/api';
import { usePrivileges } from '@rhim/sdk/customerManagement';
import {
  avgMeasurementGapLabelOperatorDisplay,
  avgSpeedLabelOperatorDisplay,
  fullScreenButtonOperatorDisplay,
  gearButtonOperatorDisplay,
  topStripContainerOperatorDisplay,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';

import AppContext from '../../app/AppContext';
import { PRIVILEGES } from '../../utilities';
import { CriticalSmallBox } from '../CriticalBox/CriticalBox';
import MeasurementSegment from './MeasurementSegment';
import ViewModeSegment from './ViewModeSegment';

interface Props {
  toggleFullscreen: () => void;
  settingsDisabled: boolean;
  isFullscreen: boolean;
  data: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel;
  vessels: APO.VesselV2[];
  selectedVesselId?: UUID;
  onSelect: (value: UUID) => void;
  disabled?: boolean;
  toggleSettings: () => void;
  toggleGraphView?: (enable: boolean) => void;
  isGraphView?: boolean;
  isTableView?: boolean;
  toggleTableView?: (enable: boolean) => void;
  campaignId?: number;
  setSelectedCampaignsId?: (first: number, second?: number) => void;
  comparedCampaign?: number;
  campaigns?: RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto[];
  toggleCompareMode: (value: boolean) => void;
  compareMode: boolean;
}

const OperatorDisplayStrip: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  disabled = false,
  toggleFullscreen,
  compareMode,
  toggleCompareMode,
  settingsDisabled,
  onSelect,
  toggleSettings,
  selectedVesselId,
  isFullscreen,
  data,
  vessels,
  toggleGraphView,
  isGraphView = false,
  isTableView = false,
  toggleTableView,
  campaigns,
  setSelectedCampaignsId,
  campaignId,
  comparedCampaign,
}): JSX.Element => {
  const { t } = i18nReact.useTranslation(['operator-display']);
  const { user, selectedCustomer } = useContext(AppContext);
  const privileges = usePrivileges(user, selectedCustomer);
  const hasAccess = privileges.global.has(PRIVILEGES.UpdateOperatorDisplaySettings) || privileges.customer.has(PRIVILEGES.UpdateOperatorDisplaySettings);
  const locale = getBrowserLocale();

  const criticalRegionsNr = React.useMemo(() => {
    let criticalRegions = [];
    if (isDefined(data.regions)) {
      criticalRegions = data.regions.filter((region) => {
        const index = region.maintenanceTasks.findIndex((item) => {
          return item.severity === RHIMContractsMaintenanceTaskSeverity.High;
        });
        return index !== -1;
      });
    }
    return criticalRegions.length;
  }, [data]);

  return (
    <Container data-test-id={topStripContainerOperatorDisplay}>
      {isFullscreen && (
        <LogoContainer>
          <ProductIcon>
            <APOIcon />
          </ProductIcon>
          <LogoSpan>{t('operator-display:label')}</LogoSpan>
        </LogoContainer>
      )}
      <MeasurementSegment
        toggleCompareMode={toggleCompareMode}
        compareMode={compareMode}
        campaigns={campaigns}
        selectCampaign={setSelectedCampaignsId}
        isTableView={isTableView}
        vessels={vessels}
        campaign={campaignId}
        heat={data.lastTotalHeat ?? data.lastHeat ?? 0}
        date={data.lastMeasurementTimestamp as Date.UTC}
        selectedVesselId={selectedVesselId}
        selectVessel={onSelect}
        disabled={disabled}
        comparedCampaign={comparedCampaign}
      />
      <InlineSection>
        {criticalRegionsNr > 0 && !compareMode && (
          <>
            <SVerticalDivider />
            <CriticalSection>
              <CriticalSmallBox
                className="area-container"
                title={criticalRegionsNr.toString()}
                icon={
                  <OpsStateCriticalInvertedIcon
                    backgroundColor={settings.colors.Operational.State_Alert_Red_3}
                    foregroundColor={settings.colors.Monochromatic.White}
                  />
                }
              />
            </CriticalSection>
          </>
        )}
        {!disabled && !compareMode && (
          <>
            <SVerticalDivider />
            <InlineSection>
              <AvgSection>
                <Text title={t('operator-display:header.avgWearSpeed')} width={130} marginRight={12} color={settings.colors.Primary.Grey_6}>
                  {t('operator-display:header.avgWearSpeed')}
                </Text>
                <Text title={t('operator-display:header.avgMeasurementGap')} width={130} marginRight={12} color={settings.colors.Primary.Grey_4}>
                  {t('operator-display:header.avgMeasurementGap')}
                </Text>
              </AvgSection>
              <AvgSection>
                <Text
                  data-test-id={avgSpeedLabelOperatorDisplay}
                  marginRight={0}
                  fontSize={settings.typography.FontSize.X_Small}
                  color={settings.colors.Primary.Grey_6}
                >
                  {/* @ts-expect-error https://github.com/i18next/react-i18next/issues/1483#issuecomment-1268455602 */}
                  {formatNumber(2)(toUnicodeLocale(locale))(data.averageWearSpeed) + ' ' + t('operator-display:header.avgWearSpeedUnits')}
                </Text>
                <Text
                  data-test-id={avgMeasurementGapLabelOperatorDisplay}
                  color={settings.colors.Primary.Grey_4}
                  marginRight={0}
                  fontSize={settings.typography.FontSize.X_Small}
                >
                  {/* @ts-expect-error https://github.com/i18next/react-i18next/issues/1483#issuecomment-1268455602 */}
                  {formatNumber(2)(toUnicodeLocale(locale))(data.averageMeasurementGap) + ' ' + t('operator-display:header.avgMeasurementGapUnits')}
                </Text>
              </AvgSection>
            </InlineSection>
          </>
        )}
      </InlineSection>
      <SVerticalDivider />
      {isDefined(toggleGraphView) && isDefined(toggleTableView) && (
        <ViewModeSegment
          compareMode={compareMode}
          toggleGraphView={toggleGraphView}
          isGraphView={isGraphView}
          isTableView={isTableView}
          toggleTableView={toggleTableView}
        />
      )}
      <SVerticalDivider />
      {hasAccess && (
        <IconContainer>
          <IconButtonLabel>{t('operator-display:sortList.title')}</IconButtonLabel>
          <IconButton mode="dark" icon={<EyeSettingsIcon />} onPress={toggleSettings} isDisabled={settingsDisabled} data-test-id={gearButtonOperatorDisplay} />
        </IconContainer>
      )}
      <FullscreenIconContainer disabled={disabled}>
        <IconButton
          mode="dark"
          icon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          onPress={toggleFullscreen}
          isDisabled={disabled}
          data-test-id={fullScreenButtonOperatorDisplay}
        />
      </FullscreenIconContainer>
    </Container>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: pointer;
`;

const IconButtonLabel = styled.span`
  color: ${settings.colors.Primary.Grey_4};
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Medium};
  padding: ${settings.Spacing.Spacing_50} 0 ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_200};
`;

const SVerticalDivider = styled.div`
  width: 1px;
  height: auto;
  align-self: stretch;
  background-color: ${settings.colors.Primary.Grey_7};
`;

const Text = styled.span<{ marginRight: number; font?: string; fontSize?: string; width?: number; color?: string }>`
  color: ${(props) => props.color ?? settings.colors.Primary.Grey_8};
  font-size: ${(props) => props.fontSize ?? settings.typography.FontSize.X_Small};
  font-family: ${(props) => props.font ?? settings.typography.FontFamily.Regular};
  margin: ${(props) => `0 0 0 ${props.marginRight}px`};
  width: ${(props) => props.width + 'px' || 'inherit'};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LogoContainer = styled.div`
  background: ${settings.colors.Primary.Blue_9};
  align-items: center;
  display: flex;

  #verticalDivider {
    display: none;
  }
`;
const FullscreenIconContainer = styled(IconContainer)<{ disabled: boolean | undefined }>`
  margin-right: ${settings.Spacing.Spacing_200};
`;

const LogoSpan = styled.span`
  color: ${settings.colors.Monochromatic.White};
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${settings.typography.FontFamily.Bold};
  margin-right: ${settings.Spacing.Spacing_200};
`;
const ProductIcon = styled.span`
  display: flex;
  align-items: center;
  padding: ${settings.Spacing.Spacing_100} 0;
  margin: 0 ${settings.Spacing.Spacing_200} 0 ${settings.Spacing.Spacing_150};
`;

const Section = styled.div`
  display: flex;
  margin: auto;
  margin-right: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const AvgSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  margin-left: ${settings.Spacing.Spacing_200};
`;

const CriticalSection = styled(Section)`
  margin: ${settings.Spacing.Spacing_200};
`;
const InlineSection = styled.div`
  display: inline-flex;
  margin: 0 auto;
  margin-right: ${settings.Spacing.Spacing_200};
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: ${settings.Elevation.Strip};
  width: 100%;
  display: inline-flex;
  height: 48px;
  background: ${settings.colors.Primary.Grey_10};

  .critical-area {
    margin-right: 25px;
    max-width: 43%;
  }

  > div {
    div {
      border-radius: 0;
    }

    .area-container {
      border-radius: 3px;
    }
  }
`;

export default React.memo(OperatorDisplayStrip);
