export const ROUTES_COMMON = {
  ROOT: '/',
  INCOMPLETE_SETUP: '/incomplete-setup',
  START: '/start',
  IMPRINT: '/imprint',
  PRIVACY: '/privacy',
  TERMS_OF_USE: '/terms-of-use',
  CODE_OF_CONDUCT: '/code-of-conduct',
  USER_SETTINGS: '/user-settings',
} as const;

export const ROUTES_STEEL_ONLY = {
  FLEET_OVERVIEW: '/fleet-overview',
  INGRESS: '/ingress',
  INGRESS_PROCESS_DATA: '/ingress/processdata',
  INGRESS_MEASUREMENT_DATA: '/ingress/measurementdata',
  INGRESS_MEASUREMENT_DATA_UPLOAD: '/ingress/measurementdata/upload',
  INGRESS_POINT_CLOUD_SCAN_DATA: '/ingress/pointclouddata',
  MEASUREMENT_VIEW: '/measurement-view',
  CONNECTED_MACHINES: '/connected-machines',
  VOLUME_CALCULATIONS: '/volume',
  BLAST_FURNACE_RUNNER_REPORT: '/bfr-report',
  REPORT: '/report',
  DISPLAY: '/display',
  RH_REPORT: '/rh-report',
  PARAMETERS_COMPARISON: '/parameterscomparison',
  SANDBOX_3D: '/sandbox',
} as const;

export const PRIVILEGES = {
  AccessFleetOverview: 'AccessFleetOverview',
  UpdateOperatorDisplaySettings: 'UpdateOperatorDisplaySettings',
  AccessOperatorDisplay: 'AccessOperatorDisplay',
  AccessMeasurementView: 'AccessMeasurementView',
  AccessDataCenter: 'AccessDataCenter',
  AccessWearExplorer: 'AccessWearExplorer',
  AccessRhLegacyPrediction: 'AccessRhLegacyPrediction',
  CompareProcessParameter: 'CompareProcessParameter',
  AccessCockpit: 'AccessCockpit',
  CustomerManagement: 'CustomerManagement',
  UserManagement: 'UserManagement',
  GlobalRoleManagement: 'GlobalRoleManagement',
  CustomerRoleManagement: 'CustomerRoleManagement',
  ReadMasterData: 'ReadMasterData',
  MasterDataMaintenance: 'MasterDataMaintenance',
  CustomerFeatureManagement: 'CustomerFeatureManagement',
  ArtificialCampaignMapping: 'ArtificialCampaignMapping',
  UploadFiles: 'UploadFiles',
  ManageUploadedFiles: 'ManageUploadedFiles',
  AccessCrkPointCloudReport: 'AccessCrkPointCloudReport',
  ManageCrkPointCloudReport: 'ManageCrkPointCloudReport',
} as const;

/** Operation available to select users */
export type Privilege = ValueOf<typeof PRIVILEGES>;

export const ROUTES_CEMENT_ONLY = {
  ARO_ROOT: '/rk',
  ARO_ARO_REPORT: 'aro-report',
  ARO_PREDICTION: 'prediction',
  ARO_ARO_REPORT_PREDICTION: '/rk/aro-report/prediction',
  ARO_ANALYTICS: 'analytics',
  ARO_INGRESS: '/rk/ingress',
  ARO_INGRESS_ROOT: '/rk/ingress',
  ARO_POINT_CLOUD_DATA: 'pointclouddata',
  ARO_INGRESS_POINT_CLOUD_DATA: '/rk/ingress/pointclouddata',
  ARO_SCANS: 'scans',
  ARO_SCANS_PARAMS: ':scanId',
  ARO_INGRESS_SCANS: '/rk/ingress/scans',
  ARO_EXTRACTIONS_CREATE: 'extractions/create',
  ARO_LES: 'les',
  ARO_REPORT: 'report',
  ARO_POINT_CLOUD: 'point-cloud',
  ARO_LES_REPORT: '/rk/les/report',
  ARO_POTREE: '/rk/potree',
} as const;

export const ROUTES_STEEL = {
  ...ROUTES_COMMON,
  ...ROUTES_STEEL_ONLY,
};

export const ROUTES_CEMENT = {
  ...ROUTES_COMMON,
  ...ROUTES_CEMENT_ONLY,
} as const;

export const ROUTES = {
  ...ROUTES_CEMENT,
  ...ROUTES_STEEL,
};

export const qckLightProductName = 'QCK light';

export const PARAMS = {
  CUSTOMER_ID: 'c',
  VESSEL_ID: 'vid',
  CAMPAIGN_ID: 'cid',
  CUSTOMER_PORTAL_CUSTOMER_ID: 'cpid',
  MEASUREMENT_ID: 'mid',
  REPORT_ID: 'rid',
  SCAN_ID: 'sid',
  TAB_PARAM_EXTRACTION: 'extractionTab',
};

// all the keys are removed when the customer is changed
export const STORAGE_KEYS = {
  CUSTOMER_ID: 'customerId',
  SELECTED_SCAN_ID: 'selectedScanId',
  SELECTED_REPORT_ID: 'selectedReportId',
  SELECTED_MEASUREMENT_ID: 'selectedMeasurementId',
  SELECTED_CAMPAIGN_ID: 'selectedCampaignId',
  SELECTED_COMPARED_CAMPAIGN_ID: 'selectedCampaignsNr',
  SELECTED_VESSEL_ID: 'selectedVesselId',
  COMPARED_VESSEL_ID: 'comparedVesselId',
};
