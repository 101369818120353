import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import classNames from 'classnames';
import React, { cloneElement, FC } from 'react';
import styled, { css } from 'styled-components';

type Mode = 'standard' | 'inverted' | 'alert';

interface LinkIcon {
  icon: React.ReactElement;
  position: 'start' | 'end';
}

type Props =
  | {
      mode?: Mode;
      /**
       * @deprecated Use `children` instead. Rationale: images and other block
       * elements can be links, too. Also, using `children` is idiomatic and
       * enables us to take advantage of the `<Trans />` component from i18n-next.
       */
      dataTestId?: string;
      text: string;
      linkIcon?: LinkIcon;
      href: string;
      rel?: string;
      className?: string;
      children?: never;
      target?: string;
      download?: string;
    }
  | {
      mode?: Mode;
      dataTestId?: string;
      linkIcon?: LinkIcon;
      href: string;
      rel?: string;
      className?: string;
      children: React.ReactNode;
      text?: never;
      target?: string;
      download?: string;
    };

export const Hyperlink: FC<React.PropsWithChildren<Props>> = ({
  mode = 'standard',
  dataTestId,
  target,
  className,
  text,
  rel,
  linkIcon,
  href,
  children,
  download,
}) => {
  const iconWithProps = isDefined(linkIcon) ? cloneElement(linkIcon.icon, { fill: 'currentColor' }) : undefined;
  return (
    <StyledHyperlink
      target={target}
      data-test-id={dataTestId}
      href={href}
      download={download}
      rel={rel}
      className={classNames(
        {
          [`mode-${mode}`]: true,
          'has-icon': isDefined(linkIcon),
        },
        className
      )}
    >
      {isDefined(linkIcon) && linkIcon.position === 'start' && <SIconContainer isPositionStart={true}>{iconWithProps}</SIconContainer>}
      {text ?? children}
      {isDefined(linkIcon) && linkIcon.position === 'end' && <SIconContainer isPositionStart={false}>{iconWithProps}</SIconContainer>}
    </StyledHyperlink>
  );
};

const StyledHyperlink = styled.a`
  font-family: ${settings.typography.FontFamily.Bold};
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;

  &:not(.has-icon) {
    border-width: 0 0 1px 0;
    margin-bottom: -1px;
    border-style: solid;
  }

  &.mode-standard {
    color: ${settings.colors.Primary.Blue_9};
    border-color: ${settings.colors.Primary.Blue_9};

    &:hover {
      color: ${settings.colors.Primary.Blue_8};
      border-color: ${settings.colors.Primary.Blue_8};

      &:active {
        color: ${settings.colors.Primary.Blue_7};
        border-color: transparent;
      }
    }
  }

  &.mode-inverted {
    color: ${settings.colors.Monochromatic.White};
    border-color: ${settings.colors.Monochromatic.White};

    &:hover {
      color: ${settings.colors.Primary.Grey_3};
      border-color: ${settings.colors.Primary.Grey_3};

      &:active {
        color: ${settings.colors.Monochromatic.White};
        border-color: transparent;
      }
    }
  }

  &.mode-alert {
    color: ${settings.colors.Operational.State_Notif_Magenta_4};
    border-color: ${settings.colors.Operational.State_Notif_Magenta_4};

    &:hover {
      color: ${settings.colors.Operational.State_Notif_Magenta_3};
      border-color: ${settings.colors.Operational.State_Notif_Magenta_3};

      &:active {
        color: ${settings.colors.Operational.State_Notif_Magenta_2};
        border-color: transparent;
      }
    }
  }
`;

const SIconContainer = styled.span<{ isPositionStart: boolean }>`
  display: flex;
  ${(props) =>
    props.isPositionStart
      ? css`
          margin-right: ${settings.Spacing.Spacing_100};
        `
      : css`
          margin-left: ${settings.Spacing.Spacing_100};
        `}
`;

export default React.memo(Hyperlink);
