import { RHIMCementServiceV1ModelsScanDownloadDto } from '@rhim/rest/cement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMCementServiceV1ModelsScanDownloadDto;

export function useCrkScanDownload(
  scanId: string,
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>
) {
  return useQuery(getKey(scanId), (context) => queryFn(context), {
    ...configuration,
  });
}

const getKey = (scanId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'cement',
      scope: 'scanDownload',
      entity: 'detail',
      scanId,
    },
  ]);

const queryFn = ({ queryKey: [{ scanId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  assert(
    isDefined(scanId),
    `${useCrkScanDownload.name} requires a scanId, but value was ${scanId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return API.scanDownload.getScansScanidDownload(scanId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCrkScanDownload.getKey = getKey;
useCrkScanDownload.queryFn = queryFn;
