// eslint-disable-next-line @typescript-eslint/no-unused-vars
type PRIVILEGES = typeof import('../utilities').PRIVILEGES;

/**
 * Feature flags defined for the environment. This is used to enable/disable unreleased features.
 * These feature flags are meant to be removed once the feature ist stable and ready for deployment to customers
 * Note: right-hand side values must reflect the values used in `apo-portal-cd.yml`.
 */
export enum FeatureFlag {
  BfrRegistrationV1 = 'BfrRegistrationV1',
}

/**
 * Customer feature flags send by the BE. The /me endpoint returns a list of these flags under the `features` property.
 *
 * Note: customers returned by the `/me` endpoint also include an `operations` property that contains {@link PRIVILEGES}.
 */
export enum CustomerFeatureFlag {
  LegacyRhView = 'LegacyRhView',
  QCKLight = 'QCKLight',
  WearExplorer = 'WearExplorer',
  ParameterComparison = 'ParameterComparison',
  OperatorDisplay = 'OperatorDisplay',
  VolumeCalculations = 'VolumeCalculations',
}

export enum Feature {
  ProcessData = 'processData',
  MeasurementData = 'measurementData',
  Report = 'report',
  PointCloudData = 'pointCloudData',
  MeasurementView = 'measurementView',
  ConnectedMachines = 'connectedMachines',
  VolumeCalculations = 'volumeCalculations',
  BlastFurnaceRunnerReport = 'BlastFurnaceRunnerReport',
  Display = 'display',
  RhReport = 'rhReport',
  AroReport = 'aroReport',
  FleetOverview = 'fleetOverview',
  ParameterComparison = 'parameterComparison',
}
