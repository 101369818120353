import { assert, isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { Button } from '../Button';

type BaseButtonProps = React.ComponentProps<typeof Button>;
type ButtonProps = Omit<BaseButtonProps, 'mode' | 'size' | 'icon'>;
type Icon = NonNullable<BaseButtonProps['icon']>['icon'];

export interface ButtonDoubleProps {
  size: BaseButtonProps['size'];
  firstButtonProps: ButtonProps & { icon?: Icon };
  secondButtonProps: Omit<ButtonProps, 'label'> & { icon: Icon };
}

export const ButtonDouble: React.FC<ButtonDoubleProps> = ({ size = 'small-40', firstButtonProps, secondButtonProps }) => {
  const secondButtonPadding = new Map<typeof size, string>([
    ['big-56', `${(56 - 24) / 2}px`], // icon 24px
    ['medium-48', `${(48 - 24) / 2}px`], // icon 24px
    ['small-40', `${(40 - 16) / 2}px`], // icon 16px
    ['x-small-32', `${(32 - 16) / 2}px`], // icon 16px
  ]).get(size);

  assert(isDefined(secondButtonPadding), 'Button size not supported by ButtonDouble');

  return (
    <Wrapper>
      <FirstButton {...firstButtonProps} {...{ size, icon: { icon: firstButtonProps.icon, position: 'start' } }} />
      <SecondButton {...secondButtonProps} {...{ size, icon: { icon: secondButtonProps.icon, position: 'start' } }} padding={secondButtonPadding} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;
`;

const FirstButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const SecondButton = styled(Button)<{ padding: string }>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 ${(props) => props.padding} !important;

  .icon-start {
    margin-right: 0 !important;
  }
`;
