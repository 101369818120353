import { AuthClient, canUseExistingAccessToken, createAuthorizationHeader, getAccessToken } from '@rhim/react';
import { isDefined } from '@rhim/utils';
import { AccessToken, BearerAccessToken } from 'typings';

import { environment } from '../environments/environment';
import { AxiosApi } from '../requests/axios-api';
import { useSessionURL } from './tracking';

export const authClient = new AuthClient({ ...environment, appName: 'apo-portal' });

authClient.interceptors.add('onBeforeLogout', async () => {
  await window.sessionStorage.clear();
});

export const Api = new AxiosApi(authClient, useSessionURL);
export const axiosApiInstance = Api.axiosApiInstance;

export async function getAuthToken(): Promise<BearerAccessToken> {
  const accessToken: AccessToken = getAccessToken();

  return isDefined(accessToken) && canUseExistingAccessToken(accessToken)
    ? createAuthorizationHeader('Bearer', accessToken.secret)
    : (await authClient.renewToken())['bearerAuthentication'];
}
