import { RHIMCementServiceV1ModelsReportConfigDto } from '@rhim/rest/cement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMCementServiceV1ModelsReportConfigDto;

export function useCrkReportConfig(
  vesselId: string,
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>
) {
  return useQuery(getKey(vesselId), (context) => queryFn(context), {
    ...configuration,
  });
}

const getKey = (vesselId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'cement',
      scope: 'reportConfig',
      entity: 'detail',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  assert(
    isDefined(vesselId),
    `${useCrkReportConfig.name} requires a vesselId, but value was ${vesselId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return API.reportConfig.getVesselsVesselidReportconfig(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCrkReportConfig.getKey = getKey;
useCrkReportConfig.queryFn = queryFn;
