/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import { ensure } from '@rhim/utils';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useScaleY } from '../hooks/useScaleY';
import { SDashedLine } from '../styles';
import { CRITICAL_LINE_COLOR, IRON_LINE_COLOR, SLAG_LINE_COLOR } from './constants';
import Graph from './Graph';
import { GraphLegendProps } from './GraphLegend';

const GRAPH_HEIGHT_TOTAL = 200;
const NODE_SIZE = 6;
const NODE_SIZE_HALF = NODE_SIZE / 2;

const GRAPH_LEGENDS: GraphLegendProps[] = [
  {
    label: 'Slag Line',
    color: SLAG_LINE_COLOR,
    isSolidLine: true,
  },
  {
    label: 'Iron Line',
    color: IRON_LINE_COLOR,
    isSolidLine: true,
  },
  {
    label: 'Critical',
    color: CRITICAL_LINE_COLOR,
    isSolidLine: false,
  },
];

interface RemainingThickness {
  tonnage: number;
  slag: {
    remainingThicknessBefore: number;
    remainingThicknessAfter: number;
  };
  iron: {
    remainingThicknessBefore: number;
    remainingThicknessAfter: number;
  };
}

function getLineFor(
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
  remainingThicknesses: RemainingThickness[],
  line: 'slag' | 'iron',
  index: number
) {
  if (index === 0) {
    return null;
  }
  const remainingThicknessPrevious = ensure(remainingThicknesses[index - 1]);
  const remainingThicknessCurrent = ensure(remainingThicknesses[index]);

  const previousValueAfter =
    line === 'slag' ? remainingThicknessPrevious.slag.remainingThicknessAfter : remainingThicknessPrevious.iron.remainingThicknessAfter;
  const previousSegmentX = xScale(remainingThicknessPrevious.tonnage);
  const previousSegmentYAfter = yScale(previousValueAfter);

  const currentValueBefore =
    line === 'slag' ? remainingThicknessCurrent.slag.remainingThicknessBefore : remainingThicknessCurrent.iron.remainingThicknessBefore;
  const currentValueAfter = line === 'slag' ? remainingThicknessCurrent.slag.remainingThicknessAfter : remainingThicknessCurrent.iron.remainingThicknessAfter;
  const currentSegmentX = xScale(remainingThicknessCurrent.tonnage);
  const currentSegmentYBefore = yScale(currentValueBefore);
  const currentSegmentYAfter = yScale(currentValueAfter);

  const color = line === 'slag' ? SLAG_LINE_COLOR : IRON_LINE_COLOR;
  return (
    <React.Fragment key={index}>
      {/* THE CURRENT SEGMENT'S MAIN LINE */}
      <SLine
        key={remainingThicknessCurrent.tonnage}
        from={{ x: previousSegmentX, y: previousSegmentYAfter }}
        to={{ x: currentSegmentX, y: currentSegmentYBefore }}
        stroke={color}
      />
      {/* A SMALL CIRCLE WITH WHITE BORDER ON THE CURRENT SEGMENT'S VALUE BEFORE*/}
      <circle
        cx={currentSegmentX}
        cy={currentSegmentYBefore}
        r={NODE_SIZE_HALF}
        fill={color}
        stroke={settings.colors.Monochromatic.White}
        strokeWidth={1}
        shapeRendering="geometricprecision"
      />
      {/* A SMALL RECTANGLE WITH WHITE BORDER ON THE CURRENT SEGMENT'S VALUE AFTER*/}
      <rect
        x={currentSegmentX - NODE_SIZE_HALF}
        y={currentSegmentYAfter - NODE_SIZE_HALF}
        width={NODE_SIZE}
        height={NODE_SIZE}
        fill={color}
        stroke={settings.colors.Monochromatic.White}
        strokeWidth={1}
        shapeRendering="crispedges"
      />
    </React.Fragment>
  );
}

function getLinesFor(
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
  remainingThicknesses: RemainingThickness[],
  line: 'slag' | 'iron'
) {
  const ret = [];
  for (let index = 0; index < remainingThicknesses.length; index++) {
    ret.push(getLineFor(xScale, yScale, remainingThicknesses, line, index));
  }

  const id = `group-remainingThickness-${line}`;

  return (
    <g key={id} id={id}>
      {ret}
    </g>
  );
}

interface Props {
  xScale: ScaleLinear<number, number>;
  remainingThicknesses: RemainingThickness[];
  critical: number;
}
const GraphRemainingThickness: React.ChildlessComponent<Props> = ({ xScale, remainingThicknesses, critical }) => {
  const graphAreaHeight = GRAPH_HEIGHT_TOTAL - 32;

  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (let index = 0; index < remainingThicknesses.length; index++) {
      const remainingThickness = ensure(remainingThicknesses[index]);
      maxValue = Math.max(maxValue, remainingThickness.slag.remainingThicknessBefore);
      maxValue = Math.max(maxValue, remainingThickness.slag.remainingThicknessAfter);
      maxValue = Math.max(maxValue, remainingThickness.iron.remainingThicknessBefore);
      maxValue = Math.max(maxValue, remainingThickness.slag.remainingThicknessAfter);
    }
    return maxValue;
  }, [remainingThicknesses]);

  //const xScale = useScaleX(graphAreaWidth);
  const yScale = useScaleY(graphAreaHeight, maxYValue);

  const measurementsAtTons = remainingThicknesses.map((remainingThickness) => remainingThickness.tonnage);

  const slagLine = useMemo(() => getLinesFor(xScale, yScale, remainingThicknesses, 'slag'), [xScale, yScale, remainingThicknesses]);
  const ironLine = useMemo(() => getLinesFor(xScale, yScale, remainingThicknesses, 'iron'), [xScale, yScale, remainingThicknesses]);

  return (
    <Graph
      graphHeightTotal={200}
      xScale={xScale}
      yAxisLabel="↑Thickness [mm]"
      graphLegends={GRAPH_LEGENDS}
      xAxisLabel="Amount of Steel [t]→"
      measurementsAtTons={measurementsAtTons}
      maxYValue={maxYValue}
    >
      {slagLine}
      {ironLine}
      <SDashedLine key="average-line" from={{ x: 0, y: yScale(critical) }} to={{ x: xScale.range()[1], y: yScale(critical) }} stroke={CRITICAL_LINE_COLOR} />
    </Graph>
  );
};

const SLine = styled(Line)`
  stroke-width: 2;
  shape-rendering: geometricprecision;
  pointer-events: none;
`;

GraphRemainingThickness.whyDidYouRender = true;
export default React.memo(GraphRemainingThickness);
