/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import { ensure } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';

import { useScaleY } from '../hooks/useScaleY';
import Graph from './Graph';

const GRAPH_HEIGHT_TOTAL = 90;
const BAR_WIDTH = 28;
const BAR_WIDTH_HALF = BAR_WIDTH / 2;

interface RepairMixAmount {
  atTons: number;
  amount: number;
}

function getBarFor(
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
  graphAreaHeight: number,
  repairMixAmounts: RepairMixAmount[],
  index: number
) {
  if (index === 0) {
    return null;
  }
  const repairMixAmountCurrent = ensure(repairMixAmounts[index]);
  const color = settings.colors.Primary.Blue_8;
  const barStartY = yScale(repairMixAmountCurrent.amount);
  const barHeight = graphAreaHeight - barStartY;
  return (
    <React.Fragment key={index}>
      {/* THE CURRENT SEGMENT'S MAIN BAR */}
      <rect
        key={repairMixAmountCurrent.atTons}
        x={xScale(repairMixAmountCurrent.atTons) - BAR_WIDTH_HALF}
        y={barStartY}
        width={BAR_WIDTH}
        height={barHeight}
        fill={color}
      />
    </React.Fragment>
  );
}

function getBarsFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, graphAreaHeight: number, repairMixAmounts: RepairMixAmount[]) {
  const ret = [];
  for (let index = 0; index < repairMixAmounts.length; index++) {
    ret.push(getBarFor(xScale, yScale, graphAreaHeight, repairMixAmounts, index));
  }
  return <g id="group-repairMixAmount">{ret}</g>;
}

interface Props {
  xScale: ScaleLinear<number, number>;
  repairMixAmounts: RepairMixAmount[];
}
const GraphRepairMixAmount: React.ChildlessComponent<Props> = ({ xScale, repairMixAmounts }) => {
  const graphAreaHeight = GRAPH_HEIGHT_TOTAL - 32;

  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (let index = 0; index < repairMixAmounts.length; index++) {
      const repairMixAmount = ensure(repairMixAmounts[index]);
      maxValue = Math.max(maxValue, repairMixAmount.amount);
    }
    return maxValue;
  }, [repairMixAmounts]);

  const yScale = useScaleY(graphAreaHeight, maxYValue);
  //const xScale = useScaleX(graphAreaWidth);

  const measurementsAtTons = repairMixAmounts.map((repairMixAmount) => repairMixAmount.atTons);

  const repairMixAmountBars = useMemo(() => getBarsFor(xScale, yScale, graphAreaHeight, repairMixAmounts), [xScale, yScale, graphAreaHeight, repairMixAmounts]);

  return (
    <Graph
      graphHeightTotal={GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yAxisLabel="Repair Mix Amount [t]"
      graphLegends={[]}
      xAxisLabel="Amount of Steel [t]→"
      measurementsAtTons={measurementsAtTons}
      maxYValue={maxYValue}
    >
      {repairMixAmountBars}
    </Graph>
  );
};

GraphRepairMixAmount.whyDidYouRender = true;
export default React.memo(GraphRepairMixAmount);
