import { Logger } from '@rhim/logging';
import { initialize as initializeCementAPI } from '@rhim/sdk/cement';
import { initialize as initializeCustomerManagementAPI } from '@rhim/sdk/customerManagement';
import { initialize as initializeFileIngestionAPI } from '@rhim/sdk/fileIngestion';
import { initialize as initializeLiningsAPI } from '@rhim/sdk/linings';
import { initialize as initializeMeasurementServiceAPI } from '@rhim/sdk/measurementService';
import { initialize as initializeOperatorDisplayAPI } from '@rhim/sdk/operatorDisplay';
import { initialize as initializeWearManagementAPI } from '@rhim/sdk/wearManagement';
import { isDefined, toArray } from '@rhim/utils';

import { environment } from './environments/environment';
import { axiosApiInstance } from './utilities';

initializeCementAPI(axiosApiInstance);
initializeWearManagementAPI(axiosApiInstance);
initializeFileIngestionAPI(axiosApiInstance);
initializeLiningsAPI(axiosApiInstance);
initializeMeasurementServiceAPI(axiosApiInstance);
initializeCustomerManagementAPI(axiosApiInstance);
initializeOperatorDisplayAPI(axiosApiInstance);

/**
 * Note: this global variable must be assigned in jest.setup.ts as well.
 */
globalThis.log = new Logger({
  logLevel: environment.logLevel,
  format: (message, _, context) => {
    if (isDefined(context)) {
      const breadcrumbs = toArray(context).map((breadcrumb) => `[${breadcrumb}]`);

      return ['%c' + breadcrumbs.join(' '), 'color: blue; font-weight: bold', ...message];
    }

    return message;
  },
});
