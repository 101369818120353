/* tslint:disable */
/* eslint-disable */
/**
 * OperatorDisplayService API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsMaintenanceTaskSeverity = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;

export type RHIMContractsMaintenanceTaskSeverity = typeof RHIMContractsMaintenanceTaskSeverity[keyof typeof RHIMContractsMaintenanceTaskSeverity];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsRegionLocation = {
    Bottom: 'Bottom',
    Barrel: 'Barrel',
    Cone: 'Cone',
    ThroatIn: 'ThroatIn',
    ThroatOut: 'ThroatOut'
} as const;

export type RHIMContractsRegionLocation = typeof RHIMContractsRegionLocation[keyof typeof RHIMContractsRegionLocation];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsThresholdType = {
    Target: 'Target',
    Lower: 'Lower',
    LowerPlotBoundary: 'LowerPlotBoundary',
    Critical: 'Critical',
    UpperPlotBoundary: 'UpperPlotBoundary',
    Upper: 'Upper'
} as const;

export type RHIMContractsThresholdType = typeof RHIMContractsThresholdType[keyof typeof RHIMContractsThresholdType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL',
    Aod: 'AOD',
    Bfr: 'BFR',
    Srf: 'SRF',
    Cou: 'COU',
    Hml: 'HML'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataLiningCondition = {
    Initial: 'Initial',
    NormalUsed: 'NormalUsed',
    AfterRepair: 'AfterRepair'
} as const;

export type RHIMMeasurementServiceContractsDataLiningCondition = typeof RHIMMeasurementServiceContractsDataLiningCondition[keyof typeof RHIMMeasurementServiceContractsDataLiningCondition];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataLiningMaintenanceType = {
    BricksChanged: 'BricksChanged',
    Gunning: 'Gunning',
    Fettling: 'Fettling',
    SlagSplashing: 'SlagSplashing'
} as const;

export type RHIMMeasurementServiceContractsDataLiningMaintenanceType = typeof RHIMMeasurementServiceContractsDataLiningMaintenanceType[keyof typeof RHIMMeasurementServiceContractsDataLiningMaintenanceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataMeasurementFileType = {
    _3d: '3d',
    Stl: 'stl',
    Pcraw: 'pcraw',
    Pcaligned: 'pcaligned',
    Pcprerendered: 'pcprerendered',
    Pcreduced: 'pcreduced',
    Rbl: 'rbl',
    Wear: 'wear',
    Pctrans: 'pctrans',
    Pcfiltered: 'pcfiltered',
    Hrrbl: 'hrrbl',
    Hrwear: 'hrwear',
    Wallplot: 'wallplot',
    Bottomplot: 'bottomplot',
    Meta: 'meta',
    Statistics: 'statistics',
    Sectioncuts: 'sectioncuts',
    Distancesplot: 'distancesplot',
    Video: 'video',
    Image: 'image',
    Segmentation: 'segmentation',
    Hrsegmentation: 'hrsegmentation',
    Report: 'report',
    Lcsvertex: 'lcsvertex',
    Ferrotronv1pointdata: 'ferrotronv1pointdata',
    Ferrotronv2pointdata: 'ferrotronv2pointdata',
    Imsipointdata: 'imsipointdata',
    Latitudepointdata: 'latitudepointdata',
    Volumes: 'volumes',
    Watertightmesh: 'watertightmesh',
    Regionvalues: 'regionvalues',
    Regions: 'regions'
} as const;

export type RHIMMeasurementServiceContractsDataMeasurementFileType = typeof RHIMMeasurementServiceContractsDataMeasurementFileType[keyof typeof RHIMMeasurementServiceContractsDataMeasurementFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataVesselLining = {
    Shell: 'Shell',
    ShellInsulation: 'ShellInsulation',
    PermanentLining: 'PermanentLining',
    WearLining: 'WearLining'
} as const;

export type RHIMMeasurementServiceContractsDataVesselLining = typeof RHIMMeasurementServiceContractsDataVesselLining[keyof typeof RHIMMeasurementServiceContractsDataVesselLining];


/**
 * Defines the parameters for calculating a wear curve:  ```      liningThickness(heat) = factor * e^(exponent * heat) + offset  ```
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsCurveModel
 */
export interface RHIMOperatorDisplayServiceV1ModelsCurveModel {
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsCurveModel
     */
    'factor': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsCurveModel
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsCurveModel
     */
    'exponent': number;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsHistoricMeasuredPointDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsHistoricMeasuredPointDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsHistoricMeasuredPointDto
     */
    'heat': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsHistoricMeasuredPointDto
     */
    'liningThickness': number;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto {
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto
     */
    'task'?: string | null;
}


/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
     */
    'heat': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
     */
    'totalHeatNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
     */
    'liningThickness': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
     */
    'measurementTimestamp'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'fileExtension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'fileType': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'vesselName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'vesselType': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'campaign': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'heat': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'uploadedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto
     */
    'fileId': string;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto {
    /**
     * 
     * @type {Array<RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto
     */
    'items': Array<RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto>;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto
     */
    'itemsCount': number;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto {
    /**
     * Unique identifier of the measured point
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'id': string;
    /**
     * Id of the region the measured point belongs to
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'regionId': string;
    /**
     * The campaign that the measured point is from
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'campaign': number;
    /**
     * Heat number of the measurement
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'heat': number;
    /**
     * Measured brick length
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'brickLength': number;
    /**
     * Timestamp of the measurement (if available)  Expected to be stored in UTC.
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'timestamp'?: string | null;
    /**
     * The R coordinate.  Has a value if !:CoordinateSystem is !:CoordinateSystem2D.RTheta
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'r'?: number | null;
    /**
     * The Theta coordinate.  Has a value if !:CoordinateSystem is !:CoordinateSystem2D.RTheta or !:CoordinateSystem2D.ThetaZ
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'theta'?: number | null;
    /**
     * The Z coordinate.  Has a value if !:CoordinateSystem is !:CoordinateSystem2D.ThetaZ
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'z'?: number | null;
    /**
     * The X coordinate.  Has a value if !:CoordinateSystem is !:CoordinateSystem2D.XY
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'x'?: number | null;
    /**
     * The Y coordinate.  Has a value if !:CoordinateSystem is !:CoordinateSystem2D.XY
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto
     */
    'y'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
 */
export interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel {
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'isHeatMappingInvalidOrMissing': boolean;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'campaign': number;
    /**
     * Retrieves the maximum heat number of all RHIM.OperatorDisplayService.V1.Models.MeasuredPointDto\">measurements</see> of all <see cref=\"P:RHIM.OperatorDisplayService.V1.Models.OperatorDataViewModel.Regions  or null if there are no measurements.
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'lastHeat'?: number | null;
    /**
     * Retrieves the maximum heat number (represented as Total Heats if available) of all RHIM.OperatorDisplayService.V1.Models.MeasuredPointDto\">measurements</see> of all <see cref=\"P:RHIM.OperatorDisplayService.V1.Models.OperatorDataViewModel.Regions  or null if there are no measurements.
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'lastTotalHeat'?: number | null;
    /**
     * Retrieves the timestamp of the latest RHIM.OperatorDisplayService.V1.Models.MeasuredPointDto\">measurements</see> of all <see cref=\"P:RHIM.OperatorDisplayService.V1.Models.OperatorDataViewModel.Regions  or null if there are no measurements or the measurements at the latest heat do not contain any timestamp.
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'lastMeasurementTimestamp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'targetLifeTime': number;
    /**
     * Provides the average of RHIM.OperatorDisplayService.V1.Models.OperatorDataViewRegionModel.WearSpeed.  Regions where RHIM.OperatorDisplayService.V1.Models.OperatorDataViewRegionModel.WearSpeed is null are excluded from the calculation.  The result is rounded to two decimal places.  <br>      If no regions or measurements exist null is returned.  
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'averageWearSpeed'?: number | null;
    /**
     * Provides the average measurement gap as defined by the last measured heat divided by the number of unique heats with measurements.  The result is rounded to two decimal places.  <br>      If no regions or measurements exist, null is returned.  
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'averageMeasurementGap'?: number | null;
    /**
     * Provides the regions ordered by RHIM.OperatorDisplayService.V1.Models.OperatorDataViewRegionModel.Order.  <br>      The list is ordered during setting the value, it does not need to be ordered before hand.  <br>      Must not be null.  
     * @type {Array<RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel
     */
    'regions': Array<RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel>;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
 */
export interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis {
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'wearRateAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'wearRateInstantaneous'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'wearRateRelatedMinimum'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'potentialAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'potentialMinimum'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'thresholdTarget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'thresholdLower'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'currentLiningThickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'minimumLiningThickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'deltaTarget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionKpis
     */
    'deltaLower'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
 */
export interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel {
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     * @deprecated
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'shortName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'brickSupplier': Array<string>;
    /**
     * Calculates the reduction of lining thickness based of the information given by RHIM.OperatorDisplayService.V1.Models.OperatorDataViewRegionModel.MeasuredPoints<br>      To calculate the wear speed, first the reduction of the lining thickness between two adjacent measurements is divided by the amount of heats between those measurements.      The wear speed is defined as the average over the results of these calculations.  <para>      If no measurements exist, null is returned.  </para><br>      The calculation only works if      <list type=\"bullet\"><item>RHIM.OperatorDisplayService.V1.Models.OperatorDataViewRegionModel.InitialLiningThickness is set</item><item>the measurements are \"consistent\" / reasonable (no negative measurements, single measurement for single heat, ...)</item></list><br>  The wear speed is provided as `mm/heat`, rounded to two decimal places.  
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'wearSpeed'?: number | null;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'initialLiningThickness': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'criticalLiningThickness'?: number | null;
    /**
     * 
     * @type {RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelTargetLiningThicknessCurve}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'targetLiningThicknessCurve'?: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelTargetLiningThicknessCurve | null;
    /**
     * 
     * @type {Array<RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'thresholdCurves': Array<RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel>;
    /**
     * Provides the (minimum) measured points (!:MinimumMeasuredPoint) ordered by RHIM.OperatorDisplayService.V1.Models.MeasuredPointDto.Heat.  <br>      The list is ordered during setting the value, it does not need to be ordered before hand.  <br>      Must not be null.  
     * @type {Array<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'measuredPoints': Array<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto>;
    /**
     * 
     * @type {Array<RHIMOperatorDisplayServiceV1ModelsHistoricMeasuredPointDto>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'historicMeasuredPoints': Array<RHIMOperatorDisplayServiceV1ModelsHistoricMeasuredPointDto>;
    /**
     * 
     * @type {Array<RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'maintenanceTasks': Array<RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto>;
    /**
     * 
     * @type {RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel
     */
    'kpis'?: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis | null;
}


/**
 * The KPIs for the region
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
 */
export interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis {
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'wearRateAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'wearRateInstantaneous'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'wearRateRelatedMinimum'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'potentialAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'potentialMinimum'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'thresholdTarget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'thresholdLower'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'currentLiningThickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'minimumLiningThickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'deltaTarget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis
     */
    'deltaLower'?: number | null;
}
/**
 * The target curve which models the nominal wear. Could be null if no target curve is configured on the region
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelTargetLiningThicknessCurve
 */
export interface RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelTargetLiningThicknessCurve {
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelTargetLiningThicknessCurve
     */
    'factor': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelTargetLiningThicknessCurve
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelTargetLiningThicknessCurve
     */
    'exponent': number;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsRegionMinimumBrickLengthDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsRegionMinimumBrickLengthDto {
    /**
     * The Id of the Region
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionMinimumBrickLengthDto
     */
    'regionId': string;
    /**
     * Measured brick length
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionMinimumBrickLengthDto
     */
    'minimumBrickLength': number;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto
     */
    'regionId': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto
     */
    'shortName': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto
     */
    'display': boolean;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsRegionWithMinimumMeasuredPointsDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsRegionWithMinimumMeasuredPointsDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionWithMinimumMeasuredPointsDto
     */
    'id': string;
    /**
     * 
     * @type {RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto}
     * @memberof RHIMOperatorDisplayServiceV1ModelsRegionWithMinimumMeasuredPointsDto
     */
    'minimumMeasuredPoint'?: RHIMOperatorDisplayServiceV1ModelsMinimumMeasuredPointDto | null;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel
 */
export interface RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel {
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel
     */
    'maintenanceTask'?: string | null;
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {RHIMContractsThresholdType}
     * @memberof RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel
     */
    'wearCurveType': RHIMContractsThresholdType;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel
     */
    'factor': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsThresholdCurveModel
     */
    'exponent': number;
}


/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsUpdateRegionOrderAndVisibilityDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsUpdateRegionOrderAndVisibilityDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceV1ModelsUpdateRegionOrderAndVisibilityDto
     */
    'regionId': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceV1ModelsUpdateRegionOrderAndVisibilityDto
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsUpdateRegionOrderAndVisibilityDto
     */
    'display': boolean;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto {
    /**
     * 
     * @type {Array<RHIMOperatorDisplayServiceV1ModelsUpdateRegionOrderAndVisibilityDto>}
     * @memberof RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto
     */
    'updateRegionOrderAndVisibilityDtos': Array<RHIMOperatorDisplayServiceV1ModelsUpdateRegionOrderAndVisibilityDto>;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto {
    /**
     * Enables the calculation of the Wear Rate Average
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableWearRateAverage': boolean;
    /**
     * Enables the Wear Rate Instantaneous (wear rate between the last two measurements) calculation
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableWearRateInstantaneous': boolean;
    /**
     * Enables the calculation of the Wear Rate Related to the Minimum Measurement of the campaign
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableWearRateRelatedMinimum': boolean;
    /**
     * Enable Potential to Average (How long the campaign could go based on the average wear rate)
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enablePotentialAverage': boolean;
    /**
     * Enable Potential to Minimum (How long the campaign could go based on the minimum wear rate)
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enablePotentialMinimum': boolean;
    /**
     * Enable the calculation of the target threshold lining thickness
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableThresholdTarget': boolean;
    /**
     * Enable the calculation of the lower threshold lining thickness
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableThresholdLower': boolean;
    /**
     * Enables the display of the Current Lining Thickness
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableCurrentLiningThickness': boolean;
    /**
     * Enable the display of the Minimum Lining Thickness
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableMinimumLiningThickness': boolean;
    /**
     * Enables the calculation of the delta Lining Thickness to the target threshold
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableDeltaTarget': boolean;
    /**
     * Enables the calculation of the delta Lining Thickness to the lower threshold
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto
     */
    'enableDeltaLower': boolean;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
 */
export interface RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto {
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityWearRateAverage'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityWearRateInstantaneous'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityWearRateRelatedMinimum'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityPotentialAverage'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityPotentialMinimum'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityThresholdTarget'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityThresholdLower'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityCurrentLiningThickness'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityMinimumLiningThickness'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityDeltaTarget'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto
     */
    'visibilityDeltaLower'?: boolean | null;
}

/**
 * MeasurementFilesApi - axios parameter creator
 * @export
 */
export const MeasurementFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a file
         * @summary deletes a file
         * @param {string} fileId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeasurementfilesFileid: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteMeasurementfilesFileid', 'fileId', fileId)
            const localVarPath = `/MeasurementFiles/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the file data
         * @summary Retrieves the file data
         * @param {string} customerId Format - uuid.
         * @param {number} [take] Format - int32.
         * @param {number} [skip] Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesCustomeridList: async (customerId: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getMeasurementfilesCustomeridList', 'customerId', customerId)
            const localVarPath = `/MeasurementFiles/{customerId}/list`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads a file
         * @summary Downloads a file
         * @param {string} fileId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesFileid: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getMeasurementfilesFileid', 'fileId', fileId)
            const localVarPath = `/MeasurementFiles/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementFilesApi - functional programming interface
 * @export
 */
export const MeasurementFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * deletes a file
         * @summary deletes a file
         * @param {string} fileId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeasurementfilesFileid(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeasurementfilesFileid(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the file data
         * @summary Retrieves the file data
         * @param {string} customerId Format - uuid.
         * @param {number} [take] Format - int32.
         * @param {number} [skip] Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementfilesCustomeridList(customerId: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementfilesCustomeridList(customerId, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads a file
         * @summary Downloads a file
         * @param {string} fileId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementfilesFileid(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementfilesFileid(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementFilesApi - factory interface
 * @export
 */
export const MeasurementFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementFilesApiFp(configuration)
    return {
        /**
         * deletes a file
         * @summary deletes a file
         * @param {string} fileId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeasurementfilesFileid(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMeasurementfilesFileid(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the file data
         * @summary Retrieves the file data
         * @param {string} customerId Format - uuid.
         * @param {number} [take] Format - int32.
         * @param {number} [skip] Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesCustomeridList(customerId: string, take?: number, skip?: number, options?: any): AxiosPromise<RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto> {
            return localVarFp.getMeasurementfilesCustomeridList(customerId, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * Downloads a file
         * @summary Downloads a file
         * @param {string} fileId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesFileid(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getMeasurementfilesFileid(fileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementFilesApi - object-oriented interface
 * @export
 * @class MeasurementFilesApi
 * @extends {BaseAPI}
 */
export class MeasurementFilesApi extends BaseAPI {
    /**
     * deletes a file
     * @summary deletes a file
     * @param {string} fileId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementFilesApi
     */
    public deleteMeasurementfilesFileid(fileId: string, options?: AxiosRequestConfig) {
        return MeasurementFilesApiFp(this.configuration).deleteMeasurementfilesFileid(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the file data
     * @summary Retrieves the file data
     * @param {string} customerId Format - uuid.
     * @param {number} [take] Format - int32.
     * @param {number} [skip] Format - int32.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementFilesApi
     */
    public getMeasurementfilesCustomeridList(customerId: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return MeasurementFilesApiFp(this.configuration).getMeasurementfilesCustomeridList(customerId, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Downloads a file
     * @summary Downloads a file
     * @param {string} fileId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementFilesApi
     */
    public getMeasurementfilesFileid(fileId: string, options?: AxiosRequestConfig) {
        return MeasurementFilesApiFp(this.configuration).getMeasurementfilesFileid(fileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MinimumMeasuredPointsApi - axios parameter creator
 * @export
 */
export const MinimumMeasuredPointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all regions including the last minimum measured point
         * @summary Retrieves all regions including the last minimum measured point
         * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimummeasuredpointsVesselidLatestmeasuredpoint: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMinimummeasuredpointsVesselidLatestmeasuredpoint', 'vesselId', vesselId)
            const localVarPath = `/MinimumMeasuredPoints/{vesselId}/latestmeasuredpoint`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
         * @summary Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
         * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
         * @param {number} campaign Format - int32. The campaign
         * @param {number} heat Format - int32. The heat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC: async (vesselId: string, campaign: number, heat: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC', 'campaign', campaign)
            // verify required parameter 'heat' is not null or undefined
            assertParamExists('getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC', 'heat', heat)
            const localVarPath = `/MinimumMeasuredPoints/{vesselId}/minimummeasuredpointsformeasurementview/{campaign}/{heat}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)))
                .replace(`{${"heat"}}`, encodeURIComponent(String(heat)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MinimumMeasuredPointsApi - functional programming interface
 * @export
 */
export const MinimumMeasuredPointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MinimumMeasuredPointsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all regions including the last minimum measured point
         * @summary Retrieves all regions including the last minimum measured point
         * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinimummeasuredpointsVesselidLatestmeasuredpoint(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMOperatorDisplayServiceV1ModelsRegionWithMinimumMeasuredPointsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinimummeasuredpointsVesselidLatestmeasuredpoint(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
         * @summary Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
         * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
         * @param {number} campaign Format - int32. The campaign
         * @param {number} heat Format - int32. The heat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC(vesselId: string, campaign: number, heat: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMOperatorDisplayServiceV1ModelsRegionMinimumBrickLengthDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC(vesselId, campaign, heat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MinimumMeasuredPointsApi - factory interface
 * @export
 */
export const MinimumMeasuredPointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MinimumMeasuredPointsApiFp(configuration)
    return {
        /**
         * Retrieves all regions including the last minimum measured point
         * @summary Retrieves all regions including the last minimum measured point
         * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimummeasuredpointsVesselidLatestmeasuredpoint(vesselId: string, options?: any): AxiosPromise<Array<RHIMOperatorDisplayServiceV1ModelsRegionWithMinimumMeasuredPointsDto>> {
            return localVarFp.getMinimummeasuredpointsVesselidLatestmeasuredpoint(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
         * @summary Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
         * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
         * @param {number} campaign Format - int32. The campaign
         * @param {number} heat Format - int32. The heat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC(vesselId: string, campaign: number, heat: number, options?: any): AxiosPromise<Array<RHIMOperatorDisplayServiceV1ModelsRegionMinimumBrickLengthDto>> {
            return localVarFp.getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC(vesselId, campaign, heat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MinimumMeasuredPointsApi - object-oriented interface
 * @export
 * @class MinimumMeasuredPointsApi
 * @extends {BaseAPI}
 */
export class MinimumMeasuredPointsApi extends BaseAPI {
    /**
     * Retrieves all regions including the last minimum measured point
     * @summary Retrieves all regions including the last minimum measured point
     * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinimumMeasuredPointsApi
     */
    public getMinimummeasuredpointsVesselidLatestmeasuredpoint(vesselId: string, options?: AxiosRequestConfig) {
        return MinimumMeasuredPointsApiFp(this.configuration).getMinimummeasuredpointsVesselidLatestmeasuredpoint(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
     * @summary Returns the min. brick length to each region for a vessel, campaign and heat. Only region with the tag MeasurementView are returned
     * @param {string} vesselId Format - uuid. Id of the vessel for which the regions should be retrieved
     * @param {number} campaign Format - int32. The campaign
     * @param {number} heat Format - int32. The heat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinimumMeasuredPointsApi
     */
    public getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC(vesselId: string, campaign: number, heat: number, options?: AxiosRequestConfig) {
        return MinimumMeasuredPointsApiFp(this.configuration).getMinimummeasuredpointsVesselidMinimummeasuredpointsformeasurementviewC(vesselId, campaign, heat, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OperatorDataViewApi - axios parameter creator
 * @export
 */
export const OperatorDataViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API providing the data for the operator view
         * @summary API providing the data for the operator view
         * @param {string} vesselId Format - uuid. id of the vessel for which the regions should be displayed
         * @param {number} [campaign] Format - int32. From which campaign the data should be shown. (This parameter is optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatordataviewVesselid: async (vesselId: string, campaign?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getOperatordataviewVesselid', 'vesselId', vesselId)
            const localVarPath = `/OperatorDataView/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaign !== undefined) {
                localVarQueryParameter['campaign'] = campaign;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API providing the available campaigns for a vessel
         * @summary API providing the available campaigns for a vessel
         * @param {string} vesselId Format - uuid. id of the vessel for which the available campaigns should get returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatordataviewVesselidCampaign: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getOperatordataviewVesselidCampaign', 'vesselId', vesselId)
            const localVarPath = `/OperatorDataView/{vesselId}/campaign`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorDataViewApi - functional programming interface
 * @export
 */
export const OperatorDataViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperatorDataViewApiAxiosParamCreator(configuration)
    return {
        /**
         * API providing the data for the operator view
         * @summary API providing the data for the operator view
         * @param {string} vesselId Format - uuid. id of the vessel for which the regions should be displayed
         * @param {number} [campaign] Format - int32. From which campaign the data should be shown. (This parameter is optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatordataviewVesselid(vesselId: string, campaign?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatordataviewVesselid(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API providing the available campaigns for a vessel
         * @summary API providing the available campaigns for a vessel
         * @param {string} vesselId Format - uuid. id of the vessel for which the available campaigns should get returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatordataviewVesselidCampaign(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatordataviewVesselidCampaign(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperatorDataViewApi - factory interface
 * @export
 */
export const OperatorDataViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperatorDataViewApiFp(configuration)
    return {
        /**
         * API providing the data for the operator view
         * @summary API providing the data for the operator view
         * @param {string} vesselId Format - uuid. id of the vessel for which the regions should be displayed
         * @param {number} [campaign] Format - int32. From which campaign the data should be shown. (This parameter is optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatordataviewVesselid(vesselId: string, campaign?: number, options?: any): AxiosPromise<RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel> {
            return localVarFp.getOperatordataviewVesselid(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * API providing the available campaigns for a vessel
         * @summary API providing the available campaigns for a vessel
         * @param {string} vesselId Format - uuid. id of the vessel for which the available campaigns should get returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatordataviewVesselidCampaign(vesselId: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getOperatordataviewVesselidCampaign(vesselId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperatorDataViewApi - object-oriented interface
 * @export
 * @class OperatorDataViewApi
 * @extends {BaseAPI}
 */
export class OperatorDataViewApi extends BaseAPI {
    /**
     * API providing the data for the operator view
     * @summary API providing the data for the operator view
     * @param {string} vesselId Format - uuid. id of the vessel for which the regions should be displayed
     * @param {number} [campaign] Format - int32. From which campaign the data should be shown. (This parameter is optional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorDataViewApi
     */
    public getOperatordataviewVesselid(vesselId: string, campaign?: number, options?: AxiosRequestConfig) {
        return OperatorDataViewApiFp(this.configuration).getOperatordataviewVesselid(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API providing the available campaigns for a vessel
     * @summary API providing the available campaigns for a vessel
     * @param {string} vesselId Format - uuid. id of the vessel for which the available campaigns should get returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorDataViewApi
     */
    public getOperatordataviewVesselidCampaign(vesselId: string, options?: AxiosRequestConfig) {
        return OperatorDataViewApiFp(this.configuration).getOperatordataviewVesselidCampaign(vesselId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegionsOrderApi - axios parameter creator
 * @export
 */
export const RegionsOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the order and visibility information of regions
         * @summary Get the order and visibility information of regions
         * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselVesselidOrder: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselVesselidOrder', 'vesselId', vesselId)
            const localVarPath = `/vessel/{vesselId}/order`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Changes the order and visibility of regions
         * @summary Changes the order and visibility of regions
         * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
         * @param {RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto} [rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto] Contains the information for the update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselVesselidOrder: async (vesselId: string, rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto?: RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselVesselidOrder', 'vesselId', vesselId)
            const localVarPath = `/vessel/{vesselId}/order`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsOrderApi - functional programming interface
 * @export
 */
export const RegionsOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionsOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the order and visibility information of regions
         * @summary Get the order and visibility information of regions
         * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselVesselidOrder(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselVesselidOrder(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Changes the order and visibility of regions
         * @summary Changes the order and visibility of regions
         * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
         * @param {RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto} [rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto] Contains the information for the update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselVesselidOrder(vesselId: string, rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto?: RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselVesselidOrder(vesselId, rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionsOrderApi - factory interface
 * @export
 */
export const RegionsOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionsOrderApiFp(configuration)
    return {
        /**
         * Get the order and visibility information of regions
         * @summary Get the order and visibility information of regions
         * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselVesselidOrder(vesselId: string, options?: any): AxiosPromise<Array<RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto>> {
            return localVarFp.getVesselVesselidOrder(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Changes the order and visibility of regions
         * @summary Changes the order and visibility of regions
         * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
         * @param {RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto} [rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto] Contains the information for the update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselVesselidOrder(vesselId: string, rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto?: RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselVesselidOrder(vesselId, rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegionsOrderApi - object-oriented interface
 * @export
 * @class RegionsOrderApi
 * @extends {BaseAPI}
 */
export class RegionsOrderApi extends BaseAPI {
    /**
     * Get the order and visibility information of regions
     * @summary Get the order and visibility information of regions
     * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsOrderApi
     */
    public getVesselVesselidOrder(vesselId: string, options?: AxiosRequestConfig) {
        return RegionsOrderApiFp(this.configuration).getVesselVesselidOrder(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Changes the order and visibility of regions
     * @summary Changes the order and visibility of regions
     * @param {string} vesselId Format - uuid. To which vessel the regions are belonging
     * @param {RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto} [rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto] Contains the information for the update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsOrderApi
     */
    public putVesselVesselidOrder(vesselId: string, rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto?: RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, options?: AxiosRequestConfig) {
        return RegionsOrderApiFp(this.configuration).putVesselVesselidOrder(vesselId, rHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VesselKpiSettingsApi - axios parameter creator
 * @export
 */
export const VesselKpiSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API to get KPI settings for a vessel for WMS
         * @summary API to get KPI settings for a vessel for WMS
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselkpisettingsDisplayVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselkpisettingsDisplayVesselid', 'vesselId', vesselId)
            const localVarPath = `/VesselKpiSettings/display/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get KPI settings for a vessel
         * @summary API to get KPI settings for a vessel
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselkpisettingsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselkpisettingsVesselid', 'vesselId', vesselId)
            const localVarPath = `/VesselKpiSettings/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to upsert KPI settings for a customer and vessel type
         * @summary API to upsert KPI settings for a customer and vessel type
         * @param {string} customerId Format - uuid.
         * @param {string} vesselType 
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsCustomeridVesseltype: async (customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putVesselkpisettingsCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('putVesselkpisettingsCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/VesselKpiSettings/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to upsert KPI settings for a customer and vessel type for WMS
         * @summary API to upsert KPI settings for a customer and vessel type for WMS
         * @param {string} customerId Format - uuid.
         * @param {string} vesselType 
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsDisplayCustomeridVesseltype: async (customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putVesselkpisettingsDisplayCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('putVesselkpisettingsDisplayCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/VesselKpiSettings/display/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to upsert KPI settings for a vessel for WMS
         * @summary API to upsert KPI settings for a vessel for WMS
         * @param {string} vesselId Format - uuid.
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsDisplayVesselid: async (vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselkpisettingsDisplayVesselid', 'vesselId', vesselId)
            const localVarPath = `/VesselKpiSettings/display/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to upsert KPI settings for a vessel
         * @summary API to upsert KPI settings for a vessel
         * @param {string} vesselId Format - uuid.
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsVesselid: async (vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselkpisettingsVesselid', 'vesselId', vesselId)
            const localVarPath = `/VesselKpiSettings/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselKpiSettingsApi - functional programming interface
 * @export
 */
export const VesselKpiSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselKpiSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * API to get KPI settings for a vessel for WMS
         * @summary API to get KPI settings for a vessel for WMS
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselkpisettingsDisplayVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselkpisettingsDisplayVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get KPI settings for a vessel
         * @summary API to get KPI settings for a vessel
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselkpisettingsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselkpisettingsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to upsert KPI settings for a customer and vessel type
         * @summary API to upsert KPI settings for a customer and vessel type
         * @param {string} customerId Format - uuid.
         * @param {string} vesselType 
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselkpisettingsCustomeridVesseltype(customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselkpisettingsCustomeridVesseltype(customerId, vesselType, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to upsert KPI settings for a customer and vessel type for WMS
         * @summary API to upsert KPI settings for a customer and vessel type for WMS
         * @param {string} customerId Format - uuid.
         * @param {string} vesselType 
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselkpisettingsDisplayCustomeridVesseltype(customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselkpisettingsDisplayCustomeridVesseltype(customerId, vesselType, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to upsert KPI settings for a vessel for WMS
         * @summary API to upsert KPI settings for a vessel for WMS
         * @param {string} vesselId Format - uuid.
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselkpisettingsDisplayVesselid(vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselkpisettingsDisplayVesselid(vesselId, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to upsert KPI settings for a vessel
         * @summary API to upsert KPI settings for a vessel
         * @param {string} vesselId Format - uuid.
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselkpisettingsVesselid(vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselkpisettingsVesselid(vesselId, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselKpiSettingsApi - factory interface
 * @export
 */
export const VesselKpiSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselKpiSettingsApiFp(configuration)
    return {
        /**
         * API to get KPI settings for a vessel for WMS
         * @summary API to get KPI settings for a vessel for WMS
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselkpisettingsDisplayVesselid(vesselId: string, options?: any): AxiosPromise<RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto> {
            return localVarFp.getVesselkpisettingsDisplayVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get KPI settings for a vessel
         * @summary API to get KPI settings for a vessel
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselkpisettingsVesselid(vesselId: string, options?: any): AxiosPromise<RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto> {
            return localVarFp.getVesselkpisettingsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * API to upsert KPI settings for a customer and vessel type
         * @summary API to upsert KPI settings for a customer and vessel type
         * @param {string} customerId Format - uuid.
         * @param {string} vesselType 
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsCustomeridVesseltype(customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselkpisettingsCustomeridVesseltype(customerId, vesselType, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API to upsert KPI settings for a customer and vessel type for WMS
         * @summary API to upsert KPI settings for a customer and vessel type for WMS
         * @param {string} customerId Format - uuid.
         * @param {string} vesselType 
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsDisplayCustomeridVesseltype(customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselkpisettingsDisplayCustomeridVesseltype(customerId, vesselType, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API to upsert KPI settings for a vessel for WMS
         * @summary API to upsert KPI settings for a vessel for WMS
         * @param {string} vesselId Format - uuid.
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsDisplayVesselid(vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselkpisettingsDisplayVesselid(vesselId, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API to upsert KPI settings for a vessel
         * @summary API to upsert KPI settings for a vessel
         * @param {string} vesselId Format - uuid.
         * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselkpisettingsVesselid(vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselkpisettingsVesselid(vesselId, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselKpiSettingsApi - object-oriented interface
 * @export
 * @class VesselKpiSettingsApi
 * @extends {BaseAPI}
 */
export class VesselKpiSettingsApi extends BaseAPI {
    /**
     * API to get KPI settings for a vessel for WMS
     * @summary API to get KPI settings for a vessel for WMS
     * @param {string} vesselId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselKpiSettingsApi
     */
    public getVesselkpisettingsDisplayVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return VesselKpiSettingsApiFp(this.configuration).getVesselkpisettingsDisplayVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get KPI settings for a vessel
     * @summary API to get KPI settings for a vessel
     * @param {string} vesselId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselKpiSettingsApi
     */
    public getVesselkpisettingsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return VesselKpiSettingsApiFp(this.configuration).getVesselkpisettingsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to upsert KPI settings for a customer and vessel type
     * @summary API to upsert KPI settings for a customer and vessel type
     * @param {string} customerId Format - uuid.
     * @param {string} vesselType 
     * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselKpiSettingsApi
     */
    public putVesselkpisettingsCustomeridVesseltype(customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options?: AxiosRequestConfig) {
        return VesselKpiSettingsApiFp(this.configuration).putVesselkpisettingsCustomeridVesseltype(customerId, vesselType, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to upsert KPI settings for a customer and vessel type for WMS
     * @summary API to upsert KPI settings for a customer and vessel type for WMS
     * @param {string} customerId Format - uuid.
     * @param {string} vesselType 
     * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselKpiSettingsApi
     */
    public putVesselkpisettingsDisplayCustomeridVesseltype(customerId: string, vesselType: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options?: AxiosRequestConfig) {
        return VesselKpiSettingsApiFp(this.configuration).putVesselkpisettingsDisplayCustomeridVesseltype(customerId, vesselType, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to upsert KPI settings for a vessel for WMS
     * @summary API to upsert KPI settings for a vessel for WMS
     * @param {string} vesselId Format - uuid.
     * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselKpiSettingsApi
     */
    public putVesselkpisettingsDisplayVesselid(vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options?: AxiosRequestConfig) {
        return VesselKpiSettingsApiFp(this.configuration).putVesselkpisettingsDisplayVesselid(vesselId, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to upsert KPI settings for a vessel
     * @summary API to upsert KPI settings for a vessel
     * @param {string} vesselId Format - uuid.
     * @param {RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto} [rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselKpiSettingsApi
     */
    public putVesselkpisettingsVesselid(vesselId: string, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options?: AxiosRequestConfig) {
        return VesselKpiSettingsApiFp(this.configuration).putVesselkpisettingsVesselid(vesselId, rHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



