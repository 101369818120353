import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { CheckmarkIcon, ChevronDownIcon, ChevronUpIcon, XMiniIcon } from '@rhim/icons/16';
import { Button, IconButton, message, Spin, Spinner } from '@rhim/react';
import {
  RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto,
  RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto,
  RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto,
} from '@rhim/rest/operatorDisplay';
import { API as WMSApi, useVesselWmsKpiSettings } from '@rhim/sdk/operatorDisplay';
import {
  applyChangesButtonRegionSelectionContainer,
  cancelButtonRegionSelectionContainer,
  closeButtonRegionSelectionContainer,
  descriptionKPISelectionContainer,
  descriptionRegionSelectionContainer,
  kpiSelectionLabelSelectionContainer,
  regionSelectionContainerOperatorDisplay,
  regionSelectionLabelSelectionContainer,
  titleRegionSelectionContainer,
} from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useState } from 'react';
import { useList } from 'react-use';
import styled from 'styled-components';

import { API } from '../../api/operatorDisplay';
import { useRegionsOrder, useWMSData } from '../../hooks';
import KpisSettingsPanel from './KpisSettingsPanel';
import ListSortWrapper from './ListSort';

interface Props {
  vesselId: UUID;
  visible: boolean;
  callback?: () => void;
  data: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[];
  toggleVisibility: (value: boolean) => void;
  campaignId?: number;
  comparedCampaign?: number;
  kpisSettings?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto;
}

export const DisplaySettingsPanel = ({ vesselId, kpisSettings, callback, toggleVisibility, visible, data, campaignId, comparedCampaign }: Props) => {
  const queryClient = useQueryClient();
  const { t } = i18nReact.useTranslation(['operator-display']);
  const [active, setActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [regions, actions] = useList((): RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[] =>
    data.map((region, index) => ({ ...region, order: index }))
  );
  const [kpis, setKpis] = useState(kpisSettings);

  const [isRegionsPanelExpanded, setRegionsPanelExpanded] = useState(false);
  const [isKpiSettingsExpanded, setKpiSettingExpanded] = useState(false);

  const onKpiChange = (pair: Partial<RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto>) => {
    if (isDefined(kpis)) {
      setKpis({ ...kpis, ...pair });

      if (!active) {
        setActive(true);
      }
    }
  };

  React.useEffect(() => {
    actions.set(data);
  }, [data, actions]);

  React.useEffect(() => {
    setKpis(kpisSettings);
  }, [kpisSettings]);

  React.useEffect(() => {
    if (!visible) {
      setActive(false);
      actions.set(data);
    }
  }, [data, visible, actions]);

  const mutation = useMutation(
    async (values: RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto) => {
      await API.vessel
        .putVesselVesselidOrder(vesselId, values, {
          headers: {
            'Content-Type': 'application/json',
          },
        } as AxiosRequestConfig)
        .then((response) => response.data)
        .catch((error: Error) => {
          assert(axios.isAxiosError(error), 'Expected an Axios error');
          assert(isDefined(error.response), 'Expected API errors to always contain a payload');

          const detail: string = error.response.data.detail;
          return message.error(detail || error.message);
        });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(useRegionsOrder.getKey(vesselId), { refetchType: 'all' });
        await queryClient.invalidateQueries(useWMSData.getKey(vesselId, isDefined(campaignId) ? campaignId.toString() : campaignId));
        if (isDefined(comparedCampaign)) {
          await queryClient.invalidateQueries(useWMSData.getKey(vesselId, comparedCampaign.toString()));
        }
      },
    }
  );

  const submitChanges = async () => {
    setLoading(true);
    const updateRegionOrderAndVisibilityDtos = regions.map((region, index) => ({
      ...region,
      order: index,
    }));

    const values = {
      updateRegionOrderAndVisibilityDtos,
    };

    try {
      await kpisMutation.mutateAsync();
      await mutation.mutateAsync(values);
    } catch (error) {
      const msj = error instanceof Error ? error.message : 'Something went wrong. Please try again!';
      message.error(msj);
    }

    setLoading(false);
    setActive(false);
    toggleVisibility(false); // Close modal after submiting.

    if (isDefined(callback)) {
      callback();
    }
  };

  const onClick = () => {
    toggleVisibility(false);
    setActive(false);
    actions.set(data);
  };

  const kpisMutation = useMutation(
    async () => {
      await WMSApi.vesselKpiSettingsApi.putVesselkpisettingsDisplayVesselid(vesselId, kpis).then((response) => response.data);
    },
    {
      onSuccess: async () => {
        setActive(false);
        await queryClient.invalidateQueries(useVesselWmsKpiSettings.getKey(vesselId));
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    }
  );

  return (
    <Nest>
      <Banner visible={visible}>
        <Container data-test-id={regionSelectionContainerOperatorDisplay} loading={loading}>
          <div>
            <HeaderBar tabIndex={1}>
              <Title data-test-id={titleRegionSelectionContainer}>{t('operator-display:sortList.title')}</Title>
              <XMiniIcon
                data-test-id={closeButtonRegionSelectionContainer}
                fill={settings.colors.Primary.Blue_9}
                style={{ cursor: 'pointer' }}
                onClick={onClick}
              />
            </HeaderBar>
          </div>
          <CollapsiblePanels>
            <RegionCollapsePanel height={isRegionsPanelExpanded ? 'auto' : '120px'}>
              <Content>
                <Header onClick={() => setRegionsPanelExpanded(!isRegionsPanelExpanded)}>
                  <div>
                    <DescriptionTitle active={true} data-test-id={regionSelectionLabelSelectionContainer}>
                      {t('operator-display:sortList.header')}
                    </DescriptionTitle>
                    <Description data-test-id={descriptionRegionSelectionContainer}>{t('operator-display:sortList.description')}</Description>
                  </div>
                  <IconButton
                    icon={isRegionsPanelExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    onPress={() => {
                      setRegionsPanelExpanded(!isRegionsPanelExpanded);
                    }}
                  />
                </Header>
                {isRegionsPanelExpanded && <ListSortWrapper regions={regions} actions={actions} setActive={setActive} />}
              </Content>
            </RegionCollapsePanel>
            <RegionCollapsePanel height={isKpiSettingsExpanded ? 'auto' : '120px'}>
              <Content>
                <Header onClick={() => setKpiSettingExpanded(!isKpiSettingsExpanded)}>
                  <div>
                    <DescriptionTitle active={true} data-test-id={kpiSelectionLabelSelectionContainer}>
                      {t('operator-display:kpiSelection.header')}
                    </DescriptionTitle>
                    <Description data-test-id={descriptionKPISelectionContainer}>{t('operator-display:kpiSelection.description')}</Description>
                  </div>
                  <IconButton
                    icon={isKpiSettingsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    onPress={() => {
                      setKpiSettingExpanded(!isKpiSettingsExpanded);
                    }}
                  />
                </Header>
                {isKpiSettingsExpanded && <KpisSettingsPanel kpis={kpis} onKpiChange={onKpiChange} />}
              </Content>
            </RegionCollapsePanel>
          </CollapsiblePanels>
          <StyledButtonGroup>
            <StyledButton data-test-id={cancelButtonRegionSelectionContainer} mode="ghost" onClick={onClick} label={t('operator-display:sortList.cancel')} />
            <FullStyledButton
              data-test-id={applyChangesButtonRegionSelectionContainer}
              disabled={!active}
              onClick={() => submitChanges()}
              label={t('operator-display:sortList.applyChanges')}
              icon={{
                icon: loading ? <Spin indicator={<Spinner size="16" rotationDuration={1} />} /> : <CheckmarkIcon fill={settings.colors.Monochromatic.White} />,
                position: 'end',
              }}
            />
          </StyledButtonGroup>
        </Container>
      </Banner>
    </Nest>
  );
};

const CollapsiblePanels = styled.div`
  display: flex;
  gap: ${settings.Spacing.Spacing_100};
  margin: ${settings.Spacing.Spacing_200} 0;
  flex-direction: column;
  overflow-y: auto;
  height: 70vh;
`;
const RegionCollapsePanel = styled.div<{ height: string }>`
  display: flex;
  height: ${(props) => props.height};
  border: 1px solid ${settings.colors.Primary.Grey_4};
  padding: ${settings.Spacing.Spacing_200};
`;

const Title = styled.div`
  color: ${settings.colors.Primary.Blue_9};
  font-size: 16px;
  font-family: ${settings.typography.FontFamily.Medium};
  flex: 1;
`;

const StyledButtonGroup = styled.section`
  display: flex;
  justify-content: end;
  min-width: 155px;
  margin: ${settings.Spacing.Spacing_200} 0;

  .btn-background-primary-9-brand.is-disabled {
    --color: ${settings.colors.Primary.Grey_4};

    color: ${settings.colors.Primary.Grey_4};
  }

  .btn-size-small-40 {
    padding: 0 ${settings.Spacing.Spacing_100};

    .icon-end {
      margin: 0 ${settings.Spacing.Spacing_100} 0 ${settings.Spacing.Spacing_100};
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: ${settings.colors.Monochromatic.White};
  color: ${settings.colors.Primary.Blue_9};
  font-family: ${settings.typography.FontFamily.Bold};
  margin-right: ${settings.Spacing.Spacing_100};
  border-radius: 3px;
`;

const FullStyledButton = styled(StyledButton)`
  color: ${settings.colors.Primary.Grey_2};
`;

const HeaderBar = styled.div`
  height: 25px;
  border-bottom: 1px solid ${settings.colors.Primary.Grey_4};
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_200} 0 ${settings.Spacing.Spacing_200};
  padding-bottom: 16px;
`;

const Banner = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 600px;
  height: calc(100vh - 52px - 48px); /* Subtract navbar height, strip height, and allocate extra room for notifiacations */
  display: flex;
  z-index: 1051;
  background: white;
  border: 1px solid ${settings.colors.Primary.Grey_3};
  flex-wrap: nowrap;
  color: ${settings.colors.Primary.Grey_8};
  align-items: flex-start;
  justify-content: center;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  padding: 24px 16px;

  .ant-btn[disabled] {
    cursor: initial;
  }
`;

const Name = styled.div<{ active: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.active ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_8)};
`;

const DescriptionTitle = styled(Name)`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  margin: 10px ${settings.Spacing.Spacing_200} 6px 0;
`;

const Header = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
const Content = styled.div`
  width: 100%;
`;

const Description = styled.div`
  font-size: 12px;
  padding: 0 ${settings.Spacing.Spacing_200} ${settings.Spacing.Spacing_150} 0;
  color: ${settings.colors.Primary.Grey_8};
`;

const Container = styled('div').withConfig<{ loading: boolean }>({
  shouldForwardProp: (prop) => !['loading'].includes(prop),
})`
  pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
  width: 100%;

  .list-sort-demo-wrapper {
    position: relative;
    padding: 16px;
  }

  .list-sort-demo {
    width: 100%;
    cursor: pointer;
    position: relative;
    overflow: auto;
  }

  .list-sort-demo > div {
    overflow: hidden;
  }

  .list-sort-demo-icon {
    width: 20%;
    display: inline-block;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    vertical-align: top;
  }

  .list-sort-demo-text {
    flex: auto;
    padding: ${settings.Spacing.Spacing_150} ${settings.Spacing.Spacing_100};
    display: inline-block;
    color: ${settings.colors.Primary.Blue_9};
    max-width: 85%;
  }
`;

const Nest = styled.div`
  display: flex;
  justify-content: end;
`;

export default DisplaySettingsPanel;
