/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { AXIS_STYLES, TICK_LABEL_PROPS } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { AxisBottom } from '@visx/axis';
import { scaleLinear } from '@visx/scale';
import React, { useCallback, useMemo } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { SAxisLabel } from '../../styles';
import {
  GRAPH_HEIGHT,
  GRAPH_MARGIN_VERTICAL,
  SHAPE_HORIZONTAL_MARGIN,
  SHAPE_VERTICAL_MARGIN,
  TOTAL_GRAPH_HEIGHT,
  VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN,
  VERTICAL_SECTIONS_COUNT,
} from './constants';
import GraphBarCharts from './GraphBarCharts';
import VerticalSections from './VerticalSections';

const BOTTOM_LINE: [number, number][] = [
  [0, 57],
  [23, 50],
  [119, 48],
  [389, 47],
  [556, 38],
  [574, 34],
  [585, 23],
  [652, 16],
  [735, 11],
  [972, 17],
  [1177, 20],
  [1181, 141],
  [714, 146],
  [633, 143],
  [576, 135],
  [575, 124],
  [508, 118],
  [395, 113],
  [307, 112],
  [130, 110],
  [44, 110],
  [23, 108],
  [10, 104],
  [1, 100],
  [0, 73],
];

const SLAG_LINE: [number, number][] = [
  [27, 57],
  [75, 39],
  [95, 29],
  [180, 14],
  [261, 17],
  [282, 16],
  [296, 13],
  [410, 19],
  [496, 13],
  [539, 17],
  [624, 1],
  [788, 5],
  [1046, 9],
  [1177, 11],
  [1187, 37],
  [1188, 61],
  [1186, 145],
  [1170, 149],
  [920, 152],
  [625, 154],
  [553, 139],
  [494, 137],
  [382, 152],
  [131, 145],
  [79, 131],
  [16, 101],
  [20, 82],
];

const MAX_X = (() => {
  let maxX = Number.NEGATIVE_INFINITY;
  for (const point of SLAG_LINE) {
    maxX = Math.max(maxX, point[0]);
  }
  return maxX;
})();

const MAX_Y = (() => {
  let maxY = Number.NEGATIVE_INFINITY;
  for (const point of SLAG_LINE) {
    maxY = Math.max(maxY, point[1]);
  }
  return maxY;
})();

const SectionCut: React.ChildlessComponent = () => {
  const [svgContainerRef, { width }] = useMeasure<SVGSVGElement>();

  const verticalSectionsWidth = width - 2 * VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN;
  const verticalSectionWidth = verticalSectionsWidth / VERTICAL_SECTIONS_COUNT;

  const xAxisScale = scaleLinear({
    domain: [0, 10],
    range: [0, width],
  });

  const xScale = scaleLinear({
    domain: [0, MAX_X],
    range: [SHAPE_HORIZONTAL_MARGIN, SHAPE_HORIZONTAL_MARGIN + width - 2 * SHAPE_HORIZONTAL_MARGIN],
  });

  const yScale = scaleLinear({
    domain: [0, MAX_Y],
    range: [SHAPE_VERTICAL_MARGIN, GRAPH_HEIGHT - SHAPE_VERTICAL_MARGIN],
  });

  const bottomLinePoints = useMemo(() => {
    const scaledPoints = BOTTOM_LINE.map((point) => `${xScale(point[0])},${yScale(point[1])}`);
    const polygonString = scaledPoints.join(' ');
    return polygonString;
  }, [xScale, yScale]);

  const slagLinePoints = useMemo(() => {
    const scaledPoints = SLAG_LINE.map((point) => `${xScale(point[0])},${yScale(point[1])}`);
    const polygonString = scaledPoints.join(' ');
    return polygonString;
  }, [xScale, yScale]);

  const ticks = useCallback(
    (position: 'top' | 'bottom') => {
      const ret = [];
      ret.push(
        <text
          key={`thickness-${ret.length}`}
          x={VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN}
          y={position === 'top' ? 0 : TOTAL_GRAPH_HEIGHT}
          textAnchor="end"
          dominantBaseline={position === 'top' ? 'hanging' : 'bottom'}
          fontFamily={settings.typography.FontFamily.Regular}
          fontSize={settings.typography.FontSize.X_Small}
          fill={settings.colors.Primary.Grey_6}
        >
          Thickness [mm]
        </text>
      );
      for (let index = 0; index < VERTICAL_SECTIONS_COUNT; index++) {
        const centerX = VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN + index * verticalSectionWidth + verticalSectionWidth / 2;
        ret.push(
          <React.Fragment key={`vertical-${index}`}>
            <text
              x={centerX}
              y={position === 'top' ? 0 : TOTAL_GRAPH_HEIGHT}
              textAnchor="middle"
              dominantBaseline={position === 'top' ? 'hanging' : 'bottom'}
              fontFamily={settings.typography.FontFamily.Regular}
              fontSize={settings.typography.FontSize.X_Small}
              fill={settings.colors.Primary.Grey_6}
            >
              324
            </text>
            <circle cx={centerX} cy={position === 'top' ? 20 : TOTAL_GRAPH_HEIGHT - 20} r="3" fill={settings.colors.Primary.Grey_3} />
            <line
              x1={centerX}
              y1={position === 'top' ? 20 : TOTAL_GRAPH_HEIGHT - 20}
              x2={centerX}
              y2={position === 'top' ? 40 : TOTAL_GRAPH_HEIGHT - 40}
              stroke={settings.colors.Primary.Grey_3}
              strokeWidth={2}
            />
          </React.Fragment>
        );
      }
      return ret;
    },
    [verticalSectionWidth]
  );

  return (
    <SWrapper>
      <SGraphBarCharts verticalSectionWidth={verticalSectionWidth} />
      <SSvg ref={svgContainerRef} width="100%" height={TOTAL_GRAPH_HEIGHT}>
        {ticks('top')}
        {ticks('bottom')}
        <g id="inner-graph" transform={`translate(0 ${GRAPH_MARGIN_VERTICAL})`}>
          <rect id="graphBorder" x={0} y={0} width="100%" height={GRAPH_HEIGHT} stroke={settings.colors.Primary.Grey_4} fill="none" />
          <VerticalSections verticalSectionWidth={verticalSectionWidth} />
          <polygon points={slagLinePoints} fill={settings.colors.Primary.Grey_2} />
          <polygon
            points={bottomLinePoints}
            fill={settings.colors.Monochromatic.White}
            stroke={settings.colors.Primary.Grey_6}
            strokeWidth={2}
            strokeDasharray="4"
          />
          <polygon points={slagLinePoints} fill="none" stroke={settings.colors.Univariate.Purple_Deep} strokeWidth={2} />
          <text
            x={width / 2}
            y={GRAPH_HEIGHT / 2}
            textAnchor="middle"
            alignmentBaseline="middle"
            fontFamily={settings.typography.FontFamily.Bold}
            fontSize={settings.typography.FontSize.X_Large}
            fill={settings.colors.Miscellaneous.Purple_Deep}
          >
            Slag Line at height 750mm
          </text>
          <text
            x={4}
            y={12}
            textAnchor="left"
            alignmentBaseline="middle"
            fontFamily={settings.typography.FontFamily.Regular}
            fontSize={settings.typography.FontSize.X_Small}
            fill={settings.colors.Primary.Grey_6}
          >
            Right
          </text>
          <line
            x1={0}
            y1={SHAPE_VERTICAL_MARGIN}
            x2={width}
            y2={SHAPE_VERTICAL_MARGIN}
            stroke={settings.colors.Operational.State_Alert_Red_2}
            strokeDasharray="2"
          />
          <text
            x={4}
            y={GRAPH_HEIGHT - 8}
            textAnchor="left"
            alignmentBaseline="middle"
            fontFamily={settings.typography.FontFamily.Regular}
            fontSize={settings.typography.FontSize.X_Small}
            fill={settings.colors.Primary.Grey_6}
          >
            Left
          </text>
          <line
            x1={0}
            y1={GRAPH_HEIGHT - SHAPE_VERTICAL_MARGIN}
            x2={width}
            y2={GRAPH_HEIGHT - SHAPE_VERTICAL_MARGIN}
            stroke={settings.colors.Operational.State_Alert_Red_2}
            strokeDasharray="2"
          />
        </g>
      </SSvg>
      <SSvgXAxis width="100%" height={18}>
        <AxisBottom top={0} scale={xAxisScale} tickValues={[0, 2, 4, 6, 8, 10]} {...AXIS_STYLES} {...TICK_LABEL_PROPS} />
      </SSvgXAxis>
      <SAxisLabel mode="xAxis">Length [m]→</SAxisLabel>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSvg = styled.svg`
  overflow: visible;
  shape-rendering: geometricprecision;
`;

const SSvgXAxis = styled(SSvg)`
  margin-top: ${settings.Spacing.Spacing_300};
`;

const SGraphBarCharts = styled(GraphBarCharts)`
  margin-bottom: ${settings.Spacing.Spacing_300};
`;

SectionCut.whyDidYouRender = true;
export default React.memo(SectionCut);
