/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DataPoint, DiamondPoint } from '@rhim/chart';
import { settings } from '@rhim/design';
import { HeatIcon } from '@rhim/icons/16';
import { RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto, RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel } from '@rhim/rest/operatorDisplay';
import { RHIMContractsMaintenanceTaskSeverity, RHIMContractsThresholdType } from '@rhim/rest/wearManagement';
import {
  currentHeatMainGraphLabelOperatorDisplay,
  graphCriticalAreaContainerOperatorDisplay,
  zoomedGraphCriticalAreaContainerOperatorDisplay,
  zoomedGraphOperatorDisplay,
} from '@rhim/test-ids';
import { ensure, hasElements, isDefined, last } from '@rhim/utils';
import { curveBasis } from '@visx/curve';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { Threshold } from '@visx/threshold';
import { AxisBottom } from '@vx/axis';
import { scaleLinear } from '@vx/scale';
import { Line } from '@vx/shape';
import { defaultStyles, Tooltip, withTooltip } from '@vx/tooltip';
import { max } from 'd3-array';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  calculateCurvePoints,
  getCircleColors,
  getTargetPoint,
  isCurrentMinAboveUpperThreshold,
  isCurrentMinCritical,
  isCurrentMinGreen,
  isCurrentMinOrange,
  isCurrentMinYellow,
} from './dataProcessor';

export interface WmsGraphProps {
  data: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel;
  width: number;
  height: number;
  zoomed?: boolean;
  targetLifeTime: number;
  lastHeat: number;
  hasZoom?: boolean;
  lastTotalHeat?: number;
  comparedData?: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel;
  compareMode?: boolean;
}

const defaultTooltipStyle: React.CSSProperties = {
  ...defaultStyles,
  padding: '2px 2px',
  height: '16px',
  borderRadius: '3px',
  textAlign: 'center',
  fontFamily: `${settings.typography.FontFamily.Bold}`,
  fontSize: `${settings.typography.FontSize.X_Small}`,
  lineHeight: '0.88',
  transform: 'translateX(-50%)',
  boxShadow: 'none',
};

/**
 * WMS graph drawing component.
 */
const WmsGraph = withTooltip(
  ({ comparedData, compareMode, hasZoom = false, data, width, height, zoomed = false, lastTotalHeat, targetLifeTime, lastHeat }: WmsGraphProps) => {
    const { measuredPoints } = data;
    const comparedMeasuredPoints = isDefined(comparedData) ? comparedData.measuredPoints : undefined;
    // bounds
    const xMax = width;
    const yMax = height;
    const MAXHEAT = targetLifeTime < lastHeat ? lastHeat : targetLifeTime;

    const criticalLiningThickness = data.criticalLiningThickness ?? 0;
    const showCriticalLiningThickness = isDefined(data.criticalLiningThickness);

    /**
     * Gets the lining from a point.
     */
    const getLining = useCallback((d: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto) => {
      return d.liningThickness;
    }, []);

    /**
     * Gets the heat from a point.
     */
    const getHeat = useCallback((d: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto) => {
      return d.heat;
    }, []);
    // bounds

    const maxLining = isDefined(comparedMeasuredPoints)
      ? Math.max(...[...measuredPoints.map(getLining), ...comparedMeasuredPoints.map(getLining)])
      : Math.max(...measuredPoints.map(getLining));
    const liningThickness = maxLining > data.initialLiningThickness ? maxLining : data.initialLiningThickness;

    /**
     * Last measured point.
     */
    const lastMeasuredPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto = useMemo(() => {
      return measuredPoints.length
        ? last(measuredPoints as NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto>)
        : { heat: lastHeat, liningThickness: liningThickness };
    }, [measuredPoints, lastHeat, liningThickness]);
    /**
     * Zoom dimensions.
     */
    const zoom = useMemo(() => {
      const heat = lastMeasuredPoint.heat;
      let left = heat - 100 < 0 ? heat : 100;
      let right = heat + 100 > MAXHEAT ? MAXHEAT - heat : 100;
      if (left < 100) {
        right = 200 - left;
      }

      if (right < 100) {
        left = 200 - right;
      }

      return { left, right };
    }, [lastMeasuredPoint, MAXHEAT]);

    /**
     * Periferic points used to defined the zoomed area.
     */
    const perifericPoints = useMemo((): [number, number] => {
      const heat = lastMeasuredPoint.heat;
      return [heat - zoom.left, heat + zoom.right];
    }, [lastMeasuredPoint, zoom]);

    const getThresholdCurveByType = (type: RHIMContractsThresholdType, severity: RHIMContractsMaintenanceTaskSeverity) => {
      const thresholdCurve = data.thresholdCurves.find((curve) => curve.wearCurveType === type && severity === curve.severity);
      return isDefined(thresholdCurve) ? calculateCurvePoints(targetLifeTime, measuredPoints, thresholdCurve, zoomed ? perifericPoints : undefined) : undefined;
    };

    /**
     * Upper threshold curve points.
     */
    const upperThresholdCurve = getThresholdCurveByType(RHIMContractsThresholdType.Upper, RHIMContractsMaintenanceTaskSeverity.Low);

    /**
     * Target curve points.
     */
    const targetCurvePoints = useMemo(
      () =>
        isDefined(data.targetLiningThicknessCurve)
          ? calculateCurvePoints(targetLifeTime, measuredPoints, data.targetLiningThicknessCurve, zoomed ? perifericPoints : undefined)
          : ([] as RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto[]),
      [data.targetLiningThicknessCurve, measuredPoints, targetLifeTime, zoomed, perifericPoints]
    );

    /**
     * First Threshold curve
     */
    const firstThresholdCurve = getThresholdCurveByType(RHIMContractsThresholdType.Lower, RHIMContractsMaintenanceTaskSeverity.Low);

    /**
     * Second threshold curve.
     */
    const secondThresholdCurve = getThresholdCurveByType(RHIMContractsThresholdType.Lower, RHIMContractsMaintenanceTaskSeverity.Medium);

    /**
     * Intersection Point between upper threshold curve and last measurement point.
     */
    const upperThresholdAtCurrentHeat = useMemo(
      () =>
        isDefined(upperThresholdCurve)
          ? upperThresholdCurve.find((point) => point.heat === getHeat(lastMeasuredPoint)) || { liningThickness: 0, heat: 0 }
          : undefined,
      [upperThresholdCurve, getHeat, lastMeasuredPoint]
    );

    /**
     * X axis domain.
     */
    const xDomain = useMemo(() => {
      if (zoomed) {
        return [perifericPoints[0], perifericPoints[1]];
      }

      return [0, MAXHEAT];
    }, [zoomed, perifericPoints, MAXHEAT]);

    const yDomain = useMemo(() => {
      if (zoomed && isDefined(upperThresholdAtCurrentHeat) && isCurrentMinAboveUpperThreshold(upperThresholdAtCurrentHeat, lastMeasuredPoint)) {
        return [0, lastMeasuredPoint.liningThickness];
      }
      const upperLining = isDefined(upperThresholdCurve) ? upperThresholdCurve[0].liningThickness : liningThickness;
      const firstLowerLining = isDefined(firstThresholdCurve) ? firstThresholdCurve[0].liningThickness : liningThickness;
      const secondLowerLining = isDefined(secondThresholdCurve) ? secondThresholdCurve[0].liningThickness : liningThickness;

      const maxCurvesLining = Math.max(...[upperLining, firstLowerLining, secondLowerLining]);
      return [0, maxCurvesLining];
    }, [lastMeasuredPoint, zoomed, upperThresholdAtCurrentHeat, upperThresholdCurve, liningThickness, firstThresholdCurve, secondThresholdCurve]);
    /**
     * X axis scale function.
     */
    const xScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [0, xMax],
          round: true,
          domain: xDomain,
        }),
      [xMax, xDomain]
    );

    /**
     * Y axis scale function.
     */
    const yScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax, 0],
          domain: yDomain,
        }),
      [yDomain, yMax]
    );

    /**
     * Axis Bottom Props.
     */
    const axisBottomProps = useMemo(() => {
      const scale = scaleLinear({
        range: [0, xMax],
        domain: [0, targetLifeTime],
      });

      /**
       * The `f` is used to remove decimal separators used by default.
       */
      const tickFormatter = scale.tickFormat(0, 'f');

      return { scale, tickFormatter };
    }, [xMax, targetLifeTime]);

    /**
     * Draws vertical lines for the zoomed area.
     */
    const drawVerticalLines = () => {
      let heat = perifericPoints[0] || 0;
      const lines = [];

      while (heat <= perifericPoints[1]) {
        lines.push(
          <Line
            key={heat}
            from={{
              x: xScale(heat),
              y: yScale(max(yDomain)!),
            }}
            to={{ x: xScale(heat), y: yScale(0) ?? 0 }}
            stroke={settings.colors.Primary.Grey_2}
            strokeWidth={1}
            strokeDasharray={(lines.length + 1) % 2 ? 'none' : 2}
            pointerEvents="none"
          />
        );

        heat += 10;
      }

      return lines;
    };

    const getPointAtHeat = (heat: number, points: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto[]) => {
      /**
       * Returns a point at a given heat.
       */
      return points.find((point) => point.heat === heat) || { liningThickness: 0, heat: 0 };
    };
    /**
     * Intersection Point between first threshold curve and last measurement point.
     */
    const firstThresholdAtCurrentHeat = useMemo(
      () =>
        isDefined(firstThresholdCurve)
          ? firstThresholdCurve.find((point) => point.heat === getHeat(lastMeasuredPoint)) || { liningThickness: 0, heat: 0 }
          : undefined,
      [firstThresholdCurve, getHeat, lastMeasuredPoint]
    );

    /**
     * Intersection Point between second threshold curve and last measurement point.
     */
    const secondThresholdAtCurrentHeat = useMemo(
      () =>
        isDefined(secondThresholdCurve)
          ? secondThresholdCurve.find((point) => point.heat === getHeat(lastMeasuredPoint)) || { liningThickness: 0, heat: 0 }
          : undefined,
      [secondThresholdCurve, getHeat, lastMeasuredPoint]
    );

    /**
     * Intersection Point between target curve and last measurement point.
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,@typescript-eslint/strict-boolean-expressions
    const targetPoint = getTargetPoint(targetCurvePoints, lastMeasuredPoint);

    /**
     * Returns the colored blocks.
     */
    const getColoredAreas = React.useCallback(() => {
      return compareMode !== true ? (
        <>
          {/*Upper Area*/}
          {measuredPoints.length &&
            isDefined(upperThresholdAtCurrentHeat) &&
            isDefined(upperThresholdCurve) &&
            isCurrentMinAboveUpperThreshold(upperThresholdAtCurrentHeat, lastMeasuredPoint) && (
              <Threshold
                id={`${Math.random()}`}
                data={upperThresholdCurve}
                x={(d) => Number(xScale(getHeat(d)))}
                y0={(d) => yScale(getLining(d)) ?? 0}
                y1={() => yScale(max(yDomain)!) ?? 0}
                clipAboveTo={0}
                clipBelowTo={yMax}
                curve={curveBasis}
                aboveAreaProps={{
                  fill: settings.colors.Operational.State_Alert_Yellow_1,
                }}
                belowAreaProps={{
                  fill: settings.colors.Operational.State_Alert_Yellow_1,
                }}
              />
            )}
          {hasElements(targetCurvePoints) &&
            isCurrentMinGreen(targetPoint, firstThresholdAtCurrentHeat || secondThresholdAtCurrentHeat || criticalLiningThickness, lastMeasuredPoint) && (
              <Threshold
                id={`${Math.random()}`}
                data={targetCurvePoints}
                x={(d) => Number(xScale(getHeat(d)))}
                y0={(d) => ensure(yScale(getLining(d)))}
                y1={(d) => {
                  const curve = firstThresholdCurve ?? secondThresholdCurve;
                  const value = isDefined(curve) ? getLining(getPointAtHeat(d.heat, curve)) : criticalLiningThickness;

                  return yScale(value) ?? 0;
                }}
                clipAboveTo={0}
                clipBelowTo={yMax}
                curve={curveBasis}
                belowAreaProps={{
                  fill: settings.colors.Operational.State_Green_1,
                }}
                aboveAreaProps={{
                  fill: settings.colors.Operational.State_Green_1,
                }}
              />
            )}

          {/*Yellow Area*/}
          {isDefined(firstThresholdAtCurrentHeat) &&
            isDefined(firstThresholdCurve) &&
            isCurrentMinYellow(firstThresholdAtCurrentHeat, secondThresholdAtCurrentHeat || criticalLiningThickness, lastMeasuredPoint) && (
              <Threshold
                id={`${Math.random()}`}
                data={firstThresholdCurve}
                x={(d) => Number(xScale(getHeat(d)))}
                y0={(d) => yScale(getLining(d)) ?? 0}
                y1={(d) => yScale(isDefined(secondThresholdCurve) ? getLining(getPointAtHeat(d.heat, secondThresholdCurve)) : criticalLiningThickness) ?? 0}
                clipAboveTo={0}
                clipBelowTo={yMax}
                curve={curveBasis}
                aboveAreaProps={{
                  fill: settings.colors.Operational.State_Alert_Yellow_1,
                }}
                belowAreaProps={{
                  fill: settings.colors.Operational.State_Alert_Yellow_1,
                }}
              />
            )}

          {/*Orange area*/}
          {isDefined(secondThresholdCurve) &&
            isDefined(secondThresholdAtCurrentHeat) &&
            isCurrentMinOrange(secondThresholdAtCurrentHeat, criticalLiningThickness, lastMeasuredPoint) && (
              <Threshold
                id={`${Math.random()}`}
                data={secondThresholdCurve}
                x={(d) => Number(xScale(getHeat(d)))}
                y0={(d) => yScale(getLining(d)) ?? 0}
                y1={yScale(criticalLiningThickness) ?? 0}
                clipAboveTo={0}
                clipBelowTo={yMax}
                curve={curveBasis}
                aboveAreaProps={{
                  fill: settings.colors.Operational.State_Alert_Orange_1,
                }}
                belowAreaProps={{
                  fill: settings.colors.Operational.State_Alert_Orange_1,
                }}
              />
            )}
        </>
      ) : null;
    }, [
      compareMode,
      yMax,
      secondThresholdCurve,
      lastMeasuredPoint,
      firstThresholdCurve,
      firstThresholdAtCurrentHeat,
      targetPoint,
      getHeat,
      getLining,
      measuredPoints.length,
      targetCurvePoints,
      upperThresholdAtCurrentHeat,
      upperThresholdCurve,
      xScale,
      yDomain,
      yScale,
      secondThresholdAtCurrentHeat,
      criticalLiningThickness,
    ]);

    //Graphs drawing.

    const getHeatTooltipMessage = () => {
      if (isDefined(lastTotalHeat)) {
        return (
          <Message>
            <HeatIcon size="12" className="heat-icon" fill={settings.colors.Monochromatic.White} />
            {`${getHeat(lastMeasuredPoint)},`}&nbsp;<span>{`#${lastTotalHeat}`}</span>
          </Message>
        );
      }
      return getHeat(lastMeasuredPoint);
    };

    /**
     * Wms Graph.
     **/
    const getGraph = () => (
      <div>
        <svg width={width + (hasZoom ? 100 : 40)} height={height + 35} preserveAspectRatio="xMinYMin meet">
          <rect x={0} y={0} width={width + 100} height={height} fill="transparent" />
          <Group top={20} transform="translate(5, 8)">
            <rect width={xScale(getHeat(lastMeasuredPoint))} height={height} x={0} y={0} fill={settings.colors.Primary.Grey_1} />
            <rect
              width={(xScale(MAXHEAT) ?? 0) - (xScale(getHeat(lastMeasuredPoint)) ?? 0)}
              height={height}
              x={xScale(getHeat(lastMeasuredPoint))}
              y={0}
              fill={settings.colors.Primary.Blue_1}
            />
            <rect
              width={xScale(MAXHEAT) ?? 0}
              height={height - (yScale(criticalLiningThickness) ?? 0)}
              x={0}
              y={yScale(criticalLiningThickness)}
              fill={settings.colors.Primary.Grey_2}
            />

            {/*Colored Areas*/}
            {getColoredAreas()}

            {hasZoom && (
              <Line
                from={{
                  x: xScale(getHeat(lastMeasuredPoint) - zoom.left),
                  y: yScale(max(yDomain)!),
                }}
                to={{ x: xScale(getHeat(lastMeasuredPoint) - zoom.left), y: yScale(0) }}
                stroke={settings.colors.Primary.Grey_3}
                strokeWidth={1}
                pointerEvents="none"
              />
            )}
            <AxisBottom
              top={yMax}
              scale={axisBottomProps.scale}
              stroke={settings.colors.Primary.Grey_1}
              tickLength={4}
              tickStroke={settings.colors.Primary.Grey_4}
              tickValues={[0]}
            />
            {hasZoom && (
              <g>
                <Line
                  from={{
                    x: xScale(getHeat(lastMeasuredPoint) + zoom.right),
                    y: yScale(max(yDomain)!),
                  }}
                  to={{ x: xScale(getHeat(lastMeasuredPoint) + zoom.right), y: yScale(0) }}
                  stroke={settings.colors.Primary.Grey_3}
                  strokeWidth={1}
                  pointerEvents="none"
                />
                <Line
                  from={{ x: xScale(getHeat(lastMeasuredPoint) + zoom.right), y: yScale(0) }}
                  to={{ x: xScale(getHeat(lastMeasuredPoint) - zoom.left), y: yScale(0) }}
                  stroke={settings.colors.Primary.Grey_3}
                  strokeWidth={1}
                  pointerEvents="none"
                />
                <Line
                  from={{
                    x: xScale(getHeat(lastMeasuredPoint) + zoom.right),
                    y: yScale(max(yDomain)!),
                  }}
                  to={{
                    x: xScale(getHeat(lastMeasuredPoint) - zoom.left),
                    y: yScale(max(yDomain)!),
                  }}
                  stroke={settings.colors.Primary.Grey_3}
                  strokeWidth={1}
                  pointerEvents="none"
                />
              </g>
            )}
            {isDefined(upperThresholdAtCurrentHeat) && (
              <LinePath
                data={upperThresholdCurve}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Alert_Yellow_3}
                strokeWidth={1}
                strokeDasharray={isCurrentMinAboveUpperThreshold(upperThresholdAtCurrentHeat, lastMeasuredPoint) ? 'none' : 2}
              />
            )}

            {hasElements(targetCurvePoints) && (
              <LinePath
                data={targetCurvePoints}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Green_3}
                strokeWidth={1}
              />
            )}
            {isDefined(firstThresholdAtCurrentHeat) && (
              <LinePath
                data={firstThresholdCurve}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Alert_Yellow_3}
                strokeWidth={1}
                strokeDasharray={
                  isCurrentMinYellow(firstThresholdAtCurrentHeat, secondThresholdAtCurrentHeat || criticalLiningThickness, lastMeasuredPoint) ? 'none' : 2
                }
              />
            )}
            {isDefined(secondThresholdCurve) && isDefined(secondThresholdAtCurrentHeat) && (
              <LinePath
                data={secondThresholdCurve}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Alert_Orange_3}
                strokeWidth={1}
                strokeDasharray={isCurrentMinOrange(secondThresholdAtCurrentHeat, criticalLiningThickness, lastMeasuredPoint) ? 'none' : 2}
              />
            )}
            {isCurrentMinCritical(criticalLiningThickness, lastMeasuredPoint) && compareMode !== true && (
              <rect
                data-test-id={graphCriticalAreaContainerOperatorDisplay}
                width={xScale(MAXHEAT)}
                height={ensure(yScale(0)) - ensure(yScale(criticalLiningThickness))}
                x={0}
                y={yScale(ensure(criticalLiningThickness))}
                fill={settings.colors.Operational.State_Alert_Red_3}
                fillOpacity={0.8}
              />
            )}
            {showCriticalLiningThickness && (
              <Line
                from={{
                  x: -2,
                  y: yScale(criticalLiningThickness),
                }}
                to={{ x: xScale(MAXHEAT), y: yScale(criticalLiningThickness) }}
                stroke={settings.colors.Operational.State_Alert_R}
                strokeWidth={1}
                pointerEvents="none"
              />
            )}
            <Line
              from={{
                x: xScale(getHeat(lastMeasuredPoint)),
                y: yScale(max(yDomain)!),
              }}
              to={{ x: xScale(getHeat(lastMeasuredPoint)), y: (yScale(0) ?? 0) + 5 }}
              stroke={settings.colors.Primary.Blue_8}
              strokeWidth={2}
              pointerEvents="none"
            />
            {(comparedMeasuredPoints || []).map((point: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto, i) => {
              return (
                <g key={`point-${point.heat}-${i}`}>
                  <DiamondPoint
                    className="dot"
                    x={xScale(getHeat(point) - 5)!}
                    y={yScale(getLining(point) + 10)!}
                    width={5}
                    height={5}
                    fill={settings.colors.Primary.Grey_4}
                  />
                </g>
              );
            })}
            {measuredPoints.map((point: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto, i) => {
              const lastEntry = i === measuredPoints.length - 1;
              return (
                <g key={`point-${point.heat}-${i}`}>
                  <DataPoint
                    className="dot"
                    cx={xScale(getHeat(point))!}
                    cy={yScale(getLining(point))!}
                    r={lastEntry ? 3 : 2}
                    stroke={settings.colors.Primary.Grey_8}
                    fill={settings.colors.Primary.Grey_8}
                  />
                </g>
              );
            })}
          </Group>
        </svg>

        {showCriticalLiningThickness && (
          <Tooltip // Mid-Bottom tooltip: Last measurement point.
            top={(yScale(criticalLiningThickness) ?? 0) - 10}
            left={-20}
            style={{
              ...defaultTooltipStyle,
              color: settings.colors.Operational.State_Alert_Red_3,
              backgroundColor: 'transparent',
            }}
          >
            {Math.round(criticalLiningThickness)}
          </Tooltip>
        )}

        <Tooltip // y-axis : initial lining thickness
          top={(yScale(data.initialLiningThickness) ?? 0) - 10}
          left={-20}
          style={{
            ...defaultTooltipStyle,
            color: settings.colors.Primary.Grey_8,
            backgroundColor: 'transparent',
            fontFamily: settings.typography.FontFamily.Regular,
          }}
        >
          {Math.round(data.initialLiningThickness)}
        </Tooltip>
        <Tooltip // Mid-Bottom tooltip: Last measurement point.
          top={yMax + 3}
          left={ensure(xScale(MAXHEAT)) - 10}
          style={{
            ...defaultTooltipStyle,
            color: settings.colors.Monochromatic.Black,
            backgroundColor: settings.colors.Operational.State_Alert_Red_1,
          }}
        >
          {MAXHEAT}
        </Tooltip>

        <Tooltip // Mid-Bottom tooltip: Current Heat.
          data-test-id={currentHeatMainGraphLabelOperatorDisplay}
          top={yMax + 3}
          left={ensure(xScale(getHeat(lastMeasuredPoint))) - 5 || 0}
          style={{
            ...defaultTooltipStyle,
            height: isDefined(lastTotalHeat) ? 'auto' : defaultTooltipStyle.height,

            color: settings.colors.Monochromatic.White,
            backgroundColor: settings.colors.Primary.Grey_8,
          }}
        >
          {getHeatTooltipMessage()}
        </Tooltip>
      </div>
    );

    /**
     * Zoomed graph.
     **/
    const getZoomedGraph = () => (
      <ZoomedContainer data-test-id={zoomedGraphOperatorDisplay}>
        <svg width={width + 40} height={height + 15} preserveAspectRatio="xMinYMin meet">
          <rect x={15} y={0} width={width} height={height} fill="transparent" />
          <Group transform="translate(15, 8)">
            <rect
              width={(xScale(perifericPoints[1]) ?? 0) - (xScale(getHeat(lastMeasuredPoint)) ?? 0)}
              height={height}
              x={xScale(getHeat(lastMeasuredPoint))}
              y={0}
              fill={settings.colors.Primary.Blue_1}
            />
            <rect
              width={width}
              height={height - (yScale(criticalLiningThickness) ?? 0)}
              x={0}
              y={yScale(criticalLiningThickness)}
              fill={settings.colors.Primary.Grey_3}
            />
            {/*Vertical lines*/}
            {drawVerticalLines()}

            {/*Colored Areas*/}
            {getColoredAreas()}

            {isDefined(upperThresholdAtCurrentHeat) && (
              <LinePath
                data={upperThresholdCurve}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Alert_Yellow_3}
                strokeWidth={1}
                strokeDasharray={isCurrentMinAboveUpperThreshold(upperThresholdAtCurrentHeat, lastMeasuredPoint) ? 'none' : 2}
              />
            )}
            {hasElements(targetCurvePoints) && !isNaN(getLining(targetPoint)) && (
              <LinePath
                data={targetCurvePoints}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Green_3}
                strokeWidth={1}
              />
            )}
            {isDefined(firstThresholdAtCurrentHeat) && (
              <LinePath
                data={firstThresholdCurve}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Alert_Yellow_3}
                strokeWidth={1}
                strokeDasharray={
                  isCurrentMinYellow(firstThresholdAtCurrentHeat, secondThresholdAtCurrentHeat || criticalLiningThickness, lastMeasuredPoint) ? 'none' : 2
                }
              />
            )}
            {isDefined(secondThresholdAtCurrentHeat) && (
              <LinePath
                data={secondThresholdCurve}
                curve={curveBasis}
                x={(d) => xScale(getHeat(d)) ?? 0}
                y={(d) => yScale(getLining(d)) ?? 0}
                stroke={settings.colors.Operational.State_Alert_Orange_3}
                strokeWidth={1}
                strokeDasharray={isCurrentMinOrange(secondThresholdAtCurrentHeat, criticalLiningThickness, lastMeasuredPoint) ? 'none' : 2}
              />
            )}
            {measuredPoints.length > 0 && (
              <Line
                from={{
                  x: ensure(xScale(ensure(perifericPoints[0]))) - 10,
                  y: yScale(getLining(lastMeasuredPoint)),
                }}
                to={{ x: xScale(getHeat(lastMeasuredPoint)), y: yScale(getLining(lastMeasuredPoint)) }}
                stroke={settings.colors.Primary.Grey_11}
                strokeWidth={1}
                pointerEvents="none"
              />
            )}
            {isCurrentMinCritical(criticalLiningThickness, lastMeasuredPoint) && (
              <rect
                data-test-id={zoomedGraphCriticalAreaContainerOperatorDisplay}
                width={xScale(perifericPoints[1])}
                height={ensure(yScale(0)) - ensure(yScale(criticalLiningThickness))}
                x={0}
                y={yScale(ensure(criticalLiningThickness))}
                fill={settings.colors.Operational.State_Alert_Red_3}
                fillOpacity={0.8}
              />
            )}
            {showCriticalLiningThickness && (
              <Line
                from={{
                  x: ensure(xScale(ensure(perifericPoints[0]))) - 2,
                  y: yScale(criticalLiningThickness),
                }}
                to={{ x: xScale(perifericPoints[1]), y: yScale(criticalLiningThickness) }}
                stroke={settings.colors.Operational.State_Alert_R}
                strokeWidth={1}
                pointerEvents="none"
              />
            )}
            {hasElements(targetCurvePoints) && !isNaN(getLining(targetPoint)) && (
              <Line
                from={{
                  x: xScale(perifericPoints[0] - 5),
                  y: yScale(getLining(targetPoint)),
                }}
                to={{ x: xScale(getHeat(lastMeasuredPoint)), y: yScale(getLining(targetPoint)) }}
                stroke={settings.colors.Operational.State_Green_3}
                strokeWidth={2}
                strokeDasharray={2}
                pointerEvents="none"
              />
            )}
            <Line
              from={{
                x: xScale(getHeat(lastMeasuredPoint)),
                y: yScale(max(yDomain)!),
              }}
              to={{ x: xScale(getHeat(lastMeasuredPoint)), y: yScale(0) ?? 0 }}
              stroke={
                isCurrentMinCritical(criticalLiningThickness, lastMeasuredPoint)
                  ? settings.colors.Operational.State_Alert_Red_4
                  : settings.colors.Primary.Grey_5
              }
              strokeWidth={2}
              pointerEvents="none"
            />
            {(comparedMeasuredPoints || [])
              .filter((item) => item.heat >= perifericPoints[0] && item.heat <= perifericPoints[1])
              .map((point: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto, i) => {
                return (
                  <g key={`point-${point.heat}-${i}`}>
                    <DiamondPoint
                      className="dot"
                      x={xScale(getHeat(point) - 3)!}
                      y={yScale(getLining(point) + 10)!}
                      width={8}
                      height={8}
                      fill={settings.colors.Primary.Grey_4}
                    />
                  </g>
                );
              })}
            {measuredPoints
              .filter((item) => item.heat >= perifericPoints[0] && item.heat <= perifericPoints[1])
              .map((point: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto, i) => {
                const style = getCircleColors(point, data, lastMeasuredPoint);

                return (
                  <g key={`point-${point.heat}-${i}`} className="circle">
                    <DataPoint
                      className="dot"
                      cx={xScale(getHeat(point))!}
                      cy={yScale(getLining(point))!}
                      stroke={style.border}
                      strokeWidth={2}
                      r={3}
                      fill={style.background}
                    />
                  </g>
                );
              })}
          </Group>
        </svg>

        {showCriticalLiningThickness && (
          <Tooltip // Mid-Bottom tooltip: Last measurement point.
            top={(yScale(criticalLiningThickness) ?? 0) + 10 || +20}
            left={7}
            style={{
              ...defaultTooltipStyle,
              fontFamily: settings.typography.FontFamily.Regular,
              color: settings.colors.Operational.State_Alert_Red_3,
              backgroundColor: 'transparent',
            }}
          >
            {Math.round(criticalLiningThickness)}
          </Tooltip>
        )}
        <Tooltip // Mid-Bottom tooltip: Last measurement point.
          top={10}
          left={0}
          style={{
            ...defaultTooltipStyle,
            color: settings.colors.Primary.Grey_8,
            backgroundColor: 'transparent',
            fontFamily: settings.typography.FontFamily.Regular,
          }}
        >
          {Math.round(max(yDomain)!)}
        </Tooltip>

        {hasElements(targetCurvePoints) && !isNaN(getLining(targetPoint)) && (
          <Tooltip // Target Intersection green point.
            top={
              Math.abs(getLining(lastMeasuredPoint) - getLining(targetPoint)) < 10
                ? (yScale(getLining(targetPoint)) ?? 0) + 5
                : (yScale(getLining(targetPoint)) ?? 0) + 10
            }
            left={2}
            style={{
              ...defaultTooltipStyle,
              fontFamily: settings.typography.FontFamily.Regular,
              color: settings.colors.Operational.State_Green_4,
              backgroundColor: settings.colors.Operational.State_Green_1,
            }}
          >
            {Math.round(getLining(targetPoint))}
          </Tooltip>
        )}
        <Tooltip // Mid-Bottom tooltip: Last measurement point.
          top={yMax + 20}
          left={23}
          style={{
            ...defaultTooltipStyle,
            color: settings.colors.Primary.Grey_6,
            backgroundColor: 'transparent',
            fontFamily: settings.typography.FontFamily.Regular,
          }}
        >
          {perifericPoints[0]}
        </Tooltip>
        <Tooltip // Mid-Bottom tooltip: Last measurement point.
          top={yMax + 20}
          left={(xScale(perifericPoints[1]) ?? 0) + 20}
          style={{
            ...defaultTooltipStyle,
            color: settings.colors.Primary.Grey_6,
            backgroundColor: 'transparent',
            fontFamily: settings.typography.FontFamily.Regular,
          }}
        >
          {perifericPoints[1]}
        </Tooltip>

        {measuredPoints.length > 0 && (
          <Tooltip // Mid-Bottom tooltip: Last measurement point.
            top={
              Math.abs(getLining(lastMeasuredPoint) - getLining(targetPoint)) < 10
                ? ensure(yScale(getLining(lastMeasuredPoint))) + 5
                : ensure(yScale(getLining(lastMeasuredPoint))) + 10
            }
            left={2}
            style={{
              ...defaultTooltipStyle,
              color: settings.colors.Monochromatic.White,
              backgroundColor: isCurrentMinCritical(criticalLiningThickness, lastMeasuredPoint)
                ? settings.colors.Operational.State_Alert_Red_4
                : settings.colors.Primary.Grey_8,
              fontFamily: settings.typography.FontFamily.Bold,
              fontSize: settings.typography.FontSize.Small,
            }}
          >
            {Math.round(getLining(lastMeasuredPoint))}
          </Tooltip>
        )}

        <Tooltip // Mid-Bottom tooltip: Last measurement point.
          top={yMax + 20}
          className="heat-tooltip"
          left={ensure(xScale(getHeat(lastMeasuredPoint))) + 20 || 0}
          style={{
            ...defaultTooltipStyle,
            height: isDefined(lastTotalHeat) ? 'auto' : defaultTooltipStyle.height,
            color: settings.colors.Monochromatic.White,
            backgroundColor: settings.colors.Primary.Grey_8,
          }}
        >
          {getHeatTooltipMessage()}
        </Tooltip>
      </ZoomedContainer>
    );

    return <Container>{zoomed ? getZoomedGraph() : getGraph()}</Container>;
  }
);

const ZoomedContainer = styled.div`
  margin-top: 20px;
  margin-left: 17px;

  *:not(.vx-tooltip-portal):not(.circle):not(.dot):not(span):not(.heat-icon):not(.heat-icon path) {
    /* stylelint-disable-next-line */
    mix-blend-mode: multiply;
  }
`;

const Container = styled.div`
  .vx-axis-tick text {
    font-size: ${settings.typography.FontSize.X_Small};
    fill: ${settings.colors.Primary.Grey_6};
    font-family: ${settings.typography.FontFamily.Regular};
  }
`;
const Message = styled.span`
  display: inline-flex;
  align-content: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.2;
`;

export default React.memo(WmsGraph);
