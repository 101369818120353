/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import { scaleLinear } from '@visx/scale';
import React, { useMemo } from 'react';

import { SSVGContainer } from '../../styles';
import { VERTICAL_SECTION_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_COUNT } from './constants';

const GRAPH_HEIGHT = 22;
const TOTAL_GRAPH_HEIGHT = GRAPH_HEIGHT + 12;

const VALUE_MAX = 25;

interface Props {
  className?: string;
  verticalSectionWidth: number;
}
const GraphBarCharts: React.ChildlessComponent<Props> = ({ className, verticalSectionWidth }) => {
  const yScale = scaleLinear({
    range: [GRAPH_HEIGHT, 0],
    domain: [0, VALUE_MAX],
  });

  const bars = useMemo(() => {
    const ret = [];
    ret.push(
      <text
        key={`repair-${ret.length}`}
        x={0}
        y={GRAPH_HEIGHT + 4}
        textAnchor="end"
        alignmentBaseline="hanging"
        fontFamily={settings.typography.FontFamily.Regular}
        fontSize={settings.typography.FontSize.X_Small}
        fill={settings.colors.Primary.Grey_6}
      >
        Repair ∆ [mm]
      </text>
    );

    for (let index = 0; index < VERTICAL_SECTIONS_COUNT; index++) {
      const centerX = index * verticalSectionWidth + verticalSectionWidth / 2;
      const width = verticalSectionWidth - 2 * VERTICAL_SECTION_HORIZONTAL_MARGIN;
      const value = Math.ceil(Math.random() * VALUE_MAX);
      const y = yScale(value);
      const fill = settings.colors.Primary.Blue_9;
      ret.push(<rect key={`rect-${index}`} x={centerX - width / 2} y={y} width={width} height={GRAPH_HEIGHT - y} fill={fill} />);
      ret.push(
        <text
          key={`value-${index}`}
          x={centerX}
          y={GRAPH_HEIGHT + 4}
          textAnchor="middle"
          dominantBaseline="hanging"
          fontFamily={settings.typography.FontFamily.Regular}
          fontSize={settings.typography.FontSize.X_Small}
          fill={settings.colors.Primary.Grey_6}
        >
          {value}
        </text>
      );
    }
    return ret;
  }, [verticalSectionWidth, yScale]);

  return (
    <SSVGContainer height={TOTAL_GRAPH_HEIGHT} className={className}>
      <g transform={`translate(${VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN} 0)`}>{bars}</g>
    </SSVGContainer>
  );
};

GraphBarCharts.whyDidYouRender = true;
export default React.memo(GraphBarCharts);
