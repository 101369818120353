import { settings } from '@rhim/design';
import { CampaignIcon } from '@rhim/icons/24';
import { campaignsDropdownItem } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { useCallback } from 'react';
import { useHover } from 'react-aria';
import styled from 'styled-components';

import { CellWrapper, getItemRowColors, ItemRow, PrimaryInfo, PrimaryLabel } from '../../pages/MeasurementView/MeasurementViewStrip/shared';

interface Referable {
  ref?: React.Ref<HTMLTableRowElement>;
}

interface Props {
  campaign: RHIM.CampaignOrUnassigned;
  isSelectedCampaign: boolean;
  onCampaignClicked: (campaignId: number | null) => void;
}
const CampaignSelectionItem = React.forwardRef<HTMLTableRowElement, Props & Referable>(function CampaignSelectionItem(
  { campaign, isSelectedCampaign, onCampaignClicked },
  ref
) {
  const { hoverProps, isHovered } = useHover({});

  const isDisabled = isDefined(campaign.itemCount) && campaign.itemCount < 1;

  const handleCampaignClicked = useCallback(() => {
    if (isDisabled) {return;}
    onCampaignClicked(campaign.id ?? null);
  }, [campaign, onCampaignClicked, isDisabled]);

  const { rowPrimaryInfo } = getItemRowColors({ isSelected: isSelectedCampaign, isHover: isHovered, isDisabled });

  return (
    <ItemRow
      variant="medium-48"
      ref={ref}
      {...hoverProps}
      isDisabled={isDisabled}
      style={{ backgroundColor: rowPrimaryInfo.backgroundColor }}
      onClick={handleCampaignClicked}
    >
      <td data-test-id={campaignsDropdownItem}>
        <CellWrapper>
          <PrimaryInfo style={rowPrimaryInfo}>
            <CampaignIcon fill={rowPrimaryInfo.fill} />
            <SPrimaryLabel>{campaign.displayText}</SPrimaryLabel>
          </PrimaryInfo>
        </CellWrapper>
      </td>
    </ItemRow>
  );
});

export default React.memo(CampaignSelectionItem);

const SPrimaryLabel = styled(PrimaryLabel)`
  padding-right: ${settings.Spacing.Spacing_200};
`;
