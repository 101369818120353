import { settings } from '@rhim/design';
import { getBrowserLocale, i18nReact, toUnicodeLocale } from '@rhim/i18n';
import { formatNumber } from '@rhim/react/partials';
import { RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto } from '@rhim/rest/processData';
import {
  chartTooltipContainerParametersComparison,
  currentCampaignHeatChartTooltipParametersComparison,
  headerChartTooltipParametersComparison,
  previousCampaignHeatChartTooltipParametersComparison,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import styled from 'styled-components';

export interface TooltipDataProps {
  name?: string | null;
  currentCampaignNr?: number;
  prevCampaignNr?: number;
  currentPoint?: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto;
  prevPoint?: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  heatRange: Double<number> | [number] | null;
}
const TooltipInfo = ({ currentPoint, prevPoint, prevCampaignNr, currentCampaignNr, heatRange }: TooltipDataProps) => {
  const locale = getBrowserLocale();
  const { t } = i18nReact.useTranslation(['parameters-comparison', 'shared']);

  const heats = React.useMemo(() => {
    if (isDefined(heatRange)) {
      if (heatRange.length > 1) {
        return `${heatRange[0]} - ${heatRange[1]}`;
      } else {
        return heatRange[0];
      }
    } else {return '-';}
  }, [heatRange]);

  return (
    <Container data-test-id={chartTooltipContainerParametersComparison}>
      <Header data-test-id={headerChartTooltipParametersComparison}>
        <Title>
          <Span>{`${t('parameters-comparison:heats')} ${heats} (${t('shared:common.average.abbreviated')})`}</Span>
        </Title>
      </Header>
      <Grid>
        {isDefined(currentPoint) && (
          <Row data-test-id={currentCampaignHeatChartTooltipParametersComparison}>
            <Title>
              <>
                <Line color={settings.colors.Primary.Blue_8} />
                <Circle stroke={settings.colors.Primary.Blue_8} />
                <Line color={settings.colors.Primary.Blue_8} />
              </>
              <RowSpan>
                {currentPoint.heat}/{currentCampaignNr}
              </RowSpan>
            </Title>
            <Span>
              <Info>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {isDefined(prevPoint) && currentPoint.value! !== prevPoint.value! && (
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  <ColorLine color={currentPoint.value! > prevPoint.value! ? settings.colors.Comparison.Higher : settings.colors.Comparison.Lower} />
                )}
                {isDefined(currentPoint.value) ? formatNumber(2)(toUnicodeLocale(locale))(currentPoint.value) : currentPoint.value}
              </Info>
            </Span>
          </Row>
        )}
        {isDefined(prevPoint) && (
          <Row data-test-id={previousCampaignHeatChartTooltipParametersComparison}>
            <Title>
              <>
                <Line color={settings.colors.Primary.Grey_6} />
                <Circle stroke={settings.colors.Primary.Grey_6} />
                <Line color={settings.colors.Primary.Grey_6} />
              </>
              <RowSpan>
                {prevPoint.heat}/{prevCampaignNr}
              </RowSpan>
            </Title>
            <Span>{isDefined(prevPoint.value) ? formatNumber(2)(toUnicodeLocale(locale))(prevPoint.value) : 0}</Span>
          </Row>
        )}
      </Grid>
    </Container>
  );
};

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${settings.colors.Monochromatic.White};
`;

const Row = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${settings.Spacing.Spacing_50};
`;

const Container = styled(Grid)`
  width: 180px;
  height: 60px;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${settings.colors.Primary.Grey_2};
`;

const Header = styled(Row)`
  height: 26px;
  background-color: ${settings.colors.Primary.Grey_2};
`;

const Span = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.X_Small};

  &::first-letter {
    text-transform: capitalize;
  }
`;

const RowSpan = styled(Span)`
  color: ${settings.colors.Primary.Grey_6};
  margin-left: ${settings.Spacing.Spacing_100};
`;

const Circle = styled.div<{ stroke: string }>`
  background: ${(props) => props.stroke};
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;
const Info = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${settings.typography.FontFamily.Bold};
`;
const Line = styled.div.attrs({ role: 'presentation' })<{ color?: string }>`
  width: 5px;
  height: 2px;
  background: ${(props) => (isDefined(props.color) ? props.color : 'transparent')};
`;

const ColorLine = styled.div.attrs({ role: 'presentation' })<{ color: string }>`
  width: 4px;
  height: 12px;
  background: ${(props) => props.color};
  margin-right: ${settings.Spacing.Spacing_50};
`;

const Title = styled.div<{ isCurrent?: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-left: ${(props) => (isDefined(props.isCurrent) && props.isCurrent ? settings.Spacing.Spacing_50 : settings.Spacing.Spacing_100)};
  font-size: ${settings.typography.FontSize.X_Small};
`;

export default TooltipInfo;
