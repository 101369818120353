import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

export interface GraphLegendProps {
  label: string;
  color: string;
  isSolidLine: boolean;
}

interface Props {
  legend: GraphLegendProps;
}

const GraphLegend: React.ChildlessComponent<Props> = ({ legend }) => {
  return (
    <SWrapper>
      {legend.isSolidLine ? <SLegendSolid fill={legend.color} /> : <SLegendDashed fill={legend.color} />}
      <SLabel>{legend.label}</SLabel>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  --width: 16px;

  display: flex;
  column-gap: ${settings.Spacing.Spacing_50};
  align-items: center;
`;

const SLegendSolid = styled.div<{ fill: string }>`
  width: var(--width);
  height: 4px;
  background-color: ${(props) => props.fill};
`;

const SLegendDashed = styled.div<{ fill: string }>`
  width: var(--width);
  height: 0;
  border-bottom: 2px dotted ${(props) => props.fill};
`;

const SLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_6};
`;

export default React.memo(GraphLegend);
