import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  height?: Property.Height;
  width?: Property.Width;
  title?: string;
  role?: string;
}

export const SafetyLiningIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, height, width, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" {...rest} shapeRendering="geometricprecision">
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M4.22134e-08 5C4.61807e-07 1 -9.00419e-07 9.53674e-07 -9.00419e-07 9.53674e-07L6 6.91406e-07L12 4.29138e-07C12 4.29138e-07 12 0.999999 12 5C12 9 6 12 6 12C6 12 -3.7738e-07 9 4.22134e-08 5Z"
        fill={fill}
      />
    </svg>
  );
};

SafetyLiningIcon.defaultProps = {
  height: '12',
  width: '12',
  fill: settings.colors.Primary.Blue_5,
};
