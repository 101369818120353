import { settings } from '@rhim/design';
import { Line } from '@vx/shape';
import styled from 'styled-components';

import { CONTAINER_HORIZONTAL_PADDING_PX } from './components/constants';

export const SSVGContainer = styled.svg<{ height: number }>`
  overflow: visible;
  width: 100%;
  height: ${(props) => props.height}px;
  shape-rendering: crispedges;
`;

export const SSubHeader = styled.div`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Large};
  color: ${settings.colors.Primary.Grey_8};
  margin-bottom: ${settings.Spacing.Spacing_150};
`;

export const SSubSubHeader = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Medium};
  color: ${settings.colors.Primary.Grey_8};
  margin-bottom: ${settings.Spacing.Spacing_100};
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${CONTAINER_HORIZONTAL_PADDING_PX}px;
`;

export const SDashedLine = styled(Line)`
  stroke-width: 1;
  stroke-dasharray: 2;
  pointer-events: none;
`;

export const SAxisLabel = styled.span<{ mode: 'yAxis' | 'xAxis' }>`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_8};
  align-self: ${(props) => (props.mode === 'yAxis' ? 'flex-start' : 'flex-end')};
`;
