/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { ensure, isDefined, roundFloatToDecimalPlaces } from '@rhim/utils';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useScaleY } from '../hooks/useScaleY';
import { SDashedLine } from '../styles';
import { getPaddedDomainY, LABEL_FONT_SIZE_PX, LABEL_PADDING_BOTTOM_PX } from '../utils';
import { AVERAGE_LINE_COLOR, GRAPH_X_AXIS_HEIGHT_PX, IRON_LINE_COLOR, SLAG_LINE_COLOR } from './constants';
import Graph from './Graph';
import { GraphLegendProps } from './GraphLegend';
import { WearRate } from './types';

const GRAPH_HEIGHT_TOTAL = 200;

const GRAPH_LEGENDS: GraphLegendProps[] = [
  {
    label: 'Slag Line',
    color: SLAG_LINE_COLOR,
    isSolidLine: true,
  },
  {
    label: 'Iron Line',
    color: IRON_LINE_COLOR,
    isSolidLine: true,
  },
  {
    label: 'Average',
    color: AVERAGE_LINE_COLOR,
    isSolidLine: false,
  },
];

function getColor(isSide0: boolean) {
  return isSide0 ? SLAG_LINE_COLOR : IRON_LINE_COLOR;
}

function getLineFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, wearRates: WearRate[], line: 'side0' | 'side1', index: number) {
  if (index === 0) {
    return null;
  }
  const wearRatePrevious = ensure(wearRates[index - 1]);
  const wearRateCurrent = ensure(wearRates[index]);

  const currentValue = line === 'side0' ? wearRateCurrent.side0WearRate : wearRateCurrent.side1WearRate;
  const currentSegmentX = xScale(wearRateCurrent.tonnageTons);
  const currentSegmentY = isDefined(currentValue) ? yScale(currentValue) : null;

  const previousValue = line === 'side0' ? wearRatePrevious.side0WearRate : wearRatePrevious.side1WearRate;
  const previousSegmentX = xScale(wearRatePrevious.tonnageTons);
  const previousSegmentY = isDefined(previousValue) ? yScale(previousValue) : currentSegmentY;

  const lineColor = getColor(line === 'side0');
  return (
    <React.Fragment key={`${line}-${index}`}>
      {/* THE CURRENT SEGMENT'S MAIN HORIZONTAL LINE */}
      {isDefined(currentSegmentY) && (
        <SLine
          key={`${wearRateCurrent.tonnageTons}-${index}`}
          from={{ x: previousSegmentX, y: currentSegmentY }}
          to={{ x: currentSegmentX, y: currentSegmentY }}
          stroke={lineColor}
        />
      )}
      {/* CONNECT THIS SEGMENT'S LEFTMOST POINT TO THE PREVIOUS SEGMENT'S RIGHTMOST POINT WITH A VERTICAL LINE */}
      {isDefined(previousSegmentY) && isDefined(currentSegmentY) && (
        <SLine
          key={`${line}-${wearRateCurrent.tonnageTons}-${index}`}
          from={{ x: previousSegmentX, y: previousSegmentY }}
          to={{ x: previousSegmentX, y: currentSegmentY }}
          stroke={lineColor}
        />
      )}
    </React.Fragment>
  );
}

function getLinesFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, wearRates: WearRate[], line: 'side0' | 'side1') {
  const ret = [];
  for (let index = 0; index < wearRates.length; index++) {
    ret.push(getLineFor(xScale, yScale, wearRates, line, index));
  }
  const id = `group-wearRate-${line}`;
  return (
    <g key={id} id={id}>
      {ret}
    </g>
  );
}

function getLargestValuesTexts(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, wearRates: WearRate[]) {
  const ret = [];

  for (const [index, wearRate] of wearRates.entries()) {
    if (index === 0) {
      continue;
    }
    if (!(isDefined(wearRate.side0WearRate) && isDefined(wearRate.side1WearRate))) {
      continue;
    }
    const previousMeasurement = ensure(wearRates[index - 1]);
    const measurementSegmentTonnageAtMiddle = previousMeasurement.tonnageTons + (wearRate.tonnageTons - previousMeasurement.tonnageTons) / 2;
    const hasSide0TheLargestValue = wearRate.side0WearRate >= wearRate.side1WearRate;
    const largestValue = Math.max(wearRate.side0WearRate, wearRate.side1WearRate);
    const y = yScale(largestValue) - LABEL_PADDING_BOTTOM_PX - LABEL_FONT_SIZE_PX;
    ret.push(
      <text
        x={xScale(measurementSegmentTonnageAtMiddle)}
        y={y}
        textAnchor="middle"
        dominantBaseline="hanging"
        fontFamily={settings.typography.FontFamily.Regular}
        fontSize={LABEL_FONT_SIZE_PX}
        fill={getColor(hasSide0TheLargestValue)}
      >
        {roundFloatToDecimalPlaces(largestValue, 1)}
      </text>
    );
  }
  return ret;
}

interface Props {
  xScale: ScaleLinear<number, number>;
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
  line: 'slagLine' | 'ironLine';
  average: number;
}
const GraphWearRate: React.ChildlessComponent<Props> = ({ xScale, measurementSequence, line, average }) => {
  const graphAreaHeight = GRAPH_HEIGHT_TOTAL - GRAPH_X_AXIS_HEIGHT_PX;

  const wearRates = useMemo(() => {
    const ret: WearRate[] = [];
    let firstTonnageTons = 0;
    if (isDefined(measurementSequence.permanentLiningMeasurement)) {
      firstTonnageTons = measurementSequence.permanentLiningMeasurement.tonnage / 1000;
    } else if (isDefined(measurementSequence.wearLiningInitialMeasurement)) {
      firstTonnageTons = measurementSequence.wearLiningInitialMeasurement.tonnage / 1000;
    }
    ret.push({
      tonnageTons: firstTonnageTons,
      side0WearRate: null,
      side1WearRate: null,
    });
    for (const measurement of measurementSequence.measurementTuples) {
      if (isDefined(measurement.workingLiningMeasurement)) {
        ret.push({
          tonnageTons: measurement.workingLiningMeasurement.tonnage / 1000,
          side0WearRate: line === 'slagLine' ? measurement.workingLiningMeasurement.side0SlaglineWear : measurement.workingLiningMeasurement.side0IronlineWear,
          side1WearRate: line === 'slagLine' ? measurement.workingLiningMeasurement.side1SlaglineWear : measurement.workingLiningMeasurement.side1IronlineWear,
        });
      }
    }
    return ret;
  }, [measurementSequence, line]);

  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (const wearRate of wearRates) {
      if (isDefined(wearRate.side0WearRate)) {
        maxValue = Math.max(maxValue, wearRate.side0WearRate);
      }
      if (isDefined(wearRate.side1WearRate)) {
        maxValue = Math.max(maxValue, wearRate.side1WearRate);
      }
    }
    const paddedMaxValue = getPaddedDomainY(graphAreaHeight, maxValue);
    return paddedMaxValue;
  }, [wearRates, graphAreaHeight]);

  const yScale = useScaleY(graphAreaHeight, maxYValue);

  const measurementsAtTons = wearRates.map((wearRate) => wearRate.tonnageTons);

  const side0Lines = useMemo(() => getLinesFor(xScale, yScale, wearRates, 'side0'), [xScale, yScale, wearRates]);
  const side1Lines = useMemo(() => getLinesFor(xScale, yScale, wearRates, 'side1'), [xScale, yScale, wearRates]);
  const largestValueTexts = useMemo(() => getLargestValuesTexts(xScale, yScale, wearRates), [xScale, yScale, wearRates]);

  return (
    <Graph
      graphHeightTotal={200}
      xScale={xScale}
      yAxisLabel="↑Wear Rate [mm/t]"
      graphLegends={GRAPH_LEGENDS}
      xAxisLabel="Iron Amount [kt]→"
      measurementsAtTons={measurementsAtTons}
      maxYValue={maxYValue}
    >
      {side0Lines}
      {side1Lines}
      {largestValueTexts}
      <SDashedLine
        key="wear-rate-average-line"
        from={{ x: 0, y: yScale(average) }}
        to={{ x: xScale.range()[1], y: yScale(average) }}
        stroke={AVERAGE_LINE_COLOR}
      />
    </Graph>
  );
};

const SLine = styled(Line)`
  stroke-width: 2;
  shape-rendering: crispedges;
  pointer-events: none;
`;

GraphWearRate.whyDidYouRender = true;
export default React.memo(GraphWearRate);
