/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { FormattedDate } from '@rhim/react';
import {
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
  RHIMMeasurementServiceContractsDataLiningCondition,
  RHIMMeasurementServiceContractsDataVesselLining,
} from '@rhim/rest/fleetOverview';
import React from 'react';
import styled from 'styled-components';

import { getLiningConditionLabel, useVesselLiningLabel } from '../../../../../src/components/DataUploadPanel/QckLightPointCloudScanData/utils';

interface TooltipTitleProps {
  measurement: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto;
}
const TooltipTitle: React.ChildlessComponent<TooltipTitleProps> = ({ measurement }) => {
  const { t } = i18nReact.useTranslation('ingress');
  const getVesselLiningLabel = useVesselLiningLabel();

  return (
    <STooltipWrapper>
      <STooltipHeader>{getVesselLiningLabel(measurement.vesselLining as RHIMMeasurementServiceContractsDataVesselLining)}</STooltipHeader>
      <STooltipRow>
        <STooltipRowStrong>{measurement.tonnage / 1000}t</STooltipRowStrong>
        <STooltipRowRegular>
          <FormattedDate datetime={measurement.measurementTaken as Date.ISO_8601} showTime={true} />
        </STooltipRowRegular>
      </STooltipRow>
      <STooltipHorizontalDivider />
      <STooltipRow>
        <STooltipRowStrong>{getLiningConditionLabel(t, measurement.liningCondition as RHIMMeasurementServiceContractsDataLiningCondition)}</STooltipRowStrong>
      </STooltipRow>
    </STooltipWrapper>
  );
};

const STooltipWrapper = styled.div`
  background-color: ${settings.colors.Monochromatic.White};
  display: flex;
  flex-direction: column;
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.X_Small};
`;

const STooltipHeader = styled.div`
  background-color: ${settings.colors.Primary.Grey_2};
  font-family: ${settings.typography.FontFamily.Medium};
  padding: ${settings.Spacing.Spacing_100};
`;

const STooltipRow = styled.div`
  background-color: ${settings.colors.Monochromatic.White};
  font-family: ${settings.typography.FontFamily.Regular};
  padding: ${settings.Spacing.Spacing_100};
  display: flex;
  column-gap: ${settings.Spacing.Spacing_150};
`;

const STooltipRowStrong = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
`;

const STooltipRowRegular = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
`;

const STooltipHorizontalDivider = styled.div`
  height: 1px;
  background-color: ${settings.colors.Primary.Grey_2};
`;

TooltipTitle.whyDidYouRender = true;
export default React.memo(TooltipTitle);
