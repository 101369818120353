/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { Progress, Tabs } from '@rhim/react';
import {
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
} from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import BlastFurnaceRunnerReportContext, { IBlastFurnaceRunnerReportContext } from './BlastFurnaceRunnerReportContext';
import CampaignReportPanel from './CampaignReportPanel';
import MeasurementsTimelinePanel from './components/MeasurementsTimeLinePanel/MeasurementsTimelinePanel';
import MeasurementStrip from './components/MeasurementStrip';
import { useMeasurementSequence } from './hooks/useMeasurementSequence';
import MeasurementReportPanel from './MeasurementReportPanel';
import { SContainer } from './styles';

const BlastFurnaceRunnerReportPage: React.ChildlessComponent = () => {
  const [selectedMeasurement, setSelectedMeasurement] = useState<
    RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
  >();
  const { data: measurementSequence } = useMeasurementSequence('c8928a74-2def-4938-94cc-08dd05873c2f', 0);

  //console.log('FOO measurementSequence ', measurementSequence);

  const contextValue: IBlastFurnaceRunnerReportContext = useMemo(() => {
    return {
      selectedMeasurement,
    };
  }, [selectedMeasurement]);

  if (!isDefined(measurementSequence)) {
    return <Progress />;
  }

  return (
    <>
      <SStickyContainer>
        <MeasurementStrip />
        <MeasurementsTimelinePanel measurementSequence={measurementSequence} onMeasurementSelected={setSelectedMeasurement} />
      </SStickyContainer>
      <BlastFurnaceRunnerReportContext.Provider value={contextValue}>
        <SContainer>
          <Tabs>
            <Tabs.TabPane tab="Measurement Report" key={1}>
              <MeasurementReportPanel />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Campaign Report" key={2}>
              <CampaignReportPanel measurementSequence={measurementSequence} />
            </Tabs.TabPane>
          </Tabs>
        </SContainer>
      </BlastFurnaceRunnerReportContext.Provider>
    </>
  );
};

const SStickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

BlastFurnaceRunnerReportPage.whyDidYouRender = true;
export default React.memo(BlastFurnaceRunnerReportPage);
