import { assert, isDefined } from '@rhim/utils';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { ACTION_ID, DRAG_ZOOM_MAX, ZOOM_CENTER_DEFAULT, ZOOM_INIT, ZOOM_MAX, ZOOM_STEP } from './constants';
import { ScrollbarRef, TooltipPosition, ZoomRange } from './types';

export function useZoom({ scrollbarRef, setScrollPositions }: { scrollbarRef: ScrollbarRef; setScrollPositions: (scrollPosition: number | null) => void }) {
  const [hoverPosition, setHoverPosition] = useState<null | TooltipPosition>(null);
  const [zoomLevel, setZoomLevel] = useState(ZOOM_INIT);
  const [relativePos, setRelativePos] = useState<{ left: number; center: number } | undefined>(undefined);

  const [zoomRange, setZoomRange] = useState<ZoomRange | null>(null);
  const [zoomRangeScrollPos, setZoomRangeScrollPos] = useState<number | null>(null);
  const [isDragTarget, setIsDragTarget] = useState<boolean>(false);

  const handleZoom = useCallback(
    (zoomButtonId: string) => {
      const scrollbar = scrollbarRef.current;
      assert(isDefined(scrollbar), 'scrollbarRef is empty');

      const scrollLeft = scrollbar.getScrollLeft();
      const clientWidth = scrollbar.getClientWidth();
      const scrollWidth = scrollbar.getScrollWidth();

      const leftRelative = Math.ceil(scrollLeft) / (scrollWidth - clientWidth) || 0;
      const centerRelative = clientWidth === scrollWidth ? ZOOM_CENTER_DEFAULT : (scrollLeft + clientWidth / 2) / scrollWidth;
      setRelativePos({ left: leftRelative, center: centerRelative });

      setZoomLevel((current) => {
        const roundedCurrent = zoomButtonId === ACTION_ID.viewZoomOut ? Math.ceil(current) : Math.floor(current);
        const zoomOutLevel = roundedCurrent > ZOOM_MAX ? ZOOM_MAX : roundedCurrent - ZOOM_STEP;
        const zoomInLevel = roundedCurrent + ZOOM_STEP;
        return zoomButtonId === ACTION_ID.viewZoomOut ? zoomOutLevel : zoomInLevel;
      });
    },
    [scrollbarRef]
  );

  /**
   * Scrolls to the right position after zoom level has been set and the scrollbar width got updated.
   * **Used only after the zoom in/out buttons have been clicked.
   */
  useEffect(() => {
    if (isDefined(relativePos)) {
      const scrollbar = scrollbarRef.current;
      assert(isDefined(scrollbar), 'scrollbarRef is empty');

      const scrollWidthAfter = scrollbar.getScrollWidth();
      const clientWidth = scrollbar.getClientWidth();
      const leftRelative = relativePos.left;
      const centerRelative = relativePos.center;

      const isBoundary = leftRelative % 1 === 0;

      setScrollPositions(isBoundary ? leftRelative * (scrollWidthAfter - clientWidth) : centerRelative * scrollWidthAfter - clientWidth / 2);
      setRelativePos(undefined);
    }
  }, [scrollbarRef, relativePos, setScrollPositions, zoomLevel]);

  const handleZoomRange = useCallback(
    (range: ZoomRange) => {
      const { dragRange } = range;
      if (dragRange.from === dragRange.to) {return;}
      const scrollbar = scrollbarRef.current;
      assert(isDefined(scrollbar), 'scrollbarRefs is empty');

      const clientWidth = scrollbar.getClientWidth();

      const rangeDelta = dragRange.to - dragRange.from;
      const zoomFactor = Math.min(DRAG_ZOOM_MAX, (clientWidth / rangeDelta) * zoomLevel);
      const scrollPosition = (dragRange.from * zoomFactor) / zoomLevel;
      setZoomLevel(zoomFactor);
      setZoomRangeScrollPos(scrollPosition);
    },
    [scrollbarRef, setZoomLevel, zoomLevel]
  );

  const resetZoom = useCallback(() => {
    setZoomLevel(ZOOM_INIT);
  }, []);

  const handleZoomRangeChange = useCallback(() => {
    if (isDefined(hoverPosition)) {setHoverPosition(null);}
  }, [hoverPosition, setHoverPosition]);

  const handleZoomRangeDragStart = useCallback(() => {
    setIsDragTarget(true);
  }, [setIsDragTarget]);

  const handleZoomRangeDragEnd = useCallback(
    (range: ZoomRange | null) => {
      if (isDragTarget) {
        setIsDragTarget(false);
      }
      if (isDefined(range)) {
        handleZoomRange(range);
      }
    },
    [handleZoomRange, isDragTarget, setIsDragTarget]
  );

  useLayoutEffect(() => {
    if (zoomRangeScrollPos !== null) {
      setScrollPositions(zoomRangeScrollPos);
    }
  }, [setScrollPositions, zoomRangeScrollPos]);

  return {
    handleZoom,
    handleZoomRangeChange,
    handleZoomRangeDragStart,
    handleZoomRangeDragEnd,
    hoverPosition,
    isDragTarget,
    setHoverPosition,
    setScrollPositions,
    resetZoom,
    setZoomRange,
    setZoomLevel,
    zoomLevel,
    zoomRange,
  };
}
