import { settings } from '@rhim/design';
import { PlusIcon, XMiniIcon } from '@rhim/icons/16';
import { hasElements, isDefined } from '@rhim/utils';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../Button';
import { Form, Rule } from '../Form';
import { TextField } from '../TextField';

const DEFAULT_ADD_BUTTON_LABEL = 'Add';
const DEFAULT_EMPTY_MESSAGE = 'Not defined';

const Pill: React.FC<{ value: number; onClick: () => void }> = ({ value, onClick }) => {
  return (
    <PillShape onClick={onClick}>
      {value}
      <XMiniIcon />
    </PillShape>
  );
};

interface PillListProps {
  addButtonLabel?: string;
  className?: string;
  emptyMessage?: string;
  onAdd: (value: number) => void;
  onRemove: (index: number) => void;
  unit?: string;
  validationRules?: Rule[];
  values: Array<number>;
}

interface FormProps {
  newValue: number | null;
}

const PillList: React.FC<PillListProps> = (props) => {
  const {
    addButtonLabel = DEFAULT_ADD_BUTTON_LABEL,
    className,
    emptyMessage = DEFAULT_EMPTY_MESSAGE,
    onAdd,
    onRemove,
    validationRules = [],
    unit,
    values,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm<FormProps>();

  const onFinish = (_values: unknown) => {
    const formProps = _values as FormProps;
    if (!isDefined(formProps.newValue)) {
      return;
    }
    onAdd(Number(formProps.newValue));
    form.setFieldValue('newValue', null);
  };

  return (
    <Wrapper className={className}>
      <PillsWrapper>
        {hasElements(values) ? (
          values.map((value, index) => <Pill key={index} value={value} onClick={() => onRemove(index)} />)
        ) : (
          <PillWrapperUndefined>{emptyMessage}</PillWrapperUndefined>
        )}
      </PillsWrapper>
      <FormNewEntry form={form} onFinish={onFinish} initialValues={{ newValue: null }} onAbort={() => form.resetFields()} autoComplete="off">
        {isEditing ? (
          <FieldAndUnit>
            <FormItem name="newValue" rules={[{ required: false }, ...validationRules]}>
              <TextField variant="x-small" onBlur={() => setIsEditing(false)} autoFocus />
            </FormItem>
            {isDefined(unit) && <Unit>{unit}</Unit>}
          </FieldAndUnit>
        ) : (
          <Button icon={{ icon: <PlusIcon />, position: 'start' }} size="x-small-32" onClick={() => setIsEditing(true)} label={addButtonLabel} />
        )}
      </FormNewEntry>
    </Wrapper>
  );
};

export default PillList;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${settings.Spacing.Spacing_100} ${settings.Spacing.Spacing_50};
`;

const PillShape = styled.div`
  display: flex;
  height: ${settings.Spacing.Spacing_300};
  padding: ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_100};
  justify-content: center;
  align-items: center;
  border-radius: ${settings.Spacing.Spacing_150};
  background-color: ${settings.colors.Primary.Blue_3};
  color: ${settings.colors.Primary.Blue_9};
  gap: 2px;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Medium};
  line-height: 20px;
  cursor: pointer;
`;

const PillWrapperUndefined = styled(PillShape)`
  background-color: ${settings.colors.Primary.Grey_2};
  color: ${settings.colors.Primary.Grey_6};
`;

const FormNewEntry = styled(Form)`
  width: 100%;
  height: ${settings.Spacing.Spacing_700};
  margin-top: ${settings.Spacing.Spacing_200};
`;

const FieldAndUnit = styled.div`
  display: flex;
  gap: ${settings.Spacing.Spacing_100};
`;

const FormItem = styled(Form.Item)`
  width: 145px;

  .ant-form-item-explain {
    white-space: nowrap;
  }
`;

const Unit = styled.div`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
  height: ${settings.Spacing.Spacing_400};
  display: flex;
  align-items: center;
`;
