/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import React from 'react';

interface Props {
  xScale: ScaleLinear<number, number>;
  graphAreaHeight: number;
  value: number;
  isSelected: boolean;
}
const SVGVerticalLine: React.ChildlessComponent<Props> = ({ xScale, graphAreaHeight, value, isSelected }) => {
  const x = xScale(value);

  return (
    <Line
      key={value}
      from={{ x, y: 0 }}
      to={{ x, y: graphAreaHeight }}
      stroke={isSelected ? settings.colors.Primary.Blue_8 : settings.colors.Primary.Grey_3}
      strokeWidth={isSelected ? 2 : 1}
      pointerEvents="none"
      shapeRendering="crispedges"
    />
  );
};

SVGVerticalLine.whyDidYouRender = true;
export default React.memo(SVGVerticalLine);
