/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

import { FLOOR_WALLPLOT_DATA, IRON_LINE_COLOR, LEFT_WALLPLOT_DATA, RIGHT_WALLPLOT_DATA, SLAG_LINE_COLOR } from './components/constants';
import ReportHeader from './components/ReportHeader';
import { SectionCut } from './components/sectionCuts';
import Wallplot from './components/Wallplot';
import { SSubHeader, SSubSubHeader } from './styles';

const MeasurementReportPanel: React.ChildlessComponent = () => {
  return (
    <SWrapper>
      <ReportHeader title="Measurement Report" metadata="140,123t (Jun 16, 2024)" />
      <SSubHeader>Section Cuts</SSubHeader>
      <SectionCut />
      <SSubHeader>Wall & Floor</SSubHeader>
      <SSubSubHeader>Right Wall</SSubSubHeader>
      <Wallplot
        yAxisLabel="height [cm]"
        plotHeight={90}
        wallplotData={RIGHT_WALLPLOT_DATA}
        xAxisRange={[0, 10]}
        yAxisRange={[0, 100]}
        indicators={[
          { label: 'Slag Line', y: 20, color: SLAG_LINE_COLOR },
          { label: 'Iron Line', y: 50, color: IRON_LINE_COLOR },
        ]}
      />
      <SSubSubHeader>Left Wall</SSubSubHeader>
      <Wallplot
        yAxisLabel="height [cm]"
        plotHeight={90}
        wallplotData={LEFT_WALLPLOT_DATA}
        xAxisRange={[0, 10]}
        yAxisRange={[0, 100]}
        indicators={[
          { label: 'Slag Line', y: 20, color: SLAG_LINE_COLOR },
          { label: 'Iron Line', y: 50, color: IRON_LINE_COLOR },
        ]}
      />
      <SSubSubHeader>Floor</SSubSubHeader>
      <Wallplot yAxisLabel="width [cm]" plotHeight={135} wallplotData={FLOOR_WALLPLOT_DATA} xAxisRange={[0, 10]} yAxisRange={[-120, 120]} indicators={[]} />
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${settings.Spacing.Spacing_300};
`;

MeasurementReportPanel.whyDidYouRender = true;
export default React.memo(MeasurementReportPanel);
