import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import AppContext from '../../../app/AppContext';
import VesselSelection from '../../../components/VesselSelectionDropdown/VesselSelection';
import { useSelectedVessel, useVessels } from '../../../hooks';
import { Feature } from '../../../settings';

const MeasurementStrip: React.ChildlessComponent = () => {
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;

  const { data: vessels } = useVessels(customerId, undefined, Feature.BlastFurnaceRunnerReport);
  assert(isDefined(vessels), 'In Suspense mode data should always be defined, according to react-query docs.');

  const { selectedVesselId, navigateToSelectedVesselId } = useSelectedVessel(vessels);

  const isLoadingVessels = !isDefined(vessels);

  return (
    <SStripContainer>
      <VesselSelection vessels={vessels} isLoading={isLoadingVessels} selectedVesselId={selectedVesselId} onVesselSelected={navigateToSelectedVesselId} />
    </SStripContainer>
  );
};

const SStripContainer = styled.div`
  height: 48px;
  background-color: ${settings.colors.Primary.Grey_10};
  flex-shrink: 0;
  display: flex;
`;

MeasurementStrip.whyDidYouRender = true;
export default React.memo(MeasurementStrip);
