import { scaleLinear } from '@visx/scale';

export type MeausurementStateA = 'safetyLining' | 'workLiningUsedDefault' | 'workLiningPartial';
export type MeausurementStateB = 'workLiningInitial' | 'workLiningUsedAfterRepair' | null;

export enum MeasurementState {
  SafetylLining = 'SafetylLining',
  WorkLiningUsedDefault = 'WorkLiningUsedDefault',
  WorkLiningPartial = 'WorkLiningPartial',
  WorkLiningInitial = 'WorkLiningInitial',
  WorkLiningUsedAfterRepair = 'WorkLiningUsedAfterRepair',
}

export interface Measurement {
  id: number;
  state: MeasurementState;
}

export interface MeasurementInfo {
  atTons: number;
  measurements: [Measurement, Measurement | null];
  fillVolumneBefore: number;
  fillVolumneAfter: number;
  repairMixAmount: number;
  deltaVolume: number;
  repairMixVolume: number;
  deltaRBLSlagLine: number;
  deltaRBLIronLine: number;
  slagLine: {
    wearRate: number;
    thicknessBefore: number;
    thicknessAfter: number;
  };
  ironLine: {
    wearRate: number;
    thicknessBefore: number;
    thicknessAfter: number;
  };
}
export const DATA: MeasurementInfo[] = [
  {
    atTons: 0,
    measurements: [
      { id: 0, state: MeasurementState.SafetylLining },
      { id: 1, state: MeasurementState.WorkLiningInitial },
    ],
    fillVolumneBefore: 1,
    fillVolumneAfter: 1,
    repairMixAmount: 0,
    deltaVolume: 2,
    repairMixVolume: 4,
    deltaRBLSlagLine: 2,
    deltaRBLIronLine: 1.3,
    slagLine: { wearRate: 3.2, thicknessBefore: 400, thicknessAfter: 400 },
    ironLine: { wearRate: 2.8, thicknessBefore: 398, thicknessAfter: 398 },
  },
  {
    atTons: 100,
    measurements: [
      { id: 2, state: MeasurementState.WorkLiningUsedDefault },
      { id: 3, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 1.8,
    fillVolumneAfter: 2,
    repairMixAmount: 1,
    deltaVolume: 2.5,
    repairMixVolume: 4.8,
    deltaRBLSlagLine: 3,
    deltaRBLIronLine: 1.5,
    slagLine: { wearRate: 3.2, thicknessBefore: 390, thicknessAfter: 395 },
    ironLine: { wearRate: 2.8, thicknessBefore: 370, thicknessAfter: 375 },
  },
  {
    atTons: 140,
    measurements: [
      { id: 4, state: MeasurementState.WorkLiningUsedDefault },
      { id: 5, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 2.9,
    fillVolumneAfter: 3.2,
    repairMixAmount: 1.5,
    deltaVolume: 2.8,
    repairMixVolume: 5,
    deltaRBLSlagLine: 3.2,
    deltaRBLIronLine: 1.7,
    slagLine: { wearRate: 4.2, thicknessBefore: 300, thicknessAfter: 315 },
    ironLine: { wearRate: 3.5, thicknessBefore: 360, thicknessAfter: 368 },
  },
  {
    atTons: 175,
    measurements: [
      { id: 6, state: MeasurementState.WorkLiningUsedDefault },
      { id: 7, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 4,
    fillVolumneAfter: 4.3,
    repairMixAmount: 1.2,
    deltaVolume: 3.1,
    repairMixVolume: 5.2,
    deltaRBLSlagLine: 3.4,
    deltaRBLIronLine: 2,
    slagLine: { wearRate: 5.1, thicknessBefore: 240, thicknessAfter: 250 },
    ironLine: { wearRate: 4.2, thicknessBefore: 280, thicknessAfter: 293 },
  },
  {
    atTons: 205,
    measurements: [{ id: 8, state: MeasurementState.WorkLiningUsedDefault }, null],
    fillVolumneBefore: 4.8,
    fillVolumneAfter: 5,
    repairMixAmount: 2,
    deltaVolume: 3.4,
    repairMixVolume: 5.5,
    deltaRBLSlagLine: 3.9,
    deltaRBLIronLine: 2.4,
    slagLine: { wearRate: 4.9, thicknessBefore: 290, thicknessAfter: 292 },
    ironLine: { wearRate: 4, thicknessBefore: 210, thicknessAfter: 230 },
  },
  {
    atTons: 235,
    measurements: [
      { id: 9, state: MeasurementState.WorkLiningUsedDefault },
      { id: 10, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 6,
    fillVolumneAfter: 6.4,
    repairMixAmount: 1.8,
    deltaVolume: 3.1,
    repairMixVolume: 5.1,
    deltaRBLSlagLine: 4.3,
    deltaRBLIronLine: 2.7,
    slagLine: { wearRate: 5.2, thicknessBefore: 250, thicknessAfter: 260 },
    ironLine: { wearRate: 4.5, thicknessBefore: 160, thicknessAfter: 175 },
  },
  {
    atTons: 265,
    measurements: [
      { id: 11, state: MeasurementState.WorkLiningUsedDefault },
      { id: 12, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 7,
    fillVolumneAfter: 7.3,
    repairMixAmount: 2.1,
    deltaVolume: 2.9,
    repairMixVolume: 4.5,
    deltaRBLSlagLine: 4.5,
    deltaRBLIronLine: 2.9,
    slagLine: { wearRate: 5.9, thicknessBefore: 150, thicknessAfter: 160 },
    ironLine: { wearRate: 5.1, thicknessBefore: 145, thicknessAfter: 149 },
  },
  {
    atTons: 290,
    measurements: [
      { id: 13, state: MeasurementState.WorkLiningUsedDefault },
      { id: 14, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 8,
    fillVolumneAfter: 8.2,
    repairMixAmount: 3.4,
    deltaVolume: 3.7,
    repairMixVolume: 4.9,
    deltaRBLSlagLine: 4.8,
    deltaRBLIronLine: 3.3,
    slagLine: { wearRate: 4.5, thicknessBefore: 200, thicknessAfter: 210 },
    ironLine: { wearRate: 4.7, thicknessBefore: 130, thicknessAfter: 142 },
  },
  {
    atTons: 315,
    measurements: [
      { id: 15, state: MeasurementState.WorkLiningPartial },
      { id: 16, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 9,
    fillVolumneAfter: 9.7,
    repairMixAmount: 2.9,
    deltaVolume: 4.2,
    repairMixVolume: 5.6,
    deltaRBLSlagLine: 4.5,
    deltaRBLIronLine: 3.1,
    slagLine: { wearRate: 3.9, thicknessBefore: 180, thicknessAfter: 183 },
    ironLine: { wearRate: 3.6, thicknessBefore: 120, thicknessAfter: 128 },
  },
  {
    atTons: 335,
    measurements: [
      { id: 17, state: MeasurementState.WorkLiningUsedDefault },
      { id: 18, state: MeasurementState.WorkLiningUsedAfterRepair },
    ],
    fillVolumneBefore: 10,
    fillVolumneAfter: 10.3,
    repairMixAmount: 3.4,
    deltaVolume: 4.4,
    repairMixVolume: 5.8,
    deltaRBLSlagLine: 4.2,
    deltaRBLIronLine: 3.3,
    slagLine: { wearRate: 4.4, thicknessBefore: 140, thicknessAfter: 146 },
    ironLine: { wearRate: 4.9, thicknessBefore: 100, thicknessAfter: 104 },
  },
  {
    atTons: 355,
    measurements: [{ id: 19, state: MeasurementState.WorkLiningUsedDefault }, null],
    fillVolumneBefore: 11,
    fillVolumneAfter: 11.1,
    repairMixAmount: 3.1,
    deltaVolume: 4.6,
    repairMixVolume: 5.9,
    deltaRBLSlagLine: 3.9,
    deltaRBLIronLine: 2.5,
    slagLine: { wearRate: 4, thicknessBefore: 130, thicknessAfter: 130 },
    ironLine: { wearRate: 3.3, thicknessBefore: 90, thicknessAfter: 95 },
  },
];

export const LABEL_FONT_SIZE_PX = 12;
export const LABEL_PADDING_TOP_PX = 8;
export const LABEL_PADDING_BOTTOM_PX = 4;
const TOTAL_LABEL_HEIGHT_PX = LABEL_FONT_SIZE_PX + LABEL_PADDING_TOP_PX + LABEL_PADDING_BOTTOM_PX;
export function getPaddedDomainY(graphAreaHeight: number, graphDomainYMaximum: number) {
  const yScale = scaleLinear({
    range: [graphAreaHeight, 0],
    domain: [0, graphDomainYMaximum],
  });
  const paddedDomainValue = yScale.invert(TOTAL_LABEL_HEIGHT_PX);
  return graphDomainYMaximum + (graphDomainYMaximum - paddedDomainValue);
}
