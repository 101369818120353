import { AXIS_STYLES, TICK_LABEL_PROPS } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleLinear } from '@visx/scale';
import { range } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import React, { useContext, useMemo } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import BlastFurnaceRunnerReportContext from '../BlastFurnaceRunnerReportContext';
import { SAxisLabel, SSVGContainer } from '../styles';
import GraphHeader from './GraphHeader';
import { GraphLegendProps } from './GraphLegend';
import SVGVerticalLine from './SVGVerticalLine';

interface Props {
  graphHeightTotal: number;
  xScale: ScaleLinear<any, any>;
  yAxisLabel: string;
  graphLegends: GraphLegendProps[];
  xAxisLabel: string;
  measurementsAtTons: number[];
  maxYValue: number;
  children: React.Node;
}
const Graph: React.FC<Props> = ({ graphHeightTotal, xScale, yAxisLabel, graphLegends, xAxisLabel, measurementsAtTons, maxYValue, children }) => {
  const { selectedMeasurement } = useContext(BlastFurnaceRunnerReportContext);
  const [svgContainerRef, { width }] = useMeasure<SVGSVGElement>();
  //const xScale = scaleLinear({ domain: [0, MAX_X_VALUE], range: [0, width] });
  const graphAreaHeight = graphHeightTotal - 32;

  const yAxisTicks = useMemo(() => {
    return range(0, maxYValue, maxYValue / 5);
  }, [maxYValue]);

  const yScale = scaleLinear({
    range: [graphAreaHeight, 0],
    domain: [0, maxYValue],
  });

  return (
    <SWrapper>
      <GraphHeader yAxisLabel={yAxisLabel} graphLegends={graphLegends} />
      <SSVGContainer ref={svgContainerRef} height={graphHeightTotal}>
        <rect x={0} y={0} width={width} height={graphAreaHeight} fill="none" stroke={settings.colors.Primary.Grey_3} />
        <AxisLeft scale={yScale} tickValues={yAxisTicks} {...AXIS_STYLES} />
        <AxisBottom
          top={graphAreaHeight}
          scale={xScale}
          tickValues={measurementsAtTons}
          tickLength={AXIS_STYLES.tickLength}
          stroke={settings.colors.Primary.Grey_4}
          tickStroke={settings.colors.Primary.Grey_6}
          tickLabelProps={(measurementAtTons) => {
            const props = {
              ...TICK_LABEL_PROPS,
              textAnchor: 'middle',
            } as const;
            return measurementAtTons === selectedMeasurement?.tonnage ? { ...props, fontFamily: settings.typography.FontFamily.Bold } : props;
          }}
        />
        {/* VERTICAL LINE WITH LABEL FOR EACH DATA POINT ACROSS THE WHOLE Y-AREA  */}
        <g id="group-vertical-lines">
          {measurementsAtTons.map((measurementAtTons, index) => (
            <React.Fragment key={index}>
              <SVGVerticalLine
                graphAreaHeight={graphAreaHeight}
                xScale={xScale}
                value={measurementAtTons}
                isSelected={measurementAtTons === selectedMeasurement?.tonnage}
              />
            </React.Fragment>
          ))}
        </g>
        {children}
      </SSVGContainer>
      <SAxisLabel mode="xAxis">{xAxisLabel}</SAxisLabel>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

Graph.whyDidYouRender = true;
export default React.memo(Graph);
