import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  height?: Property.Height;
  width?: Property.Width;
  title?: string;
  role?: string;
}

export const StarIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, height, width, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 11" {...rest} shapeRendering="geometricprecision">
      {isDefined(title) ? <title>{title}</title> : null}
      <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill={fill} />
    </svg>
  );
};

StarIcon.defaultProps = {
  height: '12',
  width: '11',
  fill: settings.colors.Primary.Blue_9,
};
