import { AXIS_STYLES, TICK_LABEL_PROPS } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { WrenchIcon } from '@rhim/icons';
import { SafetyLiningIcon, StarIcon, WorkLiningPartialIcon } from '@rhim/icons/12';
import { ChevronDownMiniIcon, ChevronUpMiniIcon } from '@rhim/icons/16';
import {
  RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
  RHIMMeasurementServiceContractsDataLiningCondition,
  RHIMMeasurementServiceContractsDataVesselLining,
} from '@rhim/rest/fleetOverview';
import { assert, ensure, hasElements, isDefined, last } from '@rhim/utils';
import { AxisBottom } from '@visx/axis';
import { max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import styled, { css } from 'styled-components';

import { SContainer as Container, SSVGContainer } from '../../styles';
import { DATA } from '../../utils';
import { CONTAINER_HORIZONTAL_PADDING_PX, GRAPH_X_AXIS_HEIGHT_PX } from '../constants';
import CustomTooltip from './CustomTooltip';

const TOTAL_SVG_HEIGHT_PX = 72;
const X_AXIS_TOP_OFFSET_PX = TOTAL_SVG_HEIGHT_PX - GRAPH_X_AXIS_HEIGHT_PX;
export const MAX_X_VALUE = 400;

const SELECTED_CLASS_NAME = 'selected';
const BOX_SIZE_PX = 12;
const SELECTED_BOX_BORDER_WIDTH_PX = 2;
const SELECTED_BOX_PADDING_PX = 1;
const SELECTED_BOX_SIZE_PX = BOX_SIZE_PX + 2 * SELECTED_BOX_BORDER_WIDTH_PX + 2 * SELECTED_BOX_PADDING_PX;
const BOX_RADIUS_PX = 2;
const VERTICAL_LINE_LENGTH_PX = 4;

export const measurementsAtTons: number[] = DATA.map((measurement) => measurement.atTons);

interface MeasurementIconProps {
  measurement: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto;
  isSelected: boolean;
  onMeasurementSelected: (measurementId: string) => void;
}
const MeasurementIcon: React.ChildlessComponent<MeasurementIconProps> = ({ measurement, isSelected, onMeasurementSelected }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleMouseClicked = useCallback(() => {
    onMeasurementSelected(measurement.measurementId);
  }, [measurement, onMeasurementSelected]);

  const boxFill = isHovered || isSelected ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Blue_5;
  const iconFill = isHovered || isSelected ? settings.colors.Monochromatic.White : settings.colors.Primary.Blue_9;

  const measurementIcon = useMemo(() => {
    const ret = [];
    const measurementVesselLining = measurement.vesselLining as RHIMMeasurementServiceContractsDataVesselLining;
    const measurementLiningCondition = measurement.liningCondition as RHIMMeasurementServiceContractsDataLiningCondition;
    const isPermanentLining = measurementVesselLining === RHIMMeasurementServiceContractsDataVesselLining.PermanentLining;
    const isWorkLiningInitial =
      measurementVesselLining === RHIMMeasurementServiceContractsDataVesselLining.WearLining &&
      measurementLiningCondition === RHIMMeasurementServiceContractsDataLiningCondition.Initial;
    const isWorkLiningPartial = !measurement.emptyVesselMeasurement;
    const isAfterRepair =
      measurementVesselLining === RHIMMeasurementServiceContractsDataVesselLining.WearLining &&
      measurementLiningCondition === RHIMMeasurementServiceContractsDataLiningCondition.AfterRepair;

    if (isPermanentLining || isWorkLiningPartial) {
      ret.push(
        <CustomTooltip measurement={measurement}>
          <SIconContainer
            className={isSelected ? SELECTED_CLASS_NAME : ''}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleMouseClicked}
          >
            {isPermanentLining && <SafetyLiningIcon fill={boxFill} />}
            {isWorkLiningPartial && <WorkLiningPartialIcon fill={boxFill} />}
          </SIconContainer>
        </CustomTooltip>
      );
    } else {
      ret.push(
        <CustomTooltip measurement={measurement}>
          <SBox
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            fill={boxFill}
            onClick={handleMouseClicked}
            className={isSelected ? SELECTED_CLASS_NAME : ''}
          >
            {isWorkLiningInitial && <StarIcon fill={iconFill} />}
            {isAfterRepair && <WrenchIcon fill={iconFill} />}
          </SBox>
        </CustomTooltip>
      );
    }
    ret.push(<SVerticalLine />);
    return ret;
  }, [measurement, isSelected, boxFill, iconFill, handleMouseEnter, handleMouseLeave, handleMouseClicked]);

  return <>{measurementIcon}</>;
};

interface Props {
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
  onMeasurementSelected: (
    measurement: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
  ) => void;
}
const MeasurementsTimelinePanel: React.ChildlessComponent<Props> = ({ measurementSequence, onMeasurementSelected }) => {
  const [svgContainerRef, { width }] = useMeasure<SVGSVGElement>();

  const measurements = useMemo(() => {
    const ret: (RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto)[] = [];
    if (isDefined(measurementSequence.permanentLiningMeasurement)) {
      ret.push(measurementSequence.permanentLiningMeasurement);
    }
    if (isDefined(measurementSequence.wearLiningInitialMeasurement)) {
      ret.push(measurementSequence.wearLiningInitialMeasurement);
    }
    for (const measurement of measurementSequence.measurementTuples) {
      if (isDefined(measurement.workingLiningMeasurement)) {
        ret.push(measurement.workingLiningMeasurement);
      }
      if (isDefined(measurement.afterRepairMeasurement)) {
        ret.push(measurement.afterRepairMeasurement);
      }
    }
    return ret;
  }, [measurementSequence]);

  const [selectedMeasurementId, setSelectedMeasurementId] = useState(() => {
    if (!hasElements(measurements)) {
      return undefined;
    }
    return last(measurements).measurementId;
  });
  const [isExpanded, setIsExpanded] = useState(true);

  const tonnageMaximumValueTons = useMemo(() => {
    const maxTonnageKg = max(measurements, (measurement) => measurement.tonnage);
    const maxTonnageTons = isDefined(maxTonnageKg) ? maxTonnageKg / 1000 : 0;
    return maxTonnageTons;
  }, [measurements]);

  const tonnageValuesTons: number[] = useMemo(() => {
    return measurements.map((measurement) => {
      const tonnageTons = measurement.tonnage / 1000;
      return tonnageTons;
    });
  }, [measurements]);

  const valueScale = scaleLinear().domain([0, tonnageMaximumValueTons]).range([0, width]);

  useEffect(() => {
    if (!isDefined(selectedMeasurementId)) {
      return;
    }
    const associatedMeasurement = measurements.find((measurement) => measurement.measurementId === selectedMeasurementId);
    assert(isDefined(associatedMeasurement), `Associated measurement not found for measurement with id : "${selectedMeasurementId}"`);
    onMeasurementSelected(associatedMeasurement);
  }, [measurements, selectedMeasurementId, onMeasurementSelected]);

  const handleExpandToggleClicked = useCallback(() => {
    setIsExpanded((current) => !current);
  }, []);

  const groupedMeasurements = useMemo(() => {
    const ret = [];
    if (isDefined(measurementSequence.permanentLiningMeasurement) || isDefined(measurementSequence.wearLiningInitialMeasurement)) {
      ret.push({ measurementAtBottom: measurementSequence.permanentLiningMeasurement, measurementAtTop: measurementSequence.wearLiningInitialMeasurement });
    }
    for (const measurementTuple of measurementSequence.measurementTuples) {
      const group = { measurementAtBottom: measurementTuple.workingLiningMeasurement, measurementAtTop: measurementTuple.afterRepairMeasurement };
      assert(
        isDefined(group.measurementAtTop) || isDefined(group.measurementAtBottom),
        `Sequence tupple has no workingLiningMeasurement nor afterRepairMeasurement`
      );
      ret.push(group);
    }
    return ret;
  }, [measurementSequence]);

  return (
    <>
      <SOuterContainer>
        <SInnerContainer isExpanded={isExpanded}>
          <SIconsContainer>
            {groupedMeasurements.map((group, index) => {
              assert(isDefined(group.measurementAtBottom) || isDefined(group.measurementAtTop), `Group has neither a top nor a bottom measurement`);
              const tonnageKg = isDefined(group.measurementAtBottom) ? group.measurementAtBottom.tonnage : ensure(group.measurementAtTop).tonnage;
              const tonnageTons = tonnageKg / 1000;
              const x = valueScale(tonnageTons);
              return (
                <SBoxContainer key={index} x={x}>
                  {isDefined(group.measurementAtTop) && (
                    <MeasurementIcon
                      measurement={group.measurementAtTop}
                      isSelected={group.measurementAtTop.measurementId === selectedMeasurementId}
                      onMeasurementSelected={setSelectedMeasurementId}
                    />
                  )}
                  {isDefined(group.measurementAtBottom) && (
                    <MeasurementIcon
                      measurement={group.measurementAtBottom}
                      isSelected={group.measurementAtBottom.measurementId === selectedMeasurementId}
                      onMeasurementSelected={setSelectedMeasurementId}
                    />
                  )}
                </SBoxContainer>
              );
            })}
          </SIconsContainer>
          <SSVGContainer ref={svgContainerRef} height={TOTAL_SVG_HEIGHT_PX}>
            <AxisBottom top={X_AXIS_TOP_OFFSET_PX} scale={valueScale} tickValues={tonnageValuesTons} {...AXIS_STYLES} {...TICK_LABEL_PROPS} />
          </SSVGContainer>
        </SInnerContainer>
      </SOuterContainer>
      <SExpandToggle onClick={handleExpandToggleClicked}>{isExpanded ? <ChevronUpMiniIcon /> : <ChevronDownMiniIcon />}</SExpandToggle>
    </>
  );
};

const SOuterContainer = styled.div`
  overflow: hidden;
  border-bottom: 1px solid ${settings.colors.Primary.Grey_3};
  box-shadow: 0 8px 32px rgb(0 0 0 / 15%);
  background-color: ${settings.colors.Monochromatic.White};
`;

const SInnerContainer = styled(Container)<{ isExpanded: boolean }>`
  position: relative;
  height: ${(props) => (props.isExpanded ? `${TOTAL_SVG_HEIGHT_PX}px` : '4px')};
  transition: height 0.25s;
`;

const SIconsContainer = styled.div`
  position: absolute;
  inset: 0 ${CONTAINER_HORIZONTAL_PADDING_PX}px 0 ${CONTAINER_HORIZONTAL_PADDING_PX}px;
`;

const SBoxContainer = styled.div<{ x: number }>`
  position: absolute;
  left: ${(props) => props.x}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -100%);
  top: ${X_AXIS_TOP_OFFSET_PX}px;
`;

const SSelectedBox = css`
  --size: ${SELECTED_BOX_SIZE_PX}px;
  --offset: ${-(SELECTED_BOX_SIZE_PX - BOX_SIZE_PX) / 2}px;

  width: var(--size);
  height: var(--size);
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  border: ${SELECTED_BOX_BORDER_WIDTH_PX}px solid ${settings.colors.Primary.Blue_5};
  background-color: ${settings.colors.Monochromatic.White};
  border-radius: ${BOX_RADIUS_PX}px;
  transform: translate(var(--offset), var(--offset));
  z-index: -1;
`;

const SBox = styled.div<{ fill: string }>`
  --size: ${BOX_SIZE_PX}px;

  width: var(--size);
  height: var(--size);
  border-radius: ${BOX_RADIUS_PX}px;
  background-color: ${(props) => props.fill};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.${SELECTED_CLASS_NAME}::before {
    ${SSelectedBox}
  }
`;

const SVerticalLine = styled.div`
  width: 2px;
  height: ${VERTICAL_LINE_LENGTH_PX}px;
  background-color: ${settings.colors.Primary.Blue_5};
  pointer-events: none;
  z-index: -2;
`;

const SIconContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;

  &.${SELECTED_CLASS_NAME}::before {
    ${SSelectedBox}
  }
`;

const SExpandToggle = styled.div`
  --borderRadius: 3px;

  margin-left: ${settings.Spacing.Spacing_100};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 16px;
  background-color: ${settings.colors.Monochromatic.White};
  border: 1px solid ${settings.colors.Primary.Grey_3};
  border-top: none;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: 0 8px 32px rgb(0 0 0 / 15%);
`;

MeasurementsTimelinePanel.whyDidYouRender = true;
export default React.memo(MeasurementsTimelinePanel);
