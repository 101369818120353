import { settings } from '@rhim/design';
import { getBrowserLocale, i18nReact, toUnicodeLocale } from '@rhim/i18n';
import { formatNumber } from '@rhim/react';
import {
  RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto,
  RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis,
  RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto,
} from '@rhim/rest/operatorDisplay';
import {
  deltaFromTargetLabelOperatorDisplay,
  minThicknessCurrentLabelOperatorDisplay,
  regionHeaderValuesContainerOperatorDisplay,
  targetCurrentLabelOperatorDisplay,
  wearRateAverageLabelOperatorDisplay,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import styledFactory, { ThemedStyledInterface } from 'styled-components';

import { warningThemes } from './dataProcessor';

const { PatchedThemeProvider } = settings.globals;

interface Props {
  currentMin?: number;
  target?: number;
  wear?: number | null;
  message?: string;
  maintenancetasks: RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto[];
  brickSuppliers: string[];
  compareMode?: boolean;
  kpis?: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModelKpis | null;
  kpisSettings?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto;
}
export type Theme = ValueOf<typeof warningThemes>;

const styled: ThemedStyledInterface<Theme> = styledFactory;

const WmsHeader: React.FunctionComponent<Props> = ({ currentMin, kpisSettings, kpis, compareMode = false, maintenancetasks }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { t } = i18nReact.useTranslation(['operator-display', 'shared']);
  const maintenance = maintenancetasks[0];
  const locale = getBrowserLocale();

  const theme: Theme = maintenance ? warningThemes[maintenance.severity] : warningThemes.None;

  const getFormattedKpisValue = (value?: number | null, fractionDigits = 0, positive = false) => {
    if (!isDefined(value)) {
      return '-';
    }
    return positive && value < 0 ? 'N/A' : formatNumber(fractionDigits)(toUnicodeLocale(locale))(value);
  };

  return (
    <PatchedThemeProvider theme={theme}>
      <Container ref={ref}>
        <MetricsAndTitle>
          <Metrics data-test-id={regionHeaderValuesContainerOperatorDisplay}>
            {!compareMode && kpisSettings && (
              <>
                {/*Current Min*/}
                {kpisSettings.visibilityCurrentLiningThickness === true && (
                  <CurrentBox>
                    <Title>{t('operator-display:minimumLiningThickness')}</Title>
                    <Subtitle>{t('operator-display:current')}</Subtitle>
                    <Value data-test-id={minThicknessCurrentLabelOperatorDisplay}>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(currentMin)}
                      </Text>
                      <Measurements>{t('operator-display:mm')}</Measurements>
                    </Value>
                  </CurrentBox>
                )}
                {kpisSettings.visibilityMinimumLiningThickness === true && (
                  <Box>
                    <Title>{t('operator-display:minimumLiningThickness')}</Title>
                    <Subtitle>{t('operator-display:minimumLiningThicknessCampaign')}</Subtitle>
                    <Value>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.minimumLiningThickness)}
                      </Text>
                      <Measurements>{t('operator-display:mm')}</Measurements>
                    </Value>
                  </Box>
                )}

                {/*Wear*/}
                {kpisSettings.visibilityWearRateAverage === true && (
                  <Box>
                    <Title>{t('operator-display:wearRate')}</Title>
                    <Subtitle>{t('operator-display:wearRateAverage')}</Subtitle>
                    <Value data-test-id={wearRateAverageLabelOperatorDisplay}>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.wearRateAverage, 2, true)}
                      </Text>
                      <Measurements>{t('operator-display:mmHeats')}</Measurements>
                    </Value>
                  </Box>
                )}
                {kpisSettings.visibilityWearRateInstantaneous === true && (
                  <Box>
                    <Title>{t('operator-display:wearRate')}</Title>
                    <Subtitle>{t('operator-display:wearRateInstantaneous')}</Subtitle>
                    <Value>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.wearRateInstantaneous, 2, true)}
                      </Text>
                      <Measurements>{t('operator-display:mmHeats')}</Measurements>
                    </Value>
                  </Box>
                )}
                {kpisSettings.visibilityWearRateRelatedMinimum === true && (
                  <Box>
                    <Title>{t('operator-display:wearRate')}</Title>
                    <Subtitle>{t('operator-display:minimumMeasurement')}</Subtitle>
                    <Value>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.wearRateRelatedMinimum, 2, true)}
                      </Text>
                      <Measurements>{t('operator-display:mmHeats')}</Measurements>
                    </Value>
                  </Box>
                )}

                {/*Potential*/}
                {kpisSettings.visibilityPotentialAverage === true && (
                  <Box>
                    <Title>{t('operator-display:potential')}</Title>
                    <Subtitle>{t('operator-display:potentialLatestMeasurement')}</Subtitle>
                    <Value>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.potentialAverage, 0, true)}
                      </Text>
                      <Measurements>{t('operator-display:heats')}</Measurements>
                    </Value>
                  </Box>
                )}
                {kpisSettings.visibilityPotentialMinimum === true && (
                  <Box>
                    <Title>{t('operator-display:potential')}</Title>
                    <Subtitle>{t('operator-display:minimumMeasurement')}</Subtitle>
                    <Value>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.potentialMinimum, 0, true)}
                      </Text>
                      <Measurements>{t('operator-display:heats')}</Measurements>
                    </Value>
                  </Box>
                )}

                {/*Target*/}
                {kpisSettings.visibilityThresholdTarget === true && (
                  <Box>
                    <TargetTitle>{t('operator-display:target')}</TargetTitle>
                    <Subtitle>{t('operator-display:current')}</Subtitle>
                    <Value data-test-id={targetCurrentLabelOperatorDisplay}>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.thresholdTarget)}
                      </Text>
                      <Measurements>{t('operator-display:mm')}</Measurements>
                    </Value>
                  </Box>
                )}
                {kpisSettings.visibilityThresholdLower === true && (
                  <Box>
                    <LowerThreshold>{t('operator-display:lowerThreshold')}</LowerThreshold>
                    <Subtitle>{t('operator-display:current')}</Subtitle>
                    <Value>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.thresholdLower)}
                      </Text>
                      <Measurements>{t('operator-display:mm')}</Measurements>
                    </Value>
                  </Box>
                )}

                {/*Delta*/}
                {kpisSettings.visibilityDeltaTarget === true && (
                  <Box>
                    <Title>{t('operator-display:delta')}</Title>
                    <Subtitle>{t('operator-display:deltaFromTarget')}</Subtitle>
                    <Value data-test-id={deltaFromTargetLabelOperatorDisplay}>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.deltaTarget)}
                      </Text>
                      <Measurements>{t('operator-display:mm')}</Measurements>
                    </Value>
                  </Box>
                )}
                {kpisSettings.visibilityDeltaLower === true && (
                  <Box>
                    <Title>{t('operator-display:delta')}</Title>
                    <Subtitle>{t('operator-display:deltaFromLowerThreshold')}</Subtitle>
                    <Value>
                      <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                        {getFormattedKpisValue(kpis?.deltaLower)}
                      </Text>
                      <Measurements>{t('operator-display:mm')}</Measurements>
                    </Value>
                  </Box>
                )}
              </>
            )}
          </Metrics>
        </MetricsAndTitle>
      </Container>
    </PatchedThemeProvider>
  );
};

const Metrics = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

const MetricsAndTitle = styled.div`
  display: flex;
  flex-direction: column;

  /* Stretch to fit parent width (so that brick suppliers can flush to the right border of their container) */
  width: 100%;
`;

const Container = styled.div`
  display: inline-flex;
`;

const Value = styled.div``;

const Box = styled.div`
  height: 62px;
  width: 128px;
  background: ${settings.colors.Primary.Grey_1};
  display: inline-grid;
  padding: 8px;
`;

const ColoredBox = styled(Box)`
  background: ${(props) => props.theme.color};
  border: ${(props) => `1px solid ${props.theme.borderColor}`};
`;

const CurrentBox = styled(ColoredBox)`
  border: ${(props) => (isDefined(props.theme.currentBorderColor) ? `1px solid ${props.theme.currentBorderColor}` : 'none')};
`;

const Title = styled.span`
  color: ${settings.colors.Primary.Grey_6};
  line-height: 14px;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  white-space: nowrap;
`;

const TargetTitle = styled(Title)`
  &::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 3px;
    margin-top: 5px;
    margin-left: 5px;
    background: ${settings.colors.Operational.State_Green_3};
  }
`;
const LowerThreshold = styled(Title)`
  &::after {
    content: '';
    margin-top: 5px;
    margin-left: 5px;
    position: absolute;
    width: 17px;
    height: 3px;
    border-bottom: 2px dotted ${settings.colors.Operational.State_Alert_Orange_3};
  }
`;

const Subtitle = styled(Title)`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.XX_Small};
`;

const Text = styled.span<{ fontSize?: string; color?: string; font?: string }>`
  color: ${(props) => props.color ?? settings.colors.Primary.Grey_6};
  height: 16px;
  font-family: ${(props) => props.font ?? settings.typography.FontFamily.Regular};
  font-size: ${(props) => props.fontSize ?? settings.typography.FontSize.X_Small};
`;

const Measurements = styled(Text)`
  margin-left: 5px;
  margin-top: 9px;
`;

export default React.memo(WmsHeader);
