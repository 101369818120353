import { colorScalesForBOF, getColorUsingScale } from '@rhim/utils';
import React, { useMemo } from 'react';

import { GRAPH_HEIGHT, VERTICAL_SECTION_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_COUNT } from './constants';

interface Props {
  verticalSectionWidth: number;
}
const VerticalSections: React.ChildlessComponent<Props> = ({ verticalSectionWidth }) => {
  const verticalSections = useMemo(() => {
    const ret = [];
    for (let index = 0; index < VERTICAL_SECTIONS_COUNT; index++) {
      const centerX = index * verticalSectionWidth + verticalSectionWidth / 2;
      const width = verticalSectionWidth - 2 * VERTICAL_SECTION_HORIZONTAL_MARGIN;
      const fill = getColorUsingScale(Math.random() * 800, colorScalesForBOF);
      ret.push(<rect key={index} x={centerX - width / 2} y={0} width={width} height={GRAPH_HEIGHT} fill={fill} />);
    }
    return ret;
  }, [verticalSectionWidth]);

  return (
    <g id="verticalSections" transform={`translate(${VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN} 0)`}>
      {verticalSections}
    </g>
  );
};

VerticalSections.whyDidYouRender = true;
export default React.memo(VerticalSections);
