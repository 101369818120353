/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable */

import React, { Suspense } from 'react';

import { Feature } from '@paralleldrive/react-feature-toggles';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, RouterProvider, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useFullscreen } from 'react-use';

import ApplicationBar from '../app/ApplicationBar/ApplicationBar';
import SteelSidebar from '../app/ApplicationBar/SteelSidebar';
import { useCustomerIdHashmap } from '../hooks';
import { hasCementVessel, hasLadleVessel } from '../lib/vessels';
import { CementSidebar } from '../pages/AroReport/components/CementSidebar';
import { FleetOverview } from '../pages/FleetOverview';
import IncompleteSetupPage from '../pages/IncompleteSetupPage';
import Ingress from '../pages/Ingress';
import LegalPage from '../pages/Legal/LegalPage';
import MeasurementDataGateway from '../pages/MeasurementData/MeasurementDataGateway';
import MeasurementsDataUpload from '../pages/MeasurementData/MeasurementDataUpload';
import { useHiddenNavigation } from '../pages/OperatorDisplay';
import PageNotFound from '../pages/PageNotFound';
import PageRequiresAPO from '../pages/PageRequiresAPO';
import PageRequiresQckLight from '../pages/PageRequiresQckLight';
import ProcessDataUpload from '../pages/ProcessDataUpload';
import { CustomerFeatureFlag, FeatureFlag } from '../settings';
import { userSettingsARO, userSettingsHMD } from '../settings/userSettings';
import { authClient, PARAMS, PRIVILEGES, Privileges, ROUTES } from '../utilities';

import ParameterComparisonRoute from './ParameterComparisonRoute';
import { RootBoundary } from './routing/RootBoundary';
import { RouteId, tenantLoader } from './routing/TenantLoader';
import { TenantContext, TenantProvider } from './routing/TenantProvider';

import { settings } from '@rhim/design';
import { BrandRhimIcon } from '@rhim/icons';
import { Bag, createAuthenticatedBrowserRouter, DefaultFooter, Delay, ErrorBoundary, Layout, PageLoadingScreen, Splash } from '@rhim/react';
import { assert, ensure, hasElements, isDefined, noop } from '@rhim/utils';
import { BlastFurnaceRunnerReportPage } from '../pages/BlastFurnaceRunnerReport';
import { VolumeCalculationsPage } from '../pages/VolumeCalculations';
import { getStartDestination } from './routing/startDestination';

const UserSettings = React.lazy(() => import('@rhim/react/components/UserSettings/UserSettings'));
const WearExplorerRoute = React.lazy(() => import('./WearExplorerRoute'));
const OperatorDisplayRoute = React.lazy(() => import('./OperatorDisplayRoute'));
const RHGOBReportRoute = React.lazy(() => import('./RhGOBReportRoute'));
const MeasurementViewRoute = React.lazy(() => import('./MeasurementViewRoute'));
const ConnectedMachinesRoute = React.lazy(() => import('./ConnectedMachinesRoute'));
const AroRoute = React.lazy(() => import('./AroRoute'));
const AroDataCenterRoute = React.lazy(() => import('./AroDataCenterRoute'));
const QckLightPointCloudScanDataUploadRoute = React.lazy(() => import('./QckLightPointCloudScanDataUploadRoute'));
const Sandbox3d = React.lazy(() => import('../pages/Sandbox3d/Sandbox3d'));
const Potree = React.lazy(() => import('../pages/AroReport/components/Les/PotreeViewer/Potree'));

const PointCloudScanDataActiveComponent = React.memo(() => <QckLightPointCloudScanDataUploadRoute />);
const MeasurementViewRouteActiveComponent = React.memo(() => <MeasurementViewRoute />);
const VolumeCalculationsActiveComponent = React.memo(() => <VolumeCalculationsPage />);
const BlastFurnaceRunnerReportActiveComponent = React.memo(() => <BlastFurnaceRunnerReportPage />);

/**
 * Route Component.
 */
const AllRoutes: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  useTranslation(['errorBoundary']);

  return (
    <React.Fragment>
      <ErrorBoundary size="large">
        <Suspense fallback={<PageLoadingScreen container="parent" delay={Delay.Content} />}>
          <RouterProvider router={router} />
        </Suspense>
      </ErrorBoundary>
    </React.Fragment>
  );
};

AllRoutes.whyDidYouRender = true;

/**
 * Allows its children routes to render under one condition: the setup must be complete.
 *
 * Otherwise, redirects to the "Incomplete setup" page.
 */

const FooterLayout: React.FunctionComponent = () => {
  return <Layout main={<Outlet />} footer={<DefaultFooter hasCodeOfConduct />} />;
};

const BlankLayout: React.FunctionComponent = () => {
  return <Layout main={<Outlet />} />;
};

/**
 * Allows its children routes to render under one condition: the setup must be complete.
 *
 * Otherwise, redirects to the "Incomplete setup" page.
 */
const NavigationLayout: React.FunctionComponent = () => {
  const [hidden, hide] = useHiddenNavigation();
  const tenant = React.useContext(TenantContext);
  assert(isDefined(tenant), 'Attempted to access the tenant context outside of a TenantProvider');

  const { customer, selectCustomer } = tenant;
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryCustomerHash = params.get(PARAMS.CUSTOMER_ID);
  const { getCustomerIdByHash } = useCustomerIdHashmap();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const isFullscreen = useFullscreen(containerRef, hidden, {
    onClose() {
      // Turn fullscreen mode off when the user closes the fullscreen mode by pressing the escape key.
      hide(true);
    },
  });

  const isSetupIncomplete = React.useMemo(() => {
    // Check if there is any customer id in the link.
    const customerId = isDefined(queryCustomerHash) ? getCustomerIdByHash(queryCustomerHash) : customer.customerId;
    return customerId === customer.customerId && isDefined(customer.vesselTypes) && !hasElements(customer.vesselTypes);
  }, [getCustomerIdByHash, queryCustomerHash, customer]);

  React.useEffect(() => {
    if (isSetupIncomplete) {
      navigate(ROUTES.INCOMPLETE_SETUP);
    }
  }, [isSetupIncomplete, navigate]);

  React.useEffect(() => {
    hide(isFullscreen);
  }, [isFullscreen]);

  const isCement = isDefined(tenant) && hasCementVessel(tenant.customer);

  return (
    <Layout
      header={<ApplicationBar authClient={authClient} selectCustomer={selectCustomer} />}
      main={
        <Bag ref={containerRef}>
          <React.Suspense fallback={<PageLoadingScreen container="parent" />}>
            <Outlet />
          </React.Suspense>
        </Bag>
      }
      sidebar={
        <Routes>
          {/* Enable ARO sidebar if so configured */}
          <Route path={`${ROUTES.ARO_ROOT}/*`} element={<CementSidebar />} />
          <Route path={`${ROUTES.ARO_INGRESS}/*`} element={null} />
          {/* These features should show the steel sidebar */}
          {!isCement && !isSetupIncomplete && (
            <Route>
              <Route path={ROUTES.FLEET_OVERVIEW} element={<SteelSidebar />} />
              <Route path={ROUTES.REPORT} element={<SteelSidebar />} />
              <Route path={ROUTES.RH_REPORT} element={<SteelSidebar />} />
              <Route path={ROUTES.DISPLAY} element={<SteelSidebar />} />
              <Route path={ROUTES.PARAMETERS_COMPARISON} element={<SteelSidebar />} />
              <Route path={ROUTES.MEASUREMENT_VIEW} element={<SteelSidebar />} />
              <Route path={ROUTES.CONNECTED_MACHINES} element={<SteelSidebar />} />
              <Route path={ROUTES.VOLUME_CALCULATIONS} element={<SteelSidebar />} />
              <Route path={ROUTES.BLAST_FURNACE_RUNNER_REPORT} element={<SteelSidebar />} />
              <Route path={ROUTES.SANDBOX_3D} element={<SteelSidebar />} />
              <Route path={ROUTES.INGRESS} element={<SteelSidebar />} />
              <Route path={ROUTES.INGRESS_MEASUREMENT_DATA} element={<SteelSidebar />} />
              <Route path={ROUTES.INGRESS_MEASUREMENT_DATA_UPLOAD} element={<SteelSidebar />} />
              <Route path={ROUTES.INGRESS_PROCESS_DATA} element={<SteelSidebar />} />
              <Route path={ROUTES.INGRESS_POINT_CLOUD_SCAN_DATA} element={<SteelSidebar />} />
            </Route>
          )}
          {/* Hide sidebar on pages where sidebar makes no sense */}
          <Route path={ROUTES.INCOMPLETE_SETUP} element={null} />
          {/* Note: unrecognized pages (like 404) should not display the sidebar */}
          <Route path="*" element={null} />
        </Routes>
      }
      footer={
        <Routes>
          <Route path={ROUTES.MEASUREMENT_VIEW} element={null} />
          <Route path={ROUTES.DISPLAY} element={null} />
          <Route path={ROUTES.SANDBOX_3D} element={null} />
          <Route path="*" element={<DefaultFooter hasCodeOfConduct />} />
        </Routes>
      }
    />
  );
};

const PublicNavigationLayout: React.FunctionComponent = () => {
  const [hidden, hide] = useHiddenNavigation();
  const tenant = React.useContext(TenantContext);

  const selectCustomer = isDefined(tenant) ? tenant.selectCustomer : noop;
  const containerRef = React.useRef<HTMLDivElement>(null);

  const isFullscreen = useFullscreen(containerRef, hidden, {
    onClose() {
      // Turn fullscreen mode off when the user closes the fullscreen mode by pressing the escape key.
      hide(true);
    },
  });

  React.useEffect(() => {
    hide(isFullscreen);
  }, [isFullscreen]);

  return (
    <Layout
      header={<ApplicationBar authClient={authClient} selectCustomer={selectCustomer} />}
      main={
        <Bag ref={containerRef}>
          <React.Suspense fallback={<PageLoadingScreen container="parent" />}>
            <Outlet />
          </React.Suspense>
        </Bag>
      }
      sidebar={
        <Routes>
          {/* Hide sidebar on the splash screen */}
          <Route path={ROUTES.ROOT} element={null} />
          {/* Hide sidebar on pages where sidebar makes no sense */}
          <Route path={ROUTES.INCOMPLETE_SETUP} element={null} />
          <Route path={ROUTES.USER_SETTINGS} element={null} />
          {/* Hide sidebar on static pages */}
          <Route path={ROUTES.IMPRINT} element={null} />
          <Route path={ROUTES.PRIVACY} element={null} />
          <Route path={ROUTES.TERMS_OF_USE} element={null} />
          <Route path={ROUTES.CODE_OF_CONDUCT} element={null} />
          {/* Note: unrecognized pages (like 404) should not display the sidebar */}
          <Route path="*" element={null} />
        </Routes>
      }
      footer={
        <Routes>
          <Route path={ROUTES.MEASUREMENT_VIEW} element={null} />
          <Route path={ROUTES.DISPLAY} element={null} />
          <Route path={ROUTES.SANDBOX_3D} element={null} />
          <Route path="*" element={<DefaultFooter hasCodeOfConduct />} />
        </Routes>
      }
    />
  );
};

NavigationLayout.whyDidYouRender = true;

const router = createAuthenticatedBrowserRouter(authClient, ROUTES.START).withRoutesFromElements(
  <>
    {/* Private routes (these have access to TenantProvider and thus AppContext, which exposes the current user and the customer) */}
    <Route id={RouteId.Root} shouldRevalidate={() => false} loader={tenantLoader} errorElement={<RootBoundary />} element={<TenantProvider />}>
      <Route
        path={ROUTES.START}
        element={
          <Privileges>
            {(privileges) => (
              <TenantContext.Consumer>
                {(tenant) => {
                  assert(isDefined(tenant), 'Attempted to access the tenant context outside of a TenantProvider');
                  const { customer, search } = tenant;
                  const destination = getStartDestination(customer, privileges);

                  return <Navigate to={{ pathname: destination, search }} replace />;
                }}
              </TenantContext.Consumer>
            )}
          </Privileges>
        }
      />

      {/* Blank layout */}
      <Route element={<BlankLayout />}>
        <Route path={ROUTES.ARO_POTREE} element={<Potree />} />
      </Route>

      {/* Private routes that show the navigation bar */}
      <Route element={<NavigationLayout />}>
        <Route path={ROUTES.INCOMPLETE_SETUP} element={<IncompleteSetupPage />} />
        <Route
          path={ROUTES.USER_SETTINGS}
          element={
            <TenantContext.Consumer>
              {(tenant) => {
                assert(isDefined(tenant), 'Attempted to access the tenant context outside of a TenantProvider');

                const { customer } = tenant;
                const isCement = hasCementVessel(customer);
                return <UserSettings appConfig={isCement ? userSettingsARO : userSettingsHMD} />;
              }}
            </TenantContext.Consumer>
          }
        />

        {/* [Private] routes that require completed setup */}
        <Route element={<Outlet />}>
          <Route
            path="*"
            element={
              <Privileges>
                {(privileges) => (
                  <TenantContext.Consumer>
                    {(tenant) => {
                      assert(isDefined(tenant), 'Attempted to access the tenant context outside of a TenantProvider');

                      const { customer } = tenant;
                      const customerId: UUID = customer.customerId as UUID;

                      const hasFleetOverviewAccess =
                        privileges.global.has(PRIVILEGES.AccessFleetOverview) || privileges.customer.has(PRIVILEGES.AccessFleetOverview);
                      const hasOperatorDisplayAccess =
                        privileges.global.has(PRIVILEGES.AccessOperatorDisplay) || privileges.customer.has(PRIVILEGES.AccessOperatorDisplay);
                      const hasWearExplorerAccess =
                        privileges.global.has(PRIVILEGES.AccessWearExplorer) || privileges.customer.has(PRIVILEGES.AccessWearExplorer);
                      const hasMeasurementViewAccess =
                        privileges.global.has(PRIVILEGES.AccessMeasurementView) || privileges.customer.has(PRIVILEGES.AccessMeasurementView);
                      const hasDataCenterAccess = privileges.global.has(PRIVILEGES.AccessDataCenter) || privileges.customer.has(PRIVILEGES.AccessDataCenter);
                      const hasRHLegacyAccess =
                        privileges.global.has(PRIVILEGES.AccessRhLegacyPrediction) || privileges.customer.has(PRIVILEGES.AccessRhLegacyPrediction);
                      const hasCompareProcessParameterAccess =
                        privileges.global.has(PRIVILEGES.CompareProcessParameter) || privileges.customer.has(PRIVILEGES.CompareProcessParameter);

                      const steelRoutes = (
                        <>
                          {hasFleetOverviewAccess && (
                            <Route
                              path={ROUTES.FLEET_OVERVIEW}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.QCKLight}
                                  activeComponent={() => {
                                    // If we are currently on the Fleet-Overview page and the selected customer has no vessels of type Ladle, redirect to the Data-Center page
                                    const hasLadles = hasLadleVessel(customer) ?? true;

                                    return hasLadles ? <FleetOverview customerId={customerId} /> : <Navigate to={ROUTES.INGRESS} replace />;
                                  }}
                                  inactiveComponent={PageRequiresQckLight}
                                />
                              }
                            />
                          )}
                          {hasDataCenterAccess && <Route path={ROUTES.INGRESS} element={<Ingress key={customerId} />} />}
                          {hasDataCenterAccess && <Route path={ROUTES.INGRESS_PROCESS_DATA} element={<ProcessDataUpload key={customerId} />} />}
                          {hasDataCenterAccess && <Route path={ROUTES.INGRESS_MEASUREMENT_DATA} element={<MeasurementDataGateway customerId={customerId} />} />}
                          {hasDataCenterAccess && <Route path={ROUTES.INGRESS_MEASUREMENT_DATA_UPLOAD} element={<MeasurementsDataUpload key={customerId} />} />}
                          {hasDataCenterAccess && (
                            <Route
                              path={ROUTES.INGRESS_POINT_CLOUD_SCAN_DATA}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.QCKLight}
                                  activeComponent={PointCloudScanDataActiveComponent}
                                  inactiveComponent={PageRequiresQckLight}
                                />
                              }
                            />
                          )}
                          {hasMeasurementViewAccess && (
                            <Route
                              path={ROUTES.MEASUREMENT_VIEW}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.QCKLight}
                                  activeComponent={MeasurementViewRouteActiveComponent}
                                  inactiveComponent={PageRequiresQckLight}
                                />
                              }
                            />
                          )}
                          {hasMeasurementViewAccess && <Route path={ROUTES.CONNECTED_MACHINES} element={<ConnectedMachinesRoute customerId={customerId} />} />}
                          {hasMeasurementViewAccess && (
                            <Route
                              path={ROUTES.VOLUME_CALCULATIONS}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.VolumeCalculations}
                                  activeComponent={VolumeCalculationsActiveComponent}
                                  inactiveComponent={PageRequiresQckLight}
                                />
                              }
                            />
                          )}
                          {hasMeasurementViewAccess && (
                            <Route
                              path={ROUTES.BLAST_FURNACE_RUNNER_REPORT}
                              element={
                                <Feature
                                  name={FeatureFlag.BfrRegistrationV1}
                                  activeComponent={BlastFurnaceRunnerReportActiveComponent}
                                  inactiveComponent={PageRequiresQckLight}
                                />
                              }
                            />
                          )}
                          {hasWearExplorerAccess && (
                            <Route
                              path={ROUTES.REPORT}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.WearExplorer}
                                  activeComponent={() => <WearExplorerRoute customerId={customerId} />}
                                  inactiveComponent={PageRequiresAPO}
                                />
                              }
                            />
                          )}
                          {hasCompareProcessParameterAccess && (
                            <Route
                              path={ROUTES.PARAMETERS_COMPARISON}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.ParameterComparison}
                                  activeComponent={ParameterComparisonRoute}
                                  inactiveComponent={PageRequiresAPO}
                                />
                              }
                            />
                          )}
                          {hasOperatorDisplayAccess && (
                            <Route
                              path={ROUTES.DISPLAY}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.OperatorDisplay}
                                  activeComponent={OperatorDisplayRoute}
                                  inactiveComponent={PageRequiresAPO}
                                />
                              }
                            />
                          )}
                          {hasRHLegacyAccess && (
                            <Route
                              path={ROUTES.RH_REPORT}
                              errorElement={<RootBoundary />}
                              element={
                                <Feature
                                  name={CustomerFeatureFlag.LegacyRhView}
                                  activeComponent={() => <RHGOBReportRoute customerId={customerId} />}
                                  inactiveComponent={PageRequiresAPO}
                                />
                              }
                            />
                          )}
                          <Route path={ROUTES.SANDBOX_3D} element={<Sandbox3d />} />
                          <Route path="*" element={<PageNotFound />} />
                        </>
                      );

                      const cementRoutes = (
                        <>
                          <Route path={`${ROUTES.ARO_ROOT}/*`} element={<AroRoute customerId={customerId} />} />
                          <Route path={`${ROUTES.ARO_INGRESS_ROOT}/*`} element={<AroDataCenterRoute customerId={customerId} />} />
                          <Route path="*" element={<PageNotFound />} />
                        </>
                      );

                      const isCement = ensure(hasCementVessel(customer));

                      const routes = isCement ? cementRoutes.props.children : steelRoutes.props.children;
                      return <Routes>{routes}</Routes>;
                    }}
                  </TenantContext.Consumer>
                )}
              </Privileges>
            }
          />
        </Route>
      </Route>
    </Route>
    {/* Public routes */}
    <Route path="/" element={<FooterLayout />}>
      {/* Public routes that do not show the navigation bar */}
      <Route
        path={ROUTES.ROOT}
        index
        element={
          <Splash
            backgroundImageHref="assets/background.jpg"
            loginPath={ROUTES.START}
            showRhimBrandIcon={false}
            productIcon={<BrandRhimIcon height={settings.Defaults.Product_Logo_Height} />}
          />
        }
      />
    </Route>

    {/* Public routes that show the navigation bar */}
    <Route path="/" element={<PublicNavigationLayout />}>
      {/* Public routes */}
      <Route path={ROUTES.IMPRINT} element={<LegalPage />} />
      <Route path={ROUTES.PRIVACY} element={<LegalPage />} />
      <Route path={ROUTES.TERMS_OF_USE} element={<LegalPage />} />
      <Route path={ROUTES.CODE_OF_CONDUCT} element={<LegalPage />} />
      {/* Unrecogized routes */}
      <Route path="*" element={<PageNotFound />} />
    </Route>
  </>
);

export default React.memo(AllRoutes);
