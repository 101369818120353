import { RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto } from '@rhim/rest/operatorDisplay';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto;

export const useVesselWmsKpiSettings = (
  vesselId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (vesselId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'customerManagement',
      scope: 'vesselDisplayKpis',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.vesselKpiSettingsApi.getVesselkpisettingsDisplayVesselid(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useVesselWmsKpiSettings.getKey = getKey;
useVesselWmsKpiSettings.queryFn = queryFn;
