import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

import { SAxisLabel } from '../styles';
import GraphLegend, { GraphLegendProps } from './GraphLegend';

interface Props {
  yAxisLabel: string;
  graphLegends: GraphLegendProps[];
}

const GraphHeader: React.ChildlessComponent<Props> = ({ yAxisLabel, graphLegends }) => {
  return (
    <SHeaderContainer>
      <SAxisLabel mode="yAxis">{yAxisLabel}</SAxisLabel>
      <SLegendsContainer>
        {graphLegends.map((graphLegend, index) => (
          <GraphLegend key={index} legend={graphLegend} />
        ))}
      </SLegendsContainer>
    </SHeaderContainer>
  );
};

const SHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${settings.Spacing.Spacing_50};
`;

const SLegendsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${settings.Spacing.Spacing_150};
`;

GraphHeader.whyDidYouRender = true;
export default React.memo(GraphHeader);
