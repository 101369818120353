import {
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
} from '@rhim/rest/fleetOverview';
import { createContext } from 'react';

export interface IBlastFurnaceRunnerReportContext {
  selectedMeasurement:
    | undefined
    | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
    | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto;
}

const BlastFurnaceRunnerReportContext = createContext<IBlastFurnaceRunnerReportContext>({ selectedMeasurement: undefined });

export default BlastFurnaceRunnerReportContext;
