import { settings } from '@rhim/design';
import { Form, FormInstance } from 'antd';
import { Rule } from 'antd/es/form';
import * as React from 'react';
import styled from 'styled-components';

export type { FormInstance, Rule };

export default styled(Form).attrs<React.ComponentProps<typeof Form>>(() => ({ preserve: false }))`
  label {
    font-size: ${settings.typography.FontSize.X_Small};
  }

  .ant-form-item-explain-error {
    color: ${settings.colors.Operational.State_Notif_Magenta_4};
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${settings.typography.FontSize.X_Small};
  }
`;
