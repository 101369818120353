/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { ensure } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';

import { useScaleY } from '../hooks/useScaleY';
import { IRON_LINE_COLOR, SLAG_LINE_COLOR } from './constants';
import Graph from './Graph';
import { GraphLegendProps } from './GraphLegend';

const GRAPH_HEIGHT_TOTAL = 200;
const BAR_WIDTH = 14;

const GRAPH_LEGENDS: GraphLegendProps[] = [
  {
    label: 'Slag Line',
    color: SLAG_LINE_COLOR,
    isSolidLine: true,
  },
  {
    label: 'Iron Line',
    color: IRON_LINE_COLOR,
    isSolidLine: true,
  },
];

interface RBL {
  atTons: number;
  deltaRBLSlagLine: number;
  deltaRBLIronLine: number;
}

function getBarFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, graphAreaHeight: number, data: RBL[], index: number) {
  if (index === 0) {
    return null;
  }
  const rblCurrent = ensure(data[index]);

  const slagLineBarStartY = yScale(rblCurrent.deltaRBLSlagLine);
  const slagLineBarHeight = graphAreaHeight - slagLineBarStartY;

  const ironLineBarStartY = yScale(rblCurrent.deltaRBLIronLine);
  const ironLineBarHeight = graphAreaHeight - ironLineBarStartY;
  return (
    <React.Fragment key={index}>
      {/* SEGMENT'S SLAG-LINE BAR */}
      <rect
        key={`deltaRBL-${rblCurrent.atTons}-slagLine`}
        x={xScale(rblCurrent.atTons) - BAR_WIDTH}
        y={slagLineBarStartY}
        width={BAR_WIDTH}
        height={slagLineBarHeight}
        fill={SLAG_LINE_COLOR}
      />
      {/* SEGMENT'S IRON-LINE BAR */}
      <rect
        key={`deltaRBL-${rblCurrent.atTons}-ironLine`}
        x={xScale(rblCurrent.atTons)}
        y={ironLineBarStartY}
        width={BAR_WIDTH}
        height={ironLineBarHeight}
        fill={IRON_LINE_COLOR}
      />
    </React.Fragment>
  );
}

function getBarsFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, graphAreaHeight: number, repairMixAmounts: RBL[]) {
  const ret = [];
  for (let index = 0; index < repairMixAmounts.length; index++) {
    ret.push(getBarFor(xScale, yScale, graphAreaHeight, repairMixAmounts, index));
  }
  return <g id="group-volumeRepairDeltaVsMixVolume">{ret}</g>;
}

interface Props {
  xScale: ScaleLinear<number, number>;
  rbls: RBL[];
}
const GraphDeltaRemainingBrickLength: React.ChildlessComponent<Props> = ({ xScale, rbls }) => {
  const graphAreaHeight = GRAPH_HEIGHT_TOTAL - 32;

  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (let index = 0; index < rbls.length; index++) {
      const rbl = ensure(rbls[index]);
      maxValue = Math.max(maxValue, rbl.deltaRBLSlagLine);
      maxValue = Math.max(maxValue, rbl.deltaRBLIronLine);
    }
    return maxValue;
  }, [rbls]);

  //const xScale = useScaleX(graphAreaWidth);
  const yScale = useScaleY(graphAreaHeight, maxYValue);

  const measurementsAtTons = rbls.map((rbl) => rbl.atTons);

  const rblBars = useMemo(() => getBarsFor(xScale, yScale, graphAreaHeight, rbls), [xScale, graphAreaHeight, yScale, rbls]);

  return (
    <Graph
      graphHeightTotal={GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yAxisLabel="∆ RBL, After-Before Repair [mm]"
      graphLegends={GRAPH_LEGENDS}
      xAxisLabel="Amount of Steel [t]→"
      measurementsAtTons={measurementsAtTons}
      maxYValue={maxYValue}
    >
      {rblBars}
    </Graph>
  );
};

GraphDeltaRemainingBrickLength.whyDidYouRender = true;
export default React.memo(GraphDeltaRemainingBrickLength);
