/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { settings } from '@rhim/design';
import { ensure } from '@rhim/utils';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useScaleY } from '../hooks/useScaleY';
import Graph from './Graph';

const GRAPH_HEIGHT_TOTAL = 200;
const NODE_SIZE = 6;
const NODE_SIZE_HALF = NODE_SIZE / 2;

interface Volume {
  atTons: number;
  volumeBefore: number;
  volumeAfter: number;
}

function getLineFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, volumes: Volume[], index: number) {
  if (index === 0) {
    return null;
  }
  const volumePrevious = ensure(volumes[index - 1]);
  const volumneCurrent = ensure(volumes[index]);

  const previousValueAfter = volumePrevious.volumeAfter;
  const previousSegmentX = xScale(volumePrevious.atTons);
  const previousSegmentYAfter = yScale(previousValueAfter);

  const currentValueBefore = volumneCurrent.volumeBefore;
  const currentValueAfter = volumneCurrent.volumeAfter;
  const currentSegmentX = xScale(volumneCurrent.atTons);
  const currentSegmentYBefore = yScale(currentValueBefore);
  const currentSegmentYAfter = yScale(currentValueAfter);

  const color = settings.colors.Primary.Blue_8;
  return (
    <React.Fragment key={index}>
      {/* THE CURRENT SEGMENT'S MAIN LINE */}
      <SLine
        key={volumneCurrent.atTons}
        from={{ x: previousSegmentX, y: previousSegmentYAfter }}
        to={{ x: currentSegmentX, y: currentSegmentYBefore }}
        stroke={color}
      />
      {/* A SMALL CIRCLE WITH WHITE BORDER ON THE CURRENT SEGMENT'S VALUE BEFORE*/}
      <circle
        cx={currentSegmentX}
        cy={currentSegmentYBefore}
        r={NODE_SIZE_HALF}
        fill={color}
        stroke={settings.colors.Monochromatic.White}
        strokeWidth={1}
        shapeRendering="geometricprecision"
      />
      {/* A SMALL RECTANGLE WITH WHITE BORDER ON THE CURRENT SEGMENT'S VALUE AFTER*/}
      <rect
        x={currentSegmentX - NODE_SIZE_HALF}
        y={currentSegmentYAfter - NODE_SIZE_HALF}
        width={NODE_SIZE}
        height={NODE_SIZE}
        fill={color}
        stroke={settings.colors.Monochromatic.White}
        strokeWidth={1}
        shapeRendering="crispedges"
      />
    </React.Fragment>
  );
}

function getLinesFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, volumes: Volume[]) {
  const ret = [];
  for (let index = 0; index < volumes.length; index++) {
    ret.push(getLineFor(xScale, yScale, volumes, index));
  }
  return <g id="group-volume">{ret}</g>;
}

interface Props {
  xScale: ScaleLinear<number, number>;
  volumes: Volume[];
}
const GraphVolume: React.ChildlessComponent<Props> = ({ xScale, volumes }) => {
  const graphAreaHeight = GRAPH_HEIGHT_TOTAL - 32;

  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (let index = 0; index < volumes.length; index++) {
      const volume = ensure(volumes[index]);
      maxValue = Math.max(maxValue, volume.volumeBefore);
      maxValue = Math.max(maxValue, volume.volumeAfter);
    }
    return maxValue;
  }, [volumes]);

  //const xScale = useScaleX(graphAreaWidth);
  const yScale = useScaleY(graphAreaHeight, maxYValue);

  const measurementsAtTons = volumes.map((volume) => volume.atTons);

  const volumeLine = useMemo(() => getLinesFor(xScale, yScale, volumes), [xScale, yScale, volumes]);

  return (
    <Graph
      graphHeightTotal={200}
      xScale={xScale}
      yAxisLabel="Volume [m³]"
      graphLegends={[]}
      xAxisLabel="Amount of Steel [t]→"
      measurementsAtTons={measurementsAtTons}
      maxYValue={maxYValue}
    >
      {volumeLine}
    </Graph>
  );
};

const SLine = styled(Line)`
  stroke-width: 2;
  shape-rendering: geometricprecision;
  pointer-events: none;
`;

GraphVolume.whyDidYouRender = true;
export default React.memo(GraphVolume);
