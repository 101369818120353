import { scaleLinear } from '@visx/scale';
import { useMemo } from 'react';

export function useScaleY(graphAreaHeight: number, maxValue: number) {
  const yScale = useMemo(() => {
    return scaleLinear({
      range: [graphAreaHeight, 0],
      domain: [0, maxValue],
    });
  }, [graphAreaHeight, maxValue]);

  return yScale;
}
