import { settings } from '@rhim/design';

export const CONTAINER_HORIZONTAL_PADDING_PX = 48;
export const GRAPH_X_AXIS_HEIGHT_PX = 22;

export const SLAG_LINE_COLOR = settings.colors.Miscellaneous.Purple_Deep;
export const IRON_LINE_COLOR = settings.colors.Miscellaneous.Blue;
export const AVERAGE_LINE_COLOR = settings.colors.Primary.Blue_8;
export const CRITICAL_LINE_COLOR = settings.colors.Operational.State_Alert_Orange_3;
export const DELTA_VOLUME_AFTER_BEFORE_REPAIR_LINE_COLOR = settings.colors.Primary.Blue_8;
export const REPAIR_MIX_VOLUME_LINE_COLOR = settings.colors.Primary.Grey_6;

const WALLPLOT_COLUMN_COUNT = 30;
const LEFT_RIGHT_WALLPLOT_ROW_COUNT = 4;
const FLOOR_WALLPLOT_ROW_COUNT = 6;

function createMockWallplotData(rowCount: number, columnCount: number) {
  const ret: number[][] = [];
  for (let row = 0; row < rowCount; row++) {
    const rowData: number[] = [];
    for (let column = 0; column < columnCount; column++) {
      rowData.push(Math.floor(70 + Math.random() * 20));
    }
    ret.push(rowData);
  }
  return ret;
}

export const RIGHT_WALLPLOT_DATA: number[][] = createMockWallplotData(LEFT_RIGHT_WALLPLOT_ROW_COUNT, WALLPLOT_COLUMN_COUNT);
export const LEFT_WALLPLOT_DATA: number[][] = createMockWallplotData(LEFT_RIGHT_WALLPLOT_ROW_COUNT, WALLPLOT_COLUMN_COUNT);
export const FLOOR_WALLPLOT_DATA: number[][] = createMockWallplotData(FLOOR_WALLPLOT_ROW_COUNT, WALLPLOT_COLUMN_COUNT);
