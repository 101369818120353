import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { Checkbox } from '@rhim/react';
import { RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto } from '@rhim/rest/operatorDisplay';
import { isDefined } from '@rhim/utils';
import styled from 'styled-components';

interface Props {
  kpis?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto;
  onKpiChange: (pair: Partial<RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto>) => void;
}
const KpisSettingsPanel = ({ kpis, onKpiChange }: Props) => {
  const { t } = i18nReact.useTranslation(['operator-display']);

  const hasWear =
    isDefined(kpis?.visibilityWearRateAverage) || isDefined(kpis?.visibilityWearRateInstantaneous) || isDefined(kpis?.visibilityWearRateRelatedMinimum);
  const hasMinimumLiningThickness = isDefined(kpis?.visibilityCurrentLiningThickness) || isDefined(kpis?.visibilityMinimumLiningThickness);
  const hasPotential = isDefined(kpis?.visibilityPotentialMinimum) || isDefined(kpis?.visibilityPotentialAverage);
  const hasThresholds = isDefined(kpis?.visibilityThresholdLower) || isDefined(kpis?.visibilityThresholdTarget);
  const hasDelta = isDefined(kpis?.visibilityDeltaTarget) || isDefined(kpis?.visibilityDeltaLower);

  return (
    <Container>
      {hasWear && (
        <Section>
          <Title>{t('operator-display:wearRateCalculatedModes')}</Title>
          <Row>
            {isDefined(kpis.visibilityWearRateAverage) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityWearRateAverage)}
                onChange={(value) => onKpiChange({ visibilityWearRateAverage: value })}
              >
                <Text>{t('operator-display:wearRateAverage')}</Text>
              </Checkbox>
            )}
            {isDefined(kpis.visibilityWearRateInstantaneous) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityWearRateInstantaneous)}
                onChange={(value) => onKpiChange({ visibilityWearRateInstantaneous: value })}
              >
                <Text>{t('operator-display:wearRateInstantaneous')}</Text>
              </Checkbox>
            )}
            {isDefined(kpis.visibilityWearRateRelatedMinimum) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityWearRateRelatedMinimum)}
                onChange={(value) => onKpiChange({ visibilityWearRateRelatedMinimum: value })}
              >
                <Text>{t('operator-display:minimumLiningThickness')}</Text>
              </Checkbox>
            )}
          </Row>
        </Section>
      )}
      {hasMinimumLiningThickness && (
        <Section>
          <Title>{t('operator-display:minimumLiningThickness')}</Title>
          <Row>
            {isDefined(kpis.visibilityCurrentLiningThickness) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityCurrentLiningThickness)}
                onChange={(value) => onKpiChange({ visibilityCurrentLiningThickness: value })}
              >
                <Text>{t('operator-display:current')}</Text>
              </Checkbox>
            )}
            {isDefined(kpis.visibilityMinimumLiningThickness) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityMinimumLiningThickness)}
                onChange={(value) => onKpiChange({ visibilityMinimumLiningThickness: value })}
              >
                <Text>{t('operator-display:minimumLiningThicknessCampaign')}</Text>
              </Checkbox>
            )}
          </Row>
        </Section>
      )}
      {hasPotential && (
        <Section>
          <Title>{t('operator-display:potential')}</Title>
          <Row>
            {isDefined(kpis.visibilityPotentialMinimum) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityPotentialMinimum)}
                onChange={(value) => onKpiChange({ visibilityPotentialMinimum: value })}
              >
                <Text>{t('operator-display:potentialMinimum')}</Text>
              </Checkbox>
            )}
            {isDefined(kpis.visibilityPotentialAverage) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityPotentialAverage)}
                onChange={(value) => onKpiChange({ visibilityPotentialAverage: value })}
              >
                <Text>{t('operator-display:potentialRelatedToAverage')}</Text>
              </Checkbox>
            )}
          </Row>
        </Section>
      )}
      {hasThresholds && (
        <Section>
          <Title>{t('operator-display:thresholds')}</Title>
          <Row>
            {isDefined(kpis.visibilityThresholdLower) && (
              <Checkbox size="small" isSelected={Boolean(kpis.visibilityThresholdLower)} onChange={(value) => onKpiChange({ visibilityThresholdLower: value })}>
                <Text>{t('operator-display:lowerThreshold')}</Text>
              </Checkbox>
            )}
            {isDefined(kpis.visibilityThresholdTarget) && (
              <Checkbox
                size="small"
                isSelected={Boolean(kpis.visibilityThresholdTarget)}
                onChange={(value) => onKpiChange({ visibilityThresholdTarget: value })}
              >
                <Text>{t('operator-display:targetCurve')}</Text>
              </Checkbox>
            )}
          </Row>
        </Section>
      )}
      {hasDelta && (
        <Section>
          <Title>{t('operator-display:delta')}</Title>
          <Row>
            {isDefined(kpis.visibilityDeltaTarget) && (
              <Checkbox size="small" isSelected={Boolean(kpis.visibilityDeltaTarget)} onChange={(value) => onKpiChange({ visibilityDeltaTarget: value })}>
                <Text>{t('operator-display:deltaRelatedToTarget')}</Text>
              </Checkbox>
            )}
            {isDefined(kpis.visibilityDeltaLower) && (
              <Checkbox size="small" isSelected={Boolean(kpis.visibilityDeltaLower)} onChange={(value) => onKpiChange({ visibilityDeltaLower: value })}>
                <Text>{t('operator-display:deltaRelatedToLowerThreshold')}</Text>
              </Checkbox>
            )}
          </Row>
        </Section>
      )}
    </Container>
  );
};

export default KpisSettingsPanel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const Section = styled.div``;

const Title = styled.div`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  margin: 10px ${settings.Spacing.Spacing_200} 6px 0;
`;

const Text = styled.span`
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
`;
