import { settings } from '@rhim/design';
import { ChevronFilledUpIcon } from '@rhim/icons/16';
import { assert, isDefined } from '@rhim/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ZoomRange } from './types';
import { localX, sortRange } from './utils';

const ARROWS_MIN_RANGE = 8;

interface Props {
  children: React.ReactNode;
  isDisabled?: boolean;
  onRangeChange?: (range: ZoomRange | null) => void;
  onRangeDragStart?: (range: ZoomRange) => void;
  onRangeDragEnd?: (range: ZoomRange | null) => void;
  setZoomRange: React.Dispatch<React.SetStateAction<ZoomRange | null>>;
  xBisectToUnit: (x: number) => number;
  unitToX: (date: number) => number;
  zoomRange: ZoomRange | null;
}

type UseDragZoomProps = Omit<Props, 'children' | 'isDisabled'>;

const useDragZoom = (props: UseDragZoomProps) => {
  const { onRangeChange, onRangeDragStart, onRangeDragEnd, setZoomRange, xBisectToUnit, unitToX, zoomRange } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setIsDragging(true);

      const x = localX(e, wrapperRef);
      const chartDate = xBisectToUnit(x);
      const xSnap = unitToX(chartDate);
      const next = {
        dragRange: { from: xSnap, to: xSnap },
        dateRange: { from: chartDate },
      };

      setZoomRange(next);
      if (isDefined(onRangeDragStart)) {
        onRangeDragStart(next);
      }
      if (isDefined(onRangeChange)) {
        onRangeChange(next);
      }
      document.body.style.userSelect = 'none';
      document.body.style.cursor = 'grabbing';
    },
    [onRangeChange, onRangeDragStart, setIsDragging, setZoomRange, unitToX, xBisectToUnit, wrapperRef]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      setZoomRange((current) => {
        if (!isDefined(current) || !isDefined(wrapperRef.current)) {
          return current;
        }

        const x = localX(e, wrapperRef);
        const chartDate = xBisectToUnit(x);
        const xSnap = unitToX(chartDate);
        const dragRange = { from: current.dragRange.from, to: xSnap };
        const sortedDragRange = sortRange(dragRange);
        const dateRange = { from: current.dateRange.from, to: chartDate };
        const sortedDateRange = sortRange(dateRange);

        if (isDefined(onRangeChange)) {
          onRangeChange({ dragRange: sortedDragRange, dateRange: sortedDateRange });
        }

        return { dragRange, dateRange };
      });
    },
    [onRangeChange, setZoomRange, unitToX, xBisectToUnit, wrapperRef]
  );

  const handleMouseUp = useCallback(() => {
    assert(isDefined(zoomRange), 'no current range');
    if (isDefined(onRangeDragEnd)) {
      onRangeDragEnd({ ...zoomRange, dragRange: sortRange(zoomRange.dragRange) });
    }
    setIsDragging(false);
    setZoomRange(null);
    document.body.style.removeProperty('user-select');
    document.body.style.removeProperty('cursor');
  }, [setIsDragging, onRangeDragEnd, zoomRange, setZoomRange]);

  const handleCancelDrag = useCallback(() => {
    if (isDefined(onRangeDragEnd)) {
      onRangeDragEnd(null);
    }
    setIsDragging(false);
    setZoomRange(null);
    document.body.style.removeProperty('user-select');
    document.body.style.removeProperty('cursor');
  }, [onRangeDragEnd, setIsDragging, setZoomRange]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.body.style.userSelect = 'none';
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp, isDragging]);

  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCancelDrag();
      }
    };

    if (isDragging) {
      document.addEventListener('keydown', keyHandler);
    }

    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, [handleCancelDrag, isDragging]);

  return {
    isDragging,
    wrapperRef,
    handleMouseDown,
  };
};

const DragZoom: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { children, isDisabled, zoomRange } = props;

  const { isDragging, wrapperRef, handleMouseDown } = useDragZoom(props);

  const sorted = zoomRange ? sortRange(zoomRange.dragRange) : null;
  const sortedDelta = isDefined(sorted) ? sorted.to - sorted.from : 0;
  const isPreview = !isDragging;

  if (isDefined(isDisabled) && isDisabled) {return <>{children}</>;}

  return (
    <Wrapper ref={wrapperRef} onMouseDown={handleMouseDown}>
      {children}
      {sorted && (
        <OverlayWrapper isPreview={isPreview}>
          <OverlayLeft width={sorted.from} isPreview={isPreview} />
          <OverlayRight left={sorted.to} isPreview={isPreview} />
          {sortedDelta > ARROWS_MIN_RANGE && (
            <Arrows left={sorted.from} width={sortedDelta}>
              <ArrowLeft />
              <Line />
              <ArrowRight />
            </Arrows>
          )}
        </OverlayWrapper>
      )}
    </Wrapper>
  );
};

const OVERLAY_BORDER_WIDTH = 2;
const OVERLAY_PREVIEW_OPACITY = 0.4;

const Wrapper = styled.div`
  width: fit-content;
  position: relative;
  overflow: hidden;
`;

interface OverlayProps {
  isPreview: boolean;
}

const OverlayWrapper = styled.div<OverlayProps>`
  opacity: ${(props) => (props.isPreview ? OVERLAY_PREVIEW_OPACITY : 1)};
`;

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-color: ${(props: OverlayProps) => (props.isPreview ? 'transparent' : settings.colors.Primary.Blue_10 + '20')};
  border-color: ${settings.colors.Primary.Grey_8};
  border-style: solid;
`;

interface OverlayLeftProps {
  width: number;
}

const OverlayLeft = styled(Overlay).attrs<OverlayLeftProps>((props) => ({
  style: { width: props.width + OVERLAY_BORDER_WIDTH },
}))<OverlayLeftProps>`
  left: -${OVERLAY_BORDER_WIDTH}px;
  border-width: 0 ${OVERLAY_BORDER_WIDTH}px 0 0;
`;

interface OverlayRightProps {
  left: number;
}

const OverlayRight = styled(Overlay).attrs<OverlayRightProps>((props) => ({
  style: { left: props.left },
}))<OverlayRightProps>`
  right: 0;
  border-width: 0 0 0 ${OVERLAY_BORDER_WIDTH}px;
`;

interface ArrowsProps {
  left: number;
  width: number;
}

const Arrows = styled.div.attrs<ArrowsProps>((props) => ({
  style: { left: props.left, width: props.width },
}))<ArrowsProps>`
  position: absolute;
  top: 0;
  pointer-events: none;
`;

const Arrow = styled(ChevronFilledUpIcon)`
  position: absolute;
  top: -4px;
  fill: ${settings.colors.Primary.Grey_8};
`;

const ArrowLeft = styled(Arrow)`
  left: -9px;
  transform: rotate(90deg);
`;

const ArrowRight = styled(Arrow)`
  right: -9px;
  transform: rotate(270deg);
`;

const Line = styled.div`
  position: absolute;
  top: 5px;
  left: 6px;
  right: 6px;
  height: 1px;
  background-color: ${settings.colors.Primary.Grey_8};
`;

export default React.memo(DragZoom);
