import mixpanel from 'mixpanel-browser';

import { isStatisticsEnabled } from './cookiebot';

const MIXPANEL_HOST = 'https://api-eu.mixpanel.com';
let currentCustomProperties: { [key: string]: string } = {};
let isMixpanelInDebugMode = false;

export const initMixpanel = (mixpanelToken: string, isProduction: boolean) => {
  isMixpanelInDebugMode = !isProduction;
  mixpanel.init(mixpanelToken, {
    api_host: MIXPANEL_HOST,
    ignore_dnt: true,
    debug: isMixpanelInDebugMode,
    verbose: isMixpanelInDebugMode,
    disable_persistence: true,
  });
};

export const setCustomProperties = (props: { [key: string]: string }) => {
  currentCustomProperties = { ...currentCustomProperties, ...props };
};

export const setCustomPropertiesForAllMixpanelEvents = (email: string, isKeyUser: boolean, props: { [key: string]: string }) => {
  mixpanel.identify(email);
  mixpanel.people.set({ isKeyUser: isKeyUser });
  setCustomProperties(props);
};

export const trackMixpanelPageView = (eventProperties?: { [key: string]: string }) => {
  if (!isMixpanelInDebugMode && !isStatisticsEnabled()) {
    // We stop the tracking if the user has not accepted the cookie consent
    return;
  }

  const props = { ...currentCustomProperties, ...eventProperties };
  mixpanel.track_pageview(props);
};

export const trackMixpanelEvent = (name: string, eventProperties?: { [key: string]: string }, flush?: boolean) => {
  if (!isMixpanelInDebugMode && !isStatisticsEnabled()) {
    // We stop the tracking if the user has not accepted the cookie consent
    return;
  }

  const props = { ...currentCustomProperties, ...eventProperties };
  mixpanel.track(name, props, { send_immediately: flush });
};
