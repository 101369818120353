import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { OpsStateCriticalInvertedIcon } from '@rhim/icons/16';
import { useMemoCompare } from '@rhim/react';
import {
  RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel,
  RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto,
} from '@rhim/rest/operatorDisplay';
import { RHIMContractsMaintenanceTaskSeverity } from '@rhim/rest/wearManagement/api';
import {
  criticalBoxContainerOperatorDisplay,
  mainGraphOperatorDisplay,
  maintenanceBoxContainerOperatorDisplay,
  regionTitleOperatorDisplay,
  supplierLabelOperatorDisplay,
} from '@rhim/test-ids';
import { hasElements, isDefined, last } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { WmsPoint } from '../../../apo';
import { useDeferredRenderingWithProgressIndicator } from '../../hooks';
import CriticalBox from '../CriticalBox/CriticalBox';
import Legend from '../PredictionGraph/Legend';
import { calculateCurvePoints, getTargetPoint, warningThemes } from './dataProcessor';
import WmsGraph from './WmsGraph';
import WmsHeader, { Theme } from './WmsHeader';

const { PatchedThemeProvider } = settings.globals;

interface Props {
  region: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel;
  parentWidth: number;
  targetLifeTime: number;
  lastHeat: number;
  expectedHeats: number;
  lastTotalHeat?: number;
  compareMode?: boolean;
  comparedRegion?: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel;
  kpis?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto;
}

const WmsDisplayContainer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  region,
  parentWidth,
  targetLifeTime,
  lastHeat,
  lastTotalHeat,
  comparedRegion,
  compareMode,
  kpis,
  expectedHeats,
}: Props) => {
  const { renderingInProgressIndicator, deferredContainerSize } = useDeferredRenderingWithProgressIndicator([parentWidth, 0]);
  const deferredParentWidth = deferredContainerSize[0];

  const wmsWidth: number = React.useMemo(() => {
    if (deferredParentWidth < 1600) {
      return deferredParentWidth * 0.75 - 60;
    }

    if (deferredParentWidth < 1700 && deferredParentWidth >= 1600) {
      return deferredParentWidth * 0.79 - 60;
    }

    if (deferredParentWidth < 1800) {
      return deferredParentWidth * 0.8 - 60;
    }

    return 587;
  }, [deferredParentWidth]);

  const wmsZoomWidth: number = React.useMemo(() => {
    const containerWidth = deferredParentWidth * 0.49;

    if (deferredParentWidth <= 1900 && deferredParentWidth > 1800) {
      return containerWidth * 0.28 - 60;
    }

    return window.scrollbars.visible ? 230 : 241;
  }, [deferredParentWidth]);
  const containerWidth = React.useMemo(() => {
    return parentWidth < 1800 ? '99%' : '49.5%';
  }, [parentWidth]);

  const maintenanceTasks = useMemoCompare(region.maintenanceTasks, (previous, next) => JSON.stringify(previous) === JSON.stringify(next));

  const { t } = i18nReact.useTranslation(['operator-display', 'shared']);
  const currentMin = region.measuredPoints.length ? last(region.measuredPoints as NonEmptyArray<WmsPoint>) : undefined;

  const targetPoint =
    isDefined(currentMin) && isDefined(region.targetLiningThicknessCurve)
      ? getTargetPoint(calculateCurvePoints(targetLifeTime, region.measuredPoints, region.targetLiningThicknessCurve), currentMin)
      : undefined;
  const isCritical = maintenanceTasks.length !== 0 ? (maintenanceTasks[0] || {}).severity === RHIMContractsMaintenanceTaskSeverity.High : false;
  const maintenanceTask = maintenanceTasks[0];
  const theme: Theme = maintenanceTask ? warningThemes[maintenanceTask.severity] : warningThemes.None;

  const getMaintenanceBox = () => {
    if (maintenanceTask?.severity === RHIMContractsMaintenanceTaskSeverity.High) {
      return (
        <StyledCriticalBox data-test-id={criticalBoxContainerOperatorDisplay}>
          <CriticalBox
            height={36}
            width={24}
            compact={false}
            title={maintenanceTask.task ?? undefined}
            backgroundColor={theme.color}
            color={settings.colors.Primary.Grey_8}
            icon={<OpsStateCriticalInvertedIcon />}
            containerStyle={{ width: '186px' }}
            centered
          />
        </StyledCriticalBox>
      );
    }
    return (
      <MaintenanceBox data-test-id={maintenanceBoxContainerOperatorDisplay}>
        <Message>{maintenanceTask?.task}</Message>
      </MaintenanceBox>
    );
  };

  return (
    <PatchedThemeProvider theme={theme}>
      <Container
        critical={compareMode === true ? false : isCritical}
        width={containerWidth}
        compareMode={compareMode ?? false}
        data-test-id={`regionContainer_${region.name}`}
      >
        <RegionHeader>
          <GroupedText>
            <Title data-test-id={regionTitleOperatorDisplay}>{region.name}</Title>
            {hasElements(region.brickSupplier) && ((isDefined(compareMode) && !compareMode) || !isDefined(compareMode)) ? (
              <BrickSupplier data-test-id={supplierLabelOperatorDisplay}>
                {t('shared:supplier', { count: region.brickSupplier.length })}: {region.brickSupplier.join(', ')}
              </BrickSupplier>
            ) : (
              // Using this placeholder without content so that the layout stays intact when no brick suppliers are known
              <BrickSupplier />
            )}
          </GroupedText>
          {((isDefined(compareMode) && !compareMode) || !isDefined(compareMode)) && maintenanceTask && getMaintenanceBox()}
        </RegionHeader>

        <div style={{ display: 'inline-flex' }}>
          <StyledDiv>
            {compareMode !== true && (
              <WmsHeader
                kpisSettings={kpis}
                target={isDefined(targetPoint) ? Math.round(targetPoint.liningThickness) : undefined}
                maintenancetasks={maintenanceTasks}
                currentMin={isDefined(currentMin) ? Math.round(currentMin.liningThickness) : undefined}
                wear={region.wearSpeed}
                kpis={region.kpis}
                brickSuppliers={region.brickSupplier}
                compareMode={compareMode}
              />
            )}
            <GraphsContainer>
              <GraphContainer width={expectedHeats <= 600 || compareMode === true ? wmsWidth + wmsZoomWidth : wmsWidth} data-test-id={mainGraphOperatorDisplay}>
                <Header>
                  <Legend>{t('operator-display:legend.title')}</Legend>
                </Header>
                <WmsGraph
                  compareMode={compareMode}
                  comparedData={comparedRegion}
                  data={region}
                  hasZoom={expectedHeats > 600 && compareMode !== true}
                  width={expectedHeats <= 600 || compareMode === true ? wmsWidth + wmsZoomWidth + 50 : wmsWidth} // for no zoomed graph, we need to add 50px more for the margin.
                  height={130}
                  targetLifeTime={targetLifeTime}
                  lastHeat={lastHeat}
                  lastTotalHeat={lastTotalHeat}
                />
              </GraphContainer>
              {expectedHeats > 600 && compareMode !== true && (
                <>
                  <WmsGraph
                    comparedData={comparedRegion}
                    lastTotalHeat={lastTotalHeat}
                    data={region}
                    width={wmsZoomWidth}
                    height={130}
                    zoomed={true}
                    targetLifeTime={targetLifeTime}
                    lastHeat={lastHeat}
                  />
                </>
              )}
            </GraphsContainer>
          </StyledDiv>
        </div>
        {renderingInProgressIndicator}
      </Container>
    </PatchedThemeProvider>
  );
};

const GraphContainer = styled.div<{ width: number }>`
  margin-top: ${settings.Spacing.Spacing_200};
  margin-left: ${settings.Spacing.Spacing_200};
  width: ${(props) => props.width}px;
  display: grid;
  align-items: end;
`;

const GroupedText = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  ::after {
    content: '';
    position: absolute;
    width: calc(100% - ${settings.Spacing.Spacing_200});
    height: 1px;
    margin-top: ${settings.Spacing.Spacing_300};
    background: ${settings.colors.Primary.Grey_2};
  }
`;

const GraphsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 32px;
`;

const MaintenanceBox = styled.div`
  background: ${(props) => props.theme.color};
  border: ${(props) => `1px solid ${props.theme.borderColor}`};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 4px 12px;
  margin-right: ${settings.Spacing.Spacing_300};
  height: 36px;
`;

const RegionHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
`;
const Message = styled.h1`
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.Medium};
  font-family: ${settings.typography.FontFamily.Bold};
  line-height: 1.25;
  text-align: center;
  overflow: hidden;
  word-break: break-word;
`;
const StyledCriticalBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: ${settings.Spacing.Spacing_300};
  height: 36px;

  .critical-text {
    font-size: ${settings.typography.FontSize.Medium};
    margin: 0 auto;
    overflow: hidden;
    word-break: break-word;
  }

  .critical-area {
    border-radius: 3px;
  }
`;

const Title = styled.div`
  color: ${settings.colors.Primary.Grey_8};
  height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Medium};
  white-space: nowrap;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding-left: ${settings.Spacing.Spacing_50};
`;

const BrickSupplier = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: ${settings.typography.LineHeight.Line_Height_14};
  color: ${settings.colors.Primary.Grey_8};
  display: inline-block;
  margin-bottom: 8px;
`;

const Container = styled.div<{ critical: boolean; width: string; compareMode: boolean }>`
  cursor: pointer;
  position: relative;
  display: grid;
  width: ${(props) => props.width};
  min-height: ${(props) => (props.compareMode ? 280 : 345)}px;
  margin-left: 8px;
  margin-bottom: ${settings.Spacing.Spacing_50};
  padding: 12px 16px;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: ${(props) => (props.critical ? `solid 2px ${settings.colors.Operational.State_Alert_Red_2}` : `solid 1px ${settings.colors.Primary.Grey_2}`)};
  background-color: ${settings.colors.Monochromatic.White};

  &:last-child {
    margin-bottom: 8px;
  }
`;

WmsDisplayContainer.whyDidYouRender = true;

export default React.memo(WmsDisplayContainer);
