import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  height?: Property.Height;
  width?: Property.Width;
  title?: string;
  role?: string;
}

export const WrenchIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, height, width, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9 9" {...rest} shapeRendering="geometricprecision">
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 2.54307 0.102155 2.10999 0.284872 1.72237L2.03125 3.46875L3.46875 2.03125L1.72237 0.284872C2.10999 0.102155 2.54307 0 3 0C4.65685 0 6 1.34315 6 3C6 3.30309 5.95505 3.59567 5.87146 3.87146L8.28124 6.28124C8.67176 6.67176 8.67176 7.30492 8.28124 7.69545L7.69545 8.28124C7.30492 8.67176 6.67176 8.67176 6.28124 8.28124L3.87146 5.87146C3.59568 5.95505 3.30309 6 3 6C1.34315 6 0 4.65685 0 3Z"
        fill={fill}
      />
    </svg>
  );
};

WrenchIcon.defaultProps = {
  height: '9',
  width: '9',
  fill: settings.colors.Primary.Blue_9,
};
