/* eslint-disable react/no-unstable-nested-components */

import { i18nReact } from '@rhim/i18n';
import { GearIcon } from '@rhim/icons/24';
import { APOIcon } from '@rhim/icons/32';
import { AppBar } from '@rhim/react';
import { dataCenterLinkAppBar } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { useContext } from 'react';
import { setI18n } from 'react-i18next';
import { Location, matchPath } from 'react-router-dom';

import { useCrkPriviledges } from '../../hooks/useCrkPriviledges';
import { hasCementVessel } from '../../lib/vessels';
import { ROUTES } from '../../utilities';
import AppContext from '../AppContext';
import { ApplicationBarProps } from './ApplicationBarProps';
import { ApplicationBarSteel } from './ApplicationBarSteel';
import { NavigationItemAccount } from './NavigationItems/NavigationItemAccount';
import { SelectCustomer } from './SelectCustomer';

setI18n(i18nReact.i18next); // use React i18n

const ApplicationBarCement: React.FunctionComponent<React.PropsWithChildren<ApplicationBarProps>> = ({ authClient, selectCustomer }) => {
  const { t } = i18nReact.useTranslation(['app', 'shared']);
  const { user } = useContext(AppContext);

  const hasMultipleCustomers = isDefined(user.customers) && user.customers.length > 1;
  const ref = React.useRef<HTMLDivElement>(null);
  const { hasLesAdminAccess } = useCrkPriviledges();
  const isCrkActive = (_matchPath: typeof matchPath, location: Location) => {
    return (
      isDefined(_matchPath({ path: `${ROUTES.ARO_ROOT}/*` }, location.pathname)) &&
      !isDefined(_matchPath({ path: `${ROUTES.ARO_INGRESS}/*` }, location.pathname))
    );
  };

  return (
    <AppBar.Bar ref={ref}>
      <AppBar.Logo productLogoIcon={<APOIcon />} productLogoHref={ROUTES.START} isLogoRightDividerShowing={hasMultipleCustomers} />
      <AppBar.NavigationItems>
        {hasMultipleCustomers && <SelectCustomer selectCustomer={selectCustomer} getPopupContainer={(node) => ref.current ?? node} />}
        <AppBar.NavigationItem dataTestId="not_implemented" href={ROUTES.ARO_LES_REPORT} label={t('shared:vesselNames.rk')} isActive={isCrkActive} />
        <AppBar.HorizontalSpring />
        {hasLesAdminAccess && <AppBar.NavigationItem label={t('app:appBar.ingress')} href={ROUTES.ARO_INGRESS} dataTestId={dataCenterLinkAppBar} />}
      </AppBar.NavigationItems>
      <AppBar.FunctionItems>
        <AppBar.NavigationIconItem href={ROUTES.USER_SETTINGS} icon={<GearIcon />} />
        <NavigationItemAccount authClient={authClient} selectCustomer={selectCustomer} />
      </AppBar.FunctionItems>
    </AppBar.Bar>
  );
};

const ApplicationBar: React.FunctionComponent<React.PropsWithChildren<ApplicationBarProps>> = ({ authClient, selectCustomer }) => {
  const { selectedCustomer } = useContext(AppContext);

  // if the customer is not set (e.g. when on the public pages), show the default application bar that doesn't display customer-specific information
  if (!isDefined(selectedCustomer.customerId))
    {return (
      <AppBar.Bar>
        <AppBar.Logo productLogoIcon={<APOIcon />} productLogoHref={ROUTES.START} isLogoRightDividerShowing={false} />
        <AppBar.NavigationItems />
        <AppBar.FunctionItems>
          <NavigationItemAccount authClient={authClient} selectCustomer={selectCustomer} />
        </AppBar.FunctionItems>
      </AppBar.Bar>
    );}

  // if the customer id is set, we can determine if the application bar should render the Cement or Steel flavor.
  // This distinction is necessary because the product should appear differently for industrial and steel customers.
  const isCement = hasCementVessel(selectedCustomer);

  return isCement ? (
    <ApplicationBarCement authClient={authClient} selectCustomer={selectCustomer} />
  ) : (
    <ApplicationBarSteel selectCustomer={selectCustomer} authClient={authClient} />
  );
};

ApplicationBar.whyDidYouRender = true;

const ApplicationBarComponent = React.memo(ApplicationBar);
ApplicationBarComponent.displayName = 'ApplicationBar';

export default ApplicationBarComponent;
