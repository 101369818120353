/* eslint-disable i18next/no-literal-string */
// TODO BFR TRANSLATIONS
import { ensure } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';

import { useScaleY } from '../hooks/useScaleY';
import { DELTA_VOLUME_AFTER_BEFORE_REPAIR_LINE_COLOR, REPAIR_MIX_VOLUME_LINE_COLOR } from './constants';
import Graph from './Graph';
import { GraphLegendProps } from './GraphLegend';

const GRAPH_HEIGHT_TOTAL = 200;
const BAR_WIDTH = 14;

const GRAPH_LEGENDS: GraphLegendProps[] = [
  {
    label: '∆ Volume After-Before Repair',
    color: DELTA_VOLUME_AFTER_BEFORE_REPAIR_LINE_COLOR,
    isSolidLine: true,
  },
  {
    label: 'Repair Mix Volume',
    color: REPAIR_MIX_VOLUME_LINE_COLOR,
    isSolidLine: true,
  },
];

interface Data {
  atTons: number;
  deltaVolume: number;
  repairMixVolume: number;
}

function getBarFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, graphAreaHeight: number, data: Data[], index: number) {
  if (index === 0) {
    return null;
  }
  const repairMixAmountCurrent = ensure(data[index]);

  const volumeRepairDeltaBarStartY = yScale(repairMixAmountCurrent.deltaVolume);
  const volumeRepairDeltaBarHeight = graphAreaHeight - volumeRepairDeltaBarStartY;

  const repairMixVolumeBarStartY = yScale(repairMixAmountCurrent.repairMixVolume);
  const repairMixVolumeBarHeight = graphAreaHeight - repairMixVolumeBarStartY;
  return (
    <React.Fragment key={index}>
      {/* SEGMENT'S DELTA VOLUME REPAIR BAR */}
      <rect
        key={`${repairMixAmountCurrent.atTons}-deltaVolumeRepair`}
        x={xScale(repairMixAmountCurrent.atTons) - BAR_WIDTH}
        y={volumeRepairDeltaBarStartY}
        width={BAR_WIDTH}
        height={volumeRepairDeltaBarHeight}
        fill={DELTA_VOLUME_AFTER_BEFORE_REPAIR_LINE_COLOR}
      />
      {/* SEGMENT'S REPAIR MIX VOLUME BAR */}
      <rect
        key={`${repairMixAmountCurrent.atTons}-repairMixVolume`}
        x={xScale(repairMixAmountCurrent.atTons)}
        y={repairMixVolumeBarStartY}
        width={BAR_WIDTH}
        height={repairMixVolumeBarHeight}
        fill={REPAIR_MIX_VOLUME_LINE_COLOR}
      />
    </React.Fragment>
  );
}

function getBarsFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, graphAreaHeight: number, repairMixAmounts: Data[]) {
  const ret = [];
  for (let index = 0; index < repairMixAmounts.length; index++) {
    ret.push(getBarFor(xScale, yScale, graphAreaHeight, repairMixAmounts, index));
  }
  return <g id="group-volumeRepairDeltaVsMixVolume">{ret}</g>;
}

interface Props {
  xScale: ScaleLinear<number, number>;
  data: Data[];
}
const GraphVolumeRepairDeltaVsMixVolume: React.ChildlessComponent<Props> = ({ xScale, data }) => {
  const graphAreaHeight = GRAPH_HEIGHT_TOTAL - 32;

  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (let index = 0; index < data.length; index++) {
      const item = ensure(data[index]);
      maxValue = Math.max(maxValue, item.deltaVolume);
      maxValue = Math.max(maxValue, item.repairMixVolume);
    }
    return maxValue;
  }, [data]);

  //const xScale = useScaleX(graphAreaWidth);
  const yScale = useScaleY(graphAreaHeight, maxYValue);

  const measurementsAtTons = data.map((item) => item.atTons);

  const rblBars = useMemo(() => getBarsFor(xScale, yScale, graphAreaHeight, data), [xScale, yScale, graphAreaHeight, data]);

  return (
    <Graph
      graphHeightTotal={GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yAxisLabel="∆ RBL, After-Before Repair [mm]"
      graphLegends={GRAPH_LEGENDS}
      xAxisLabel="Amount of Steel [t]→"
      measurementsAtTons={measurementsAtTons}
      maxYValue={maxYValue}
    >
      {rblBars}
    </Graph>
  );
};

GraphVolumeRepairDeltaVsMixVolume.whyDidYouRender = true;
export default React.memo(GraphVolumeRepairDeltaVsMixVolume);
