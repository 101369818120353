import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { Button, Modal, Tabs } from '@rhim/react';
import { RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto } from '@rhim/rest/operatorDisplay';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';
interface Props {
  visible: boolean;
  close: () => void;
  kpis?: RHIMOperatorDisplayServiceV1ModelsVesselKpiSettingsWmsDto;
}

const { TabPane } = Tabs;

const KpisInfoModal = ({ visible, close, kpis }: Props) => {
  const { t } = i18nReact.useTranslation(['operator-display']);
  const hasWear =
    isDefined(kpis?.visibilityWearRateAverage) || isDefined(kpis?.visibilityWearRateInstantaneous) || isDefined(kpis?.visibilityWearRateRelatedMinimum);
  const hasMinimumLiningThickness = isDefined(kpis?.visibilityCurrentLiningThickness) || isDefined(kpis?.visibilityMinimumLiningThickness);
  const hasPotential = isDefined(kpis?.visibilityPotentialMinimum) || isDefined(kpis?.visibilityPotentialAverage);
  const hasThresholds = isDefined(kpis?.visibilityThresholdLower) || isDefined(kpis?.visibilityThresholdTarget);
  const hasDelta = isDefined(kpis?.visibilityDeltaTarget) || isDefined(kpis?.visibilityDeltaLower);

  return (
    <Modal
      destroyOnClose
      onCancel={close}
      open={visible}
      width={700}
      footer={
        <Left>
          <Button label={t('operator-display:ok')} onClick={close} />
        </Left>
      }
    >
      <Tabs destroyInactiveTabPane>
        {hasMinimumLiningThickness && (
          <TabPane tab={t('operator-display:minimumLiningThickness')} key="1">
            <Info>
              <Header>{t('operator-display:minimumLiningThickness')}</Header>
              <Kpis>
                <Name>{t('operator-display:current')}</Name>
                <Description>{t('operator-display:legend.kpis.currentDescription')}</Description>

                <Name>{t('operator-display:minimumLiningThicknessCampaign')}</Name>
                <Description>{t('operator-display:legend.kpis.minimumLiningThicknessCampaignDescription')}</Description>
              </Kpis>
            </Info>
          </TabPane>
        )}
        {hasWear && (
          <TabPane tab={t('operator-display:legend.kpis.wearRate')} key="2">
            <Info>
              <Header>{t('operator-display:legend.kpis.wearRate')}</Header>
              <Kpis>
                <Description>{t('operator-display:legend.kpis.wearRateDescription')}</Description>
                <Name>{t('operator-display:legend.kpis.average')}</Name>
                <Description>{t('operator-display:legend.kpis.averageDescription')}</Description>
                <Name>{t('operator-display:legend.kpis.instantaneous')}</Name>
                <Description>{t('operator-display:legend.kpis.instantaneousDescription')}</Description>
                <Name>{t('operator-display:legend.kpis.relatedToMinimumMeasurement')}</Name>
                <Description>{t('operator-display:legend.kpis.wearRateRelatedToMinimumMeasurementDescription')}</Description>
              </Kpis>
            </Info>
          </TabPane>
        )}
        {hasPotential && (
          <TabPane tab={t('operator-display:potential')} key="3">
            <Info>
              <Header>{t('operator-display:potential')}</Header>
              <Kpis>
                <Name>{t('operator-display:legend.kpis.relatedToLatestMeasurement')}</Name>
                <Description>{t('operator-display:legend.kpis.relatedToLatestMeasurementDescription')}</Description>

                <Name>{t('operator-display:legend.kpis.relatedToMinimumMeasurement')}</Name>
                <Description>{t('operator-display:legend.kpis.relatedToMinimumMeasurementDescription')}</Description>
              </Kpis>
            </Info>
          </TabPane>
        )}
        {hasThresholds && (
          <TabPane tab={t('operator-display:thresholds')} key="4">
            <Info>
              <Header>{t('operator-display:thresholds')}</Header>
              <Kpis>
                <Name>{t('operator-display:targetCurve')}</Name>
                <Description>{t('operator-display:legend.kpis.targetCurveDescription')}</Description>
                <Name>{t('operator-display:lowerThreshold')}</Name>
                <Description>{t('operator-display:legend.kpis.lowerThresholdDescription')}</Description>
              </Kpis>
            </Info>
          </TabPane>
        )}
        {hasDelta && (
          <TabPane tab={t('operator-display:delta')} key="5">
            <Info>
              <Header>{t('operator-display:delta')}</Header>
              <Kpis>
                <Name>{t('operator-display:deltaRelatedToTarget')}</Name>
                <Description>{t('operator-display:legend.kpis.deltaRelatedToTargetDescription')}</Description>
                <Name>{t('operator-display:deltaRelatedToLowerThreshold')}</Name>
                <Description>{t('operator-display:legend.kpis.deltaRelatedToLowerThresholdDescription')}</Description>
              </Kpis>
            </Info>
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

const Info = styled.div`
  display: flex;
  flex-direction: column;
  color: ${settings.colors.Primary.Grey_8};
  overflow: auto;
  max-height: 220px;
  padding-right: 12px;
  scrollbar-width: thin;
  scrollbar-color: ${settings.colors.Primary.Grey_10} ${settings.colors.Primary.Grey_2};
`;

const Header = styled.h3`
  ${settings.typography.fonts.h_xl};
  margin: 24px 0 40px 0;
`;

const Kpis = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Description = styled.div`
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
`;

const Name = styled.div`
  font-family: ${settings.typography.FontFamily.Medium};
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default React.memo(KpisInfoModal);
